import React from "react"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { actionGetStatusSave, actionNavigationenu } from '../redux/actions'
import { connect } from 'react-redux'
import RequestBGPaper from '../component/scene/RequestBGPaper'
import { Loading } from '../component/common'
import WithWebService from '../utils/WebServiceInterface'
import { getCookie} from '../../src/utils/Utilities'

const styles = theme => ({
    root: {
        marginTop: 0
    },
    title: { fontSize: 24, },
    layoutSubTitle: {
        margin: 36,
    },
    connectorActive: {
        '& $connectorLine': {
            borderColor: theme.palette.secondary.main,
            borderWidth: 2

        },
    },
    connectorCompleted: {
        '& $connectorLine': {
            borderColor: theme.palette.secondary.main,
            borderWidth: 2
        },
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: theme.palette.grey[100],
            borderWidth: 2
        },
    },
    connectorLine: {
        transition: theme.transitions.create('border-color'),
        borderWidth: 3

    },
    stepIcon: {
        width: 40,
        height: 40
    },
    rootSteper: {
        backbroundColor: 'transparent'
    }
})



class RequesteBGPaperView extends React.Component {


    constructor(props) {
        super(props)
        // const { poNumber, requestNo } = this.props.match.params

        this.state = {
            isLoading: false,
            steps: [
                { label: 'บันทึก', decscription: '(AR Finance)', completed: false, },
                { label: 'ตรวจรับ จาก ปภญ.', decscription: '(System)', completed: false, },
                { label: 'ตรวจสอบคืน จาก ปภญ.', decscription: '(System)', completed: false, },],
            step:{
                saveByARF: "C",
                acceptByPPY: "N",
                completed: "N"
            },
            creditDetail:{}
        }
    }

    componentDidMount(){
        if (this.props.match.params.requestNo && this.props.match.params.requestNo != ''){
            this.stepPaperInfo()
            this.getCreditPaper()
        }

    }

    render() {

        const { classes } = this.props;

        const connector = (
            <StepConnector
                classes={{
                    active: classes.connectorActive,
                    completed: classes.connectorCompleted,
                    disabled: classes.connectorDisabled,
                    line: classes.connectorLine,
                }}
            />
        );

        return (
            <div className={classes.root}>

                <Stepper nonLinear alternativeLabel activeStep={this.getCurrentStep()} connector={connector} style={{ backgroundColor: 'transparent' }}>

                    {this.state.steps.map((step, index) => {
                        const stepStatus = this.state.step
                        let isCompleted = this.checkComplete(index, stepStatus)

                        return (
                            <Step key={step.label} completed={isCompleted} >

                                <StepLabel>
                                    <div>
                                        <div>
                                            {step.label}
                                        </div>
                                        <div>
                                            {step.decscription}
                                        </div>
                                    </div>
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>

                <main>
                    <div>
                        {this.state.isLoading ? <Loading /> : <RequestBGPaper creditDetail={this.state.creditDetail} isCreate={!this.props.match.params.requestNo} />}
                    </div>
                </main>

            </div>
        )
    }

    

    checkComplete(index, stepStatus) {

        var isCompleted = false
        switch (index) {
            case 0:
                isCompleted = stepStatus.saveByARF === "Y"
                break
            case 1:
                isCompleted = stepStatus.acceptByPPY === "Y"
                break
            case 2:
                isCompleted = stepStatus.completed === "Y"
                break
            default:
                isCompleted = false
        }

        return isCompleted

    }


    getCurrentStep() {

        const stepStatus = this.state.step
        if (stepStatus.saveByARF === "C") {
            return 0
        } else if (stepStatus.acceptByPPY === "C") {
            return 1
        } else if (stepStatus.completed === "C") {
            return 2
        } 


    }

    stepPaperInfo() {

        const parameter = { requestNo: this.props.match.params.requestNo }
        this.props.webService.stepPaperInfo(parameter).then(response => {
         
            this.setState({  step: response.data.data })


        }).catch(error => {
           
        })

    }

    getCreditPaper() {

        this.setState({ isLoading: true })
        const parameter = { requestNo: this.props.match.params.requestNo }
        this.props.webService.getCreditPaper(parameter).then(response => {
          
            this.setState({ isLoading: false, creditDetail: response.data.data })


        }).catch(error => {
          
            this.setState({ isLoading: false, })
        })

    }

}


const mapStateToProps = ({ step }) => {

    return (
        {
            // step: step.step
        }
    )
}


export default WithWebService(connect(mapStateToProps, { actionGetStatusSave, actionNavigationenu })(withRouter(withStyles(styles, { withTheme: true })(RequesteBGPaperView))))