import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class ExportExcelSearchDocument extends React.Component {
    render() {
        return (
            <ExcelFile element={<button style={{ background: '#06A719', color: 'white', height: 30, borderRadius: 10, margin: 8, cursor: 'pointer', }}>Export Excel</button>}>
                <ExcelSheet data={this.props.dataSet} name="Document">
                    <ExcelColumn label="เลขที่หนังสือค้ำประกัน" value={(col) => `${col.bgNo}`} />
                    <ExcelColumn label="ประเภทหนังสือค้ำประกัน" value={(col) => `${col.requestType}`} />
                    <ExcelColumn label="ลูกค้า" value={(col) => `${col.sapCustomerId} ${col.companyNameTh}`} />
                    <ExcelColumn label="รายละเอียดสัญญา" value={(col) => `${col.contractDocName}`} />
                    <ExcelColumn label="วันที่เอกสาร" value={(col) => `${col.requestDate}`} />
                    <ExcelColumn label="มูลค่าหลักประกัน	" value={(col) => col.amount} />
                    <ExcelColumn label="วันหมดอายุ" value={(col) => col.expiryDate} />
                </ExcelSheet>

            </ExcelFile>
        );
    }
}

export default ExportExcelSearchDocument