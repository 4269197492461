const keys = {
    IS_LOGIN: 'IS_LOGIN',
    USERNAME: 'USERNAME',
    USERDETAIL: 'USERDETAIL',
    TOKEN: 'TOKEN',
}


const roles = {
    SALES: 'SALES',
    CDF: 'CDF',//ผู้รับผืดชอบ
    CDM: 'CDM',//คู่ค้า
    CUS: 'CUS',//ผู้มีอำนาจอนุมัติ
    APD: 'APD',//ผู้มีอำนาจอนุมัติ
    ARA: 'ARA',//ผู้มีอำนาจอนุมัติ
    SM: 'SM',//ผู้มีอำนาจอนุมัติ
    SVP: 'SVP',//ผู้มีอำนาจอนุมัติ
    ARM: 'ARM',
    ARF: 'ARF',
    SALES_VIEW: 'SALES_VIEW'
}

const stepCode = {
    CREATE: 'CREATE',
    VERIFY_BY_CREDIT_OFFICER: "VERIFY_BY_CREDIT_OFFICER",
    APPROVE_BY_CREDIT_MANAGE: "APPROVE_BY_CREDIT_MANAGE",
    UPDATE_REQUEST_BY_CUSTOMER: "UPDATE_REQUEST_BY_CUSTOMER",
    BCI_PROCESS: "BCI_PROCESS",
    VERIFY_APPROVAL_DEPARTMENT: "VERIFY_APPROVAL_DEPARTMENT",
    COMPLETED: "COMPLETED",
    ACCEPT_COLLATERAL: 'ACCEPT_COLLATERAL',
    VERIFY_BY_AR_ACCOUNTANT: "VERIFY_BY_AR_ACCOUNTANT",
    VERIFY_BY_CREDIT_MANAGE: "VERIFY_BY_CREDIT_MANAGE",
    VERIFY_BY_SALES_MANAGER: "VERIFY_BY_SALES_MANAGER",
    APPROVE_BY_SALES_VP: "APPROVE_BY_SALES_VP",
    VERIFY_BY_AR_MANAGER: "VERIFY_BY_AR_MANAGER",
    SAVE_BY_ARF: 'SAVE_BY_ARF',
    ACCEPT_BY_PPY: 'ACCEPT_BY_PPY',
    CDF_ACCEPT: 'CDF_ACCEPT'

}

const flowCode = {
    eBG01: 'eBG01', //ISSUE
    eBG02: 'eBG02', //AMEND
    eBG03: 'eBG03', //DECREASE
    eBG04: 'eBG04', //CANCEL
}

export { keys, roles, stepCode, flowCode }