const UTILITIES = {
  "SYSTEM_NAME": "Electronic Bank Guarantee",
  "SYSTEM_SHORT_NAME": "EBG",
  "SYSTEM": "azt_cms",
  "APP_TYPE": "CMS",
  "ACTIVE_STATUS": "ACTIVE",
  "DELETE_STATUS": "DELETE",
  "INACTIVE_STATUS": "INACTIVE",
  "CREATE_EVEN_PAGE": "CREATE",
  "EDIT_EVENT_PAGE": "EDIT",
  "LOGIN_TYPE_B2C": "B2C",
  "LOGIN_TYPE_AAD": "AAD",
  "TEXT_ALERT": {
    "CONFIRM_DELETE": "Are you sure to delete this",
    "CONFIRM_ADD": "Are you sure to add this",
    "CONFIRM_EDIT": "Are you sure to edit this",
    "CONFIRM": "Confirm",
    "CANCEL": "Cancel"
  },
  "FUTURE": "Features is coming soon.",
  "ERRORS": {
    "REQUIRED_INPUT": "Please complete the information.",
    "DUPLICATE_VALUE": "This user has been added",
    "FORMAT_EMAIL_INCORRECT": "Incorrect email format",
    "USER_NOT_EXIST_SYSTEM": "This user does not exist in the system.",
    "INCORRECT_FORMAT_MENU" : "Incorrect format menu",
    "VALIDATE_THAI_LANGUAGE" : "Should not fill in the information in Thai"
  },
  "ERROR_SYSTEM": "System error",
  "SUCCESS": {},
  "TEMPLATE": {
    "TEMPLATE_BREAKPOINTS_UP": "md"
  }
}


const USER_DEFAULT = {
  issuer_b2c: "PTTDigitalLabB2C.onmicrosoft.com",
  email_b2c: "@PTTDigitalLabB2C.onmicrosoft.com"
}

const FORMATDATE = {
  ddmmyyyy: "DD/MM/YYYY",
  ddmmyyyyhhmmss: "DD/MM/YYYY HH:mm:ss",
}

const GLOBALCONFIGKEY = {
  LIMIT_MONTH: "LIMIT_MONTH",
  DD_STATUS: "DD_STATUS",
  DD_LOCATION: "DD_LOCATION",
  DD_BOOK_SUB_BOOK: "DD_BOOK_SUB_BOOK",
  VERSION: "VERSION",
  SESSION_TIMEOUT: "SESSION_TIMEOUT",
  SESSION_TIMEOUT_INTERVAL: "SESSION_TIMEOUT_INTERVAL",
  LIMITATION_CONNECTION_TIME: "LIMITATION_CONNECTION_TIME",
  PREFIX: "@CLAIM"
}





export default {
  UTILITIES,
  FORMATDATE,
  GLOBALCONFIGKEY,
  USER_DEFAULT
};
