
import axios from 'axios'
import { getCookie } from "../utils/Utilities";
import { keys } from "../utils/Constants";
import { env } from "../env"

// http://ptt-ebg-gateway.herokuapp.com
//https://ebg-gateway.herokuapp.com/
//http://ptt-credit-backend.herokuapp.com
//http://credit-service-dev-ebg.apps.platform.pttgrp.com/

const BASE_URL = env.REACT_APP_API_ENDPOINT
// const BASE_URL = 'http://credit-service-dev-ebg.apps.platform.pttgrp.com/'  

const setHeader = (isMultipart) => {

    axios.defaults.baseURL = BASE_URL
    const token = getCookie(keys.TOKEN);

    axios.defaults.headers = {
        "Content-Type": isMultipart ? 'multipart/form-data' : "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token == null ? "" :  token
    };

    axios.defaults.timeout = 60 * 4 * 1000;
}

const post = (path, parameter, isMultipart,config = {}) => {
    return new Promise((resolve, reject) => {

        setHeader(isMultipart)

        return axios
            .post(path, parameter, config)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
              
                reject(error);
            });
    });
}

const get = (path, parameter, config = {}) => {
    return new Promise((resolve, reject) => {

        setHeader()

        return axios
            .get(path, { params: parameter, ...config })
            .then(response => {
                resolve(response);
            })
            .catch(error => {

                reject(error);
            });
    });
}

export { post, get, BASE_URL }