import React from 'react'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TaskView from './TaskView'
import WithWebService from '../utils/WebServiceInterface'
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import { MyTextField } from '../component/common/MaterialUI'
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({

    tab: { fontSize: 12, backgroundColor: '#D6D6D6', height: 70, color: theme.palette.text.main, },
    tabActive: { fontSize: 12, backgroundColor: '#fff', height: 70, color: theme.palette.secondary.main },
    rootTab: { borderWidth: 1, borderColor: 'red' },
    content: {
        padding: 36
    },
    containerTab: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },



})

let delaySearch = null

class MyTaskView extends React.Component {

    constructor(props) {
        super(props)




        this.state = {
            myTask: [],
            isLoading: false,
            parameter: {
                requestNo: '',
                requestDate: "",
                vendor: '',
                bank: '',
                bgNo: '',
                bgType: '',
                amount: '',
                status: '',
                effectiveDate: "",
                expiryDate: "",
                remainDate: '',
            }
        }
    }

    componentWillMount() {
        this.getMyTask({ requestNo: "" })
    }

    search(e) {

        if (delaySearch) {
            clearTimeout(delaySearch)
        }

        delaySearch = setTimeout(() => {
            this.getMyTask({ requestNo: e.target.value })
        }, 300);
    }


    getMyTask(parameter) {


        this.setState({ isLoading: true })

        this.props.webService.getMyTask(parameter).then(response => {

            this.setState({
                isLoading: false,
                myTask: response.data === "" ? null : response.data.data.tasksList,
            })


        }).catch(error => {
            this.setState({ isLoading: false })
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Card>
                <div>
                    <div className={classes.content}>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Typography variant="h6">งานของฉัน</Typography>
                            </Grid>
                            <Grid item xs>
                                <MyTextField
                                name="search"
                                    onChange={this.search.bind(this)}
                                    placeholder="ค้นหาเลขที่เอกสาร"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Icon color="disabled" >search</Icon>
                                        </InputAdornment>
                                    } />
                            </Grid>
                            <Grid item xs></Grid>
                        </Grid>
                        <br />
                        <Divider className={classes.divider} />

                        <TaskView isLoading={this.state.isLoading} task={this.state.myTask} type='MY_TASK' onFilter={(filter) => {
                            this.setState({ parameter: filter }, () => {
                                this.getMyTask()
                            })
                        }} />

                    </div>
                </div>
            </Card>
        )
    }


}





export default WithWebService(withRouter(withStyles(styles)(MyTaskView)))