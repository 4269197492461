import React from 'react'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid/Grid";
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    root:{
        margin: 24,
        marginBottom:0
    },
    titleTable: {
        marginTop: 0,
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom:16
    },
    verticalColor:{
        width:3,
        backgroundColor: theme.palette.common.verticalColor,
        height:20
    }

})

export const TitleSection = (withStyles(styles)((props) => {

    const { classes } = props
    return <div className={classes.root}>
        <Grid container spacing={1} >
        <Grid item>
                <div className={classes.verticalColor}/>
        </Grid>
            <Grid item xs>
                <Typography paragraph variant="subheading" className={classes.titleTable}>{props.title}</Typography>
            </Grid>
            <Grid item>
                {props.children}
            </Grid>
        </Grid>
        <Divider/>
    </div>
}))