import React from 'react'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { MDateField, MSelect, MTextField, Loading, Empty, ButtonSave,  ButtonSearchCircle, TitleSection } from "../common"

import WithWebService from '../../utils/WebServiceInterface'
import Moment from 'moment'
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import DialogApp from '../common/DialogApp'
import { connect } from 'react-redux'
import { actionGetStatusSave, actionNavigationenu } from '../../redux/actions'
import { MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import axios from 'axios'

import { formatNumber, getCookie, capitalize } from "../../utils/Utilities"
import { keys, roles, stepCode, flowCode } from '../../utils/Constants'
import { menuNavigationSlice } from "../../redux/feature/navigationSlice"

const styles = theme => ({
    content: {
        padding: 24,
        paddingTop: 0,
        paddingBottom: 8,
    },
    titleTable: {
        marginTop: 0,
        fontWeight: 'bold',
        fontSize: 18
    },
    containerGroup: {
        padding: 36
    },
    cssLabel: {
        '&$cssFocused': {
            color: theme.palette.primary.dark,
        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
    },
    cssFocused: {},
    notchedOutline: {},
    success: {
        backgroundColor: '#00C217'
    },
    labelColor: {
        color: theme.palette.common.textTitle
    },
    titleData: {
        color: theme.palette.common.textTitleRequest,
        fontSize: 14
    },
    valueData: {
        color: theme.palette.common.textTitle,
        fontSize: 14,
        overflowWrap: 'break-word',
        wordBreak: 'break-all'
    },
    containerHeaderdata: {
        padding: 24
    },
    contentHeader: {
        marginTop: 4
    },
    containerRemark: {
        padding: '8px 24px 8px 24px'
    },
    textRemark: {
        color: 'red'
    },
    clipFile: {
        marginTop: 4, marginLeft: 4,
    },


})

const style = {
    itemField: { paddingTop: 0, paddingBottom: 0 }
}

class RequestBGPaper extends React.Component {

    constructor(props) {
        super(props)

        const creditDetail = this.props.creditDetail

        var minDueDate = new Date('1900-01-01')
        if (this.props.match.params.action === 'amend') {

            const initDate = Moment(creditDetail.collateralEndDate, "DD/MM/YYYY").add("years", -543).format("DD/MM/YYYY")
            minDueDate = Moment(initDate, "DD/MM/YYYY")
        }

        this.state = {
            isSaveSuccess: false,
            torFlag: 'Y',
            periodMinTotal: 0,
            periodSubmit: 0,
            periodCheck: 0,
            periodWarranty: 0,
            periodFinance: 0,
            periodOther: 0,
            periodDelayed: 0,
            periodSubmitFlag: 'Y',
            requestDate: new Date(),
            collateralEnforceDate: new Date(),
            isLoading: false,
            isLoadingSave: false,
            isLoadingSearch: false,
            isPopupNote: false,
            isLoadingUploadFile: false,
            remark: '',
            collateralModelList: [],
            collateralTypeList: [],
            sapBussinessTypeList: [],
            sapProductDeliveryList: [],
            sapAreaList: [],
            bankList: [],
            fileUploads: (creditDetail.attachFiles ? creditDetail.attachFiles.fileDataList : []) || [],
            beneficiaryName: creditDetail.beneficiaryName ? creditDetail.beneficiaryName : "บริษัท ปตท. จำกัด (มหาชน)",
            bciDocumentId: creditDetail.bciDocumentId ? creditDetail.bciDocumentId : "-",
            createDate: creditDetail.createDate ? creditDetail.createDate : Moment(new Date()).format('DD/MM/YYYY'),
            beneficiaryTax: creditDetail.beneficiaryTax ? creditDetail.beneficiaryTax : "0107544000108",
            createdBy: creditDetail.createdBy ? creditDetail.createdBy : "-",
            bankName: creditDetail.bankName ? creditDetail.bankName : "-",
            requestNo: creditDetail.requestNo ? creditDetail.requestNo : "",
            bgNo: creditDetail.bgNo ? creditDetail.bgNo : "-",
            contractEnforceDate: creditDetail.contractEnforceDate ? Moment(creditDetail.contractEnforceDate, 'DD/MM/YYYY').add("years", -543) : Moment(new Date()),
            contractExpireDate: creditDetail.contractExpireDate ? Moment(creditDetail.contractExpireDate, 'DD/MM/YYYY').add("years", -543) : Moment(new Date()).add("days", 365),
            documentId: creditDetail.documentId || '',
            requestId: creditDetail.requestId || '',
            minDueDate: minDueDate,
            fileUploadsDebtor: (creditDetail.attachFiles ? creditDetail.attachFiles.fileDataARList : []) || [],
            confirmArInformation: creditDetail.confirmArInformation ? (creditDetail.confirmArInformation === 'Y' ? true : false) : false,
            requireConfirmArInformationError: false,
            step:{
                "currentEbg": {
                    "requestNo": "",
                    "flowCode": "",
                    "flowName": ""
                },
                "create": "",
                "updateRequest": "",
                "verify": "",
                "approved": "",
                "bciProcess": "",
                "completed": ""
            },

        }
    }

    componentWillMount() {

        this.setState({ isLoading: true })
        Promise.all([this.getCollateralModel(), this.getCollateralTypeList(), this.getBankList(), this.getSapBusinessArea(), this.getSapBusinessType(), this.getSapProductDelivery()]).then(response => {
            this.setState({ isLoading: false })
            this.setButtonNavigation()
        }).catch(error => {
            this.setState({ isLoading: false })
            this.setButtonNavigation()
        })

    }

    componentDidMount() {
        if (!this.props.isView) {
            this.setButtonNavigation()
        }
    }

    setButtonNavigation() {
        // this.props.actionNavigationenu(this.renderButton.bind(this))
        this.props.menuNavigationSlice(this.renderButton.bind(this))
        this.setState({ menuNavigation: this.renderButton.bind(this) })
    }

    componentWillUnmount() {
        this.props.actionNavigationenu(() => { })
        this.props.menuNavigationSlice(() => { })
    }


    defaultLoadingButton() {
        this.setState({
            isLoadingSave: false,
            isLoadingApprove: false,
            isLoadingReject: false,
            isLoadingSubmit: false,
        })

        this.setButtonNavigation()
    }

    searchCustomerByTaxID(customerTax) {

        this.setState({ isLoading: true, isLoadingSearch: true })
        this.props.webService.searchCustomerByTaxID({ sapCustomerId: customerTax }).then(response => {
          
            this.setState({ isLoading: false, isLoadingSearch: false, })
            if (response.data.customer) {
                this.form.setFieldValue('companyName', response.data.customer.companyName)
                this.form.setFieldValue('customerTax', response.data.customer.customerTax)
            }

            if (!response.data) {
              
                setTimeout(() => {

                    this.dialogApp.showDialogFail(`ไม่พบ Customer ที่ค้นหา`, ``, () => {

                    })
                }, 300);
            }

        }).catch(error => {
            this.setState({ isLoading: false, isLoadingSearch: false })
        })

    }





    getCollateralModel() {

        return new Promise((resolve, reject) => {

            this.props.webService.getCollateralModel().then(response => {
                
                
                this.setState({ collateralModelList: response.data.data.collateralModelList })
                resolve()

            }).catch(error => {
                
                
                reject()
            })
        })

    }

    getBankList() {

        return new Promise((resolve, reject) => {

            this.props.webService.getBankList().then(response => {
               
                this.setState({ bankList: response.data.data.bankList })
                resolve()

            }).catch(error => {
              
                reject()
            })
        })

    }

    getSapBusinessArea() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapBusinessArea().then(response => {
              
                this.setState({ sapAreaList: response.data.data.businessArea })
                resolve()

            }).catch(error => {
              
                reject()
            })
        })

    }

    getSapBusinessType() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapBusinessType().then(response => {
               
                this.setState({ sapBussinessTypeList: response.data.data.BusinessType })
                resolve()

            }).catch(error => {
               
                reject()
            })
        })

    }

    getSapProductDelivery() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapProductDelivery().then(response => {
               
                this.setState({ sapProductDeliveryList: response.data.data.productDelivery })
                resolve()

            }).catch(error => {
               
                reject()
            })
        })

    }



    checkCustomerStatus(customerTax) {

        const parameter = { "customerTax": customerTax }
        this.setState({ isLoading: true, })

        return this.props.webService.checkTaxId(parameter)

    }


    getCollateralTypeList() {

        return new Promise((resolve, reject) => {

            this.props.webService.getCollateralTypeList().then(response => {
             
                this.setState({ collateralTypeList: response.data.data.collateralTypeList })
                resolve()

            }).catch(error => {
               
                reject()
            })
        })

    }

    createCreditPaper(values) {

        return new Promise((resolve, reject) => {



            const files = []
            this.state.fileUploads.forEach(element => {
                files.push(element)
            })

            const fileDataARList = []
            this.state.fileUploadsDebtor.forEach(element => {
                fileDataARList.push(element)
            })

          

            const valuesData = Object.assign(values, { attachFiles: { fileDataList: files, fileDataARList }, requestNo: this.state.requestNo, status:'Paper' })
          

            const creditDetail = this.props.creditDetail
            Object.assign(creditDetail, valuesData)

           

            this.props.webService.createCreditPaper(values).then(response => {
                console.log("webService createCreditPaper => ", response);

                resolve(response)

            }).catch(error => {
               
                reject(error)
            })

        })

    }

   

    handleChangeDate(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            const { periodSubmit,
                periodCheck,
                periodWarranty,
                periodFinance,
                periodOther,
                periodDelayed } = this.state
            const periodMinTotal = parseInt(periodSubmit) + parseInt(periodCheck) + parseInt(periodWarranty) + parseInt(periodFinance) + parseInt(periodOther) + parseInt(periodDelayed)
            this.setState({ periodMinTotal })
        })
    }

    render() {
        const { classes } = this.props;
        const { creditDetail } = this.props

        if (creditDetail != null) {
            return (
                <div className={classes.content}>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        key={`TopRight`}
                        open={this.state.isSaveSuccess}
                        onClose={() => {
                            this.setState({ isSaveSuccess: false })
                        }}
                        variant="success"
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        autoHideDuration={2000}
                        message={<span id="message-id">บันทึกข้อมูลแล้ว</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={() => {
                                    this.setState({ isSaveSuccess: false })
                                }}
                            >
                                <Icon>close</Icon>
                            </IconButton>,
                        ]}
                    />

                    <Formik
                        ref={ref => {
                            this.form = ref
                        }}
                        enableReinitialize={!this.props.isCreate}
                        initialValues={{
                            requestDate: creditDetail.createDate || '',
                            contractDocName: creditDetail.contractDocName || '',
                            contractObjectiveName: creditDetail.contractObjectiveName || '',
                            contractNumber: creditDetail.contractNumber || '',
                            contractEnforceDate: creditDetail.contractEnforceDate || '',
                            contractExpireDate: creditDetail.contractExpireDate || '',
                            gasStartDate: creditDetail.gasStartDate || '',
                            companyName: creditDetail.companyName || '',
                            customerTax: creditDetail.customerTax || '',
                            sapProductDeliveryId: creditDetail.sapProductDeliveryId || '',
                            bankCode: creditDetail.bankCode || '',
                            sapBusinessTypeId: creditDetail.sapBusinessTypeId || '',
                            sapShipToCode: creditDetail.sapShipToCode || '',
                            sapBusinessAreaId: creditDetail.sapBusinessAreaId || '',
                            saleAreaManager: creditDetail.saleAreaManager || '',
                            collateralModelCode: creditDetail.collateralModelCode || '',
                            guaranteeAmount: creditDetail.guaranteeAmount || '',
                            sapCompanyId: creditDetail.sapCompanyId || '',
                            currencyCode: 'บาท',
                            collateralEnforceDate: creditDetail.collateralEnforceDate || '',
                            collateralEndDate: creditDetail.collateralEndDate || '',
                            collateralTypeCode: creditDetail.collateralTypeCode || '',
                            collateralAmendDate: creditDetail.collateralAmendDate || '',
                            collateralCancelDate: creditDetail.collateralCancelDate || '',
                            collateralDecreaseDate: creditDetail.collateralDecreaseDate || '',
                            overdueInformation: creditDetail.overdueInformation || '',
                            bankBranch: creditDetail.bankBranch||'',
                            bgNo:creditDetail.bgNo||'',
                            issueDate:creditDetail.issueDate||''

                        }}
                        validate={values => {
                            const requires = ['contractDocName', 'contractNumber', 'contractObjectiveName', 'guaranteeAmount', 'collateralEnforceDate', 'collateralEndDate', 'issueDate', 'customerTax', 'companyName', 'bgNo', 'bankCode','sapBusinessAreaId']
                          
                            let errors = {};

                            if (values['customerTax'].toString().length < 13){
                                errors['customerTax'] = 'กรุณากรอกเลขประจำตัวผู้เสียภาษี 13 หลัก'
                            }
                            
                            requires.forEach(field => {
                                if (!values[field]) {
                                    errors[field] = 'Required';
                                }
                            });


                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            console.log("test log 1");
                            this.setState({ isLoading: true, isLoadingSubmit: true })
                            this.setButtonNavigation()
                            this.createCreditPaper(values).then(response => {
                                console.log("test log 2");
                                this.setState({ isLoading: false, })
                                this.defaultLoadingButton()
                                setTimeout(() => {
                                    this.dialogApp.showDialogSuccess("บันทึกเรียบร้อย", `บันทึกใบงานเรียบร้อย`, () => {
                                        this.props.history.push(`/`)
                                    })
                                }, 300);

                            }).catch(error => {
                                this.setState({ isLoading: false, isLoadingSave: false, isLoadingSubmit: false })
                                this.defaultLoadingButton()
                            })

                        }}
                        render={({ submitForm, isSubmitting, values, setFieldValue, handleChange, setFieldError }) => {

                            return (
                                <Form>

                                    <br />
                                    <Card>
                                        <div className={classes.containerHeaderdata}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>เอกสารเลขที่</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.requestNo}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>ตรวจรับจาก ปภญ.</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{creditDetail.getDate}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>ตรวจสอบคืนจาก ปภญ.</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{creditDetail.returnDate}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> 
                                                {/* <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>แบบหนังสือค้ำประกัน</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.collateralModelCode}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>ผู้รับผลประโยชน์</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.beneficiaryName}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                            </Grid>
                                            <Grid container className={classes.contentHeader}>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>สร้างเมื่อ</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.createDate}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>&nbsp;</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>&nbsp;</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> 
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>ชื่อผู้รับคืน</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{creditDetail.receiver}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> 
                                                {/* <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>สถานะเอกสาร</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.beneficiaryTax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                                {/* <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>เลขทะเบียนนิติบุคคล</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.beneficiaryTax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                            </Grid>
                                            <Grid container className={classes.contentHeader}>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>สร้างโดย</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.createdBy}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                
                                            </Grid>
                                            
                                        </div>
                                    </Card>

                                    <br />
                                    {(this.props.creditDetail && this.props.creditDetail.remarkReject && this.props.creditDetail.remarkReject != '') && <Card>
                                        <div className={classes.containerRemark}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Icon color="error">info</Icon>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.textRemark}>
                                                        {this.props.creditDetail.remarkReject}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                        </div>
                                    </Card>}

                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 1 ข้อมูลหนังสือสัญญา" />
                                        <div className={classes.containerGroup}>
                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractDocName")}
                                                                type="text"
                                                                name="contractDocName"
                                                                fullWidth
                                                                label="ชื่อหนังสือสัญญา"
                                                                margin="normal"
                                                                required
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField('contractNumber')}
                                                                type="text"
                                                                name="contractNumber"
                                                                fullWidth
                                                                label="เลขที่สัญญา"
                                                                margin="normal"
                                                                required
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractEnforceDate")}
                                                                name="contractEnforceDate"
                                                                fullWidth
                                                                label="วันที่สัญญามีผลบังคับใช้"
                                                                margin="normal"
                                                                maxDate={this.state.contractExpireDate}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                   
                                                                    this.setState({ contractEnforceDate: dateAD })
                                                                    setFieldValue('contractEnforceDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractExpireDate")}
                                                                name="contractExpireDate"
                                                                fullWidth
                                                                label="วันที่สัญญาสิ้นสุด"
                                                                margin="normal"
                                                                minDate={this.state.contractEnforceDate}
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    this.setState({ contractExpireDate: dateAD })
                                                                    setFieldValue('contractExpireDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("gasStartDate")}
                                                                name="gasStartDate"
                                                                fullWidth
                                                                label="วันที่เริ่มจ่ายก๊าซ"
                                                                margin="normal"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    setFieldValue('gasStartDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={12} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractObjectiveName")}
                                                                type="text"
                                                                name="contractObjectiveName"
                                                                fullWidth
                                                                label="วัตถุประสงค์ของสัญญา"
                                                                margin="normal"
                                                                required
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    <Grid xs={12} md={6} item style={style.itemField}>
                                                        <div>
                                                            <Typography className={classes.labelColor} variant="body2">เอกสารแนบ(ไม่เกิน 10 MB / File)</Typography>
                                                            <br />
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item style={style.itemField}>

                                                                    <label htmlFor="button-file">
                                                                        <ButtonSearchCircle
                                                                            icon="attach_file"
                                                                            size="small"
                                                                            label="Search"
                                                                            containerElement='label'
                                                                            component="span"
                                                                            disabled={this.state.isLoading || this.checkDisableField("btnAttachFile")}
                                                                            isLoading={this.state.isLoadingUploadFile}
                                                                            onClick={() => {

                                                                            }} >
                                                                            <input
                                                                                id="button-file"
                                                                                multiple
                                                                                onChange={(e) => {


                                                                                    for (let index = 0; index < e.target.files.length; index++) {
                                                                                        const element = e.target.files[index];
                                                                                        if (((element.size / 1024) / 1024) > 10) {
                                                                                            this.dialogApp.showDialogFail("ผิดพลาด", "ไฟล์ขนาดใหญ่เกิน 10 MB", () => { })

                                                                                            return
                                                                                        }
                                                                                    }

                                                                                    this.uploadFiles(e.target.files, 'fileUploads')

                                                                                }}
                                                                                type="file"
                                                                                style={{
                                                                                    opacity: 0, overflow: 'hidden',
                                                                                    position: 'absolute',
                                                                                    width: 30,
                                                                                    zIndex: 1,
                                                                                }} />
                                                                        </ButtonSearchCircle>
                                                                    </label>
                                                                    

                                                                </Grid>
                                                                <Grid item xs style={style.itemField}>
                                                                    {this.state.fileUploads.map((element, index) => {
                                                                        return (
                                                                            <Chip
                                                                                className={classes.clipFile}
                                                                                key={index}
                                                                                label={element.fileName}
                                                                                onClick={() => {
                                                                                    this.downloadFile(element)
                                                                                }}
                                                                                onDelete={() => {
                                                                                    if (!this.checkDisableField("btnAttachFile")) {
                                                                                        this.removeFile(element, 'fileUploads')
                                                                                    }

                                                                                }}
                                                                            />

                                                                        )
                                                                    })}


                                                                </Grid>

                                                            </Grid>
                                                            <br />
                                                            <Divider />
                                                        </div>

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 2 ข้อมูลหนังสือค้ำประกัน" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                type="text"
                                                                required
                                                                name="bgNo"
                                                                fullWidth
                                                                label="เลขที่หนังสือค้ำประกัน"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField()}
                                                                name="issueDate"
                                                                fullWidth
                                                                label="วันที่ออกหนังสือค้ำประกัน"
                                                                required
                                                                margin="normal"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    setFieldValue('issueDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("guaranteeAmount")}
                                                                type="number"
                                                                name="guaranteeAmount"
                                                                fullWidth
                                                                label="จำนวนเงินค้ำประกัน"
                                                                maxLength={15}
                                                                margin="normal"
                                                                required
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={true}
                                                                type="text"
                                                                name="currencyCode"
                                                                fullWidth
                                                                label="สกุลเงิน"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField()}
                                                                name="collateralEnforceDate"
                                                                fullWidth
                                                                label="วันที่มีผลบังคับใช้หนังสือค้ำประกัน"
                                                                required
                                                                margin="normal"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    setFieldValue('collateralEnforceDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField('collateralEndDate')}
                                                                name="collateralEndDate"
                                                                fullWidth
                                                                label="วันที่ครบกำหนด"
                                                                required
                                                                margin="normal"
                                                                type="date"
                                                                minDate={this.state.minDueDate}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    setFieldValue('collateralEndDate', e)
                                                                }}
                                                            />
                                                        </Grid>




                                                        {this.props.match.params.action === 'cancel' &&
                                                            <Grid xs={12} md={6} item style={style.itemField}>
                                                                <Field
                                                                    disabled={this.checkDisableField("collateralCancelDate")}
                                                                    name="collateralCancelDate"
                                                                    fullWidth
                                                                    label="วันที่มีผลบังคับยกเลิกหนังสือค้ำประกัน"
                                                                    margin="normal"
                                                                    type="date"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    component={MTextField}
                                                                    onChange={(e) => {
                                                                        handleChange(e)

                                                                    }}
                                                                /></Grid>}
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 3 ข้อมูลลูกค้า" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item xs style={style.itemField}>
                                                                    <Field
                                                                        disabled={this.checkDisableField()}
                                                                        type="text"
                                                                        name="sapCompanyId"
                                                                        fullWidth
                                                                        label="รหัสลูกค้าในระบบ SAP"
                                                                        margin="normal"
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                            setFieldValue("sapCompanyId", e.target.value)


                                                                        }}
                                                                        component={MTextField}
                                                                    />
                                                                </Grid>

                                                                <Grid item style={style.itemField}>
                                                                    <ButtonSearchCircle
                                                                        icon="search"
                                                                        size="small"
                                                                        label="Search"
                                                                        disabled={this.state.isLoading || this.checkDisableField("btnSearch")}
                                                                        isLoading={this.state.isLoadingSearch}
                                                                        onClick={() => {
                                                                          
                                                                            if (values['sapCompanyId'].length >= 8 && values['sapCompanyId'].length <= 10) {
                                                                                this.searchCustomerByTaxID(values.sapCompanyId)
                                                                            } else {
                                                                                setFieldError('sapCompanyId', 'รหัสลูกค้าในระบบ SAP ต้องมากกว่า 8 และ น้อยกว่า 10')
                                                                            }

                                                                        }} />
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}></Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                required
                                                                disabled={this.checkDisableField() || values.sapCompanyId != ''}
                                                                type="tax"
                                                                name="customerTax"
                                                                fullWidth
                                                                label="เลขประจำตัวผู้เสียภาษี"
                                                                margin="normal"
                                                                maxLength={13}
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                required
                                                                disabled={this.checkDisableField() || values.sapCompanyId != ''}
                                                                type="text"
                                                                name="companyName"
                                                                fullWidth
                                                                label="ชื่อบริษัทลูกค้า (ไทย)"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 4 ธนาคารผู้ออกหนังสือค้ำประกัน" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("bankCode")}
                                                                fullWidth
                                                                // required={this.props.account && this.props.account.role === roles.CUS}
                                                                required
                                                                type="text"
                                                                name="bankCode"
                                                                label="ธนาคาร"
                                                                options={this.state.bankList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.bankList.map(option => (
                                                                    <MenuItem key={option.bankCode} value={option.bankCode}>
                                                                        {option.bankName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField()}
                                                                type="text"
                                                                name="bankBranch"
                                                                fullWidth
                                                                label="สาขา"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 5 ข้อมูลทั่วไป" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("sapShipToCode")}
                                                                type="text"
                                                                name="sapShipToCode"
                                                                fullWidth
                                                                label="รหัสที่ส่งสินค้า (SAP ship-to)"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField('saleAreaManager')}
                                                                type="text"
                                                                name="saleAreaManager"
                                                                fullWidth
                                                                label="ผู้จัดการเขตการขาย"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("sapBusinessTypeId")}
                                                                fullWidth
                                                                type="text"
                                                                name="sapBusinessTypeId"
                                                                label="ประเภทธุรกิจ"
                                                                options={this.state.sapBussinessTypeList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.sapBussinessTypeList.map(option => (
                                                                    <MenuItem key={option.sapBusinessTypeId} value={option.sapBusinessTypeId}>
                                                                        {option.sapBusinessTypeDescription}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("sapProductDeliveryId")}
                                                                fullWidth
                                                                type="text"
                                                                name="sapProductDeliveryId"
                                                                label="ช่องทางการขนส่งผลิตภัณฑ์"
                                                                options={this.state.sapProductDeliveryList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.sapProductDeliveryList.map(option => (
                                                                    <MenuItem key={option.sapProductDeliveryId} value={option.sapProductDeliveryId}>
                                                                        {option.sapProductDeliveryDescription}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("sapBusinessAreaId")}
                                                                fullWidth
                                                                type="text"
                                                                required
                                                                name="sapBusinessAreaId"
                                                                label="Business Area"
                                                                options={this.state.sapAreaList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.sapAreaList.map(option => (
                                                                    <MenuItem key={option.sapBusinessAreaId} value={option.sapBusinessAreaId}>
                                                                        {option.sapBusinessAreaDescription}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    


                                    <br />

                                </Form>
                            )
                        }}
                    />

                    <DialogApp ref={ref => {
                        this.dialogApp = ref
                    }} />

                </div>
            )
        } else {

            return (<div>
                {this.state.isLoading ? <Loading /> : <Empty label="ไม่สามารถแสดงข้อมูลได้" description="เนื่องจากมีข้อผิดพลาด กรุณาลองใหม่" />}
            </div>)
        }


    }

    checkDisableField(fieldName) {

        const { creditDetail } = this.props


        return !this.checkRoleArf()

    }

    renderButton() {

        const { creditDetail } = this.props


        if(this.checkRoleArf()){
            return (
                <Grid container spacing={2}>
                    <Grid item>
                        <ButtonSave variant="contained" disabled={this.state.isLoading} isLoading={this.state.isLoadingSave} onClick={async () => {

                            this.form.getFormikContext().validateForm(this.form.getFormikContext().values).then(async response => {
                                if (Object.entries(response).length === 0 && response.constructor === Object) {

                                    this.form.setFieldValue('action', 'save')
                                    await Promise.resolve()
                                    this.form.submitForm()

                                } else {
                                    this.dialogApp.showDialogFail(`กรอกข้อมูลไม่ถูกต้อง หรือ ไม่ครบถ้วน กรุณาตรวจสอบ`, ``, () => {

                                    })
                                }
                            })
                            

                        }} />
                    </Grid>
                </Grid>
            )
        }
        


    }

    checkRoleArf() {
        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.ARF
    }

    subtractDate(startDate, endDate) {
        let diff = Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24);
     
        return diff
    }


    uploadFiles(files, stateUploadName) {

        this.setState({ isLoading: true, isLoadingUploadFile: true })

        const apiUploadFiles = []
        for (let index = 0; index < files.length; index++) {
            const element = files[index];
            apiUploadFiles.push(this.uploadFile(element))

        }
        axios.all(apiUploadFiles).then(response => {
          
            const fileUploads = this.state[stateUploadName]
            response.forEach(element => {
                fileUploads.push(element.data.data)
            })
            this.setState({ isLoading: false, isLoadingUploadFile: false, [stateUploadName]: fileUploads })
        }).catch(error => {
          
            this.setState({ isLoading: false, isLoadingUploadFile: false })
        })

    }

    uploadFile(attachFile) {

        const parameter = new FormData()
        parameter.append("file", attachFile)
        return this.props.webService.uploadFile(parameter)

    }

    removeFile(file, stateUploadName) {

        const parameter = { "filePath": file.filePath }
        this.setState({ isLoading: true, isLoadingUploadFile: true })
        const fileUploads = this.state[stateUploadName]

        this.props.webService.removeFile(parameter).then(response => {
           
            fileUploads.splice(fileUploads.indexOf(file), 1)
            this.setState({ isLoading: false, isLoadingUploadFile: false, [stateUploadName]: fileUploads, })
        }).catch(error => {
            this.setState({ isLoading: false, isLoadingUploadFile: false })
        })

    }

    downloadFile(file) {

        const parameter = { filePath: file.filePath }
        this.props.webService.downloadFile(parameter).then(response => {


            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', file.fileName);
            // document.body.appendChild(link);
            // link.click();

            var newBlob = new Blob([response.data])

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, file.fileName);
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            // link.download = file.fileName;
            link.setAttribute('download', file.fileName);
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);

        }).catch(error => {

        })

    }
   
    
}



const mapStateToProps = ({ account, step }) => {

    return (
        {
            account: account.user,
            // step: step.step
        }
    )
}

export default connect(mapStateToProps, { actionGetStatusSave, actionNavigationenu, menuNavigationSlice })(withRouter(WithWebService(withStyles(styles)(RequestBGPaper))))