import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

class DialogApp extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            isOpenDialog:false,
            message:'',
            icon:'error_outline',
            color:'error',
            onClose:null,
            title:"",
            isOpenConfirmDialog: false,
            onConfirm: null,
        }
    }

    handleClose = () => {
        this.setState({ isOpenDialog: false ,isOpenConfirmDialog:false});
        if(this.state.onClose !=null){
            this.state.onClose()
        }
    };

    showDialogSuccess(title, message, onClose){
        this.setState({ isOpenDialog: true, onClose, title, message, icon: 'check_circle_outline', color: 'green'})
    }

    showDialogFail(title, message, onClose) {
        this.setState({ isOpenDialog: true, onClose, title, message, icon: 'error_outline', color: 'red' })
    }

    handleCloseConfirm = () => {
        this.setState({ isOpenDialog: false, isOpenConfirmDialog: false });
        if (this.state.onConfirm != null) {
            this.state.onConfirm()
        }
    };

    showDialogConfirm(title, message, onClose, onConfirm) {
        this.setState({ isOpenConfirmDialog: true, onClose, title, message, onConfirm })
    }

    render(){

        return(
           <div>
                <Dialog
                    open={this.state.isOpenDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'sm'}
                >

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                        <DialogTitle>
                            <Icon style={{ fontSize: 50, color: this.state.color }}>
                                {this.state.icon}
                            </Icon>
                        </DialogTitle>

                        <DialogContent>
                            <Typography align="center" variant="h6" style={styles.textValue} >{this.state.title}</Typography>
                        </DialogContent>


                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="inherit" style={styles.textValue} align="center">{this.state.message}</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={this.handleClose} color="secondary" style={{ marginBottom: 24 }}>
                                OK
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <Dialog
                    open={this.state.isOpenConfirmDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth={'sm'}
                >

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                        <DialogTitle>
                            <Typography align="center" variant="h6">{this.state.title}</Typography>
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="inherit" align="center" style={styles.textValue}>{this.state.message}</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={this.handleClose} color="secondary" style={{ marginBottom: 24 }}>
                                CANCEL
                            </Button>
                            <Button variant="contained" onClick={this.handleCloseConfirm} color="secondary" style={{ marginBottom: 24 }}>
                                CONFIRM
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
           </div>
        )
    }
}

const styles = {
    textValue: {
        overflowWrap: 'break-word',
        wordBreak: 'break-all'
    }
}


export default DialogApp