import {LOGIN} from "../types"

export const actionLogin = (user) =>{

    return(
        {
            type:LOGIN,
            user:user
        }
    )
}