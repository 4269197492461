import React from 'react'
import axios from 'axios'

class VendorLoginView extends React.Component{

    constructor(props){
        super(props)

        this.state = {token:''}
    }

    componentWillMount(){

        this.sendLogin()
    }

    sendLogin(){


        axios.get('http://ebg-backend.herokuapp.com/ebg/login/vendor', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'x-access-token':this.props.match.params.token,
                'Content-Type':'application/x-www-form-urlencoded'
            }}).then(response =>{
                this.setState({ token: JSON.stringify(response.data)})
            }).catch(error =>{
                this.setState({ token: JSON.stringify(error.message.data) })
            })

        
    }

    render(){

        return(
            <div>
                Response {this.state.token}
            </div>
        )
    }
}

export default VendorLoginView