import { NAVIGATION } from "../types"

export const actionNavigationenu = (menuNavigation) => {

    return (
        {
            type: NAVIGATION,
            menuNavigation: menuNavigation
        }
    )
}