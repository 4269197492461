import { FECT_STEP } from '../types'
import { post } from '../../utils/WebService'

export const actionGetStatusSave = (query) => {

    return (dispatch) => {
        dispatch({ type: FECT_STEP.PENDING })

        return new Promise((resolve, reject) => {

            post(`credit/stepInfo`, query).then(response => {
                dispatch({ type: FECT_STEP.SUCCESS, step: response.data.data })
                resolve(response)
            }).catch(error => {
                dispatch({ type: FECT_STEP.FAILED })
                reject(error)
            })

        })
    }
}