import { createSlice } from "@reduxjs/toolkit";


const configData = {
    accountLogin : "",
}

const login = createSlice({
    name: "login",
    initialState: configData,
    reducers: {
      updateLogin: (state, action) => {
        state = { ...state,...action.payload};
        return state;
      },
      setDefault: (state) => {
        state = { ...state,...configData};
        return state;
      },
    },
  });
  export const { updateLogin, setDefault } = login.actions
  export default login;