import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../resource/animation/loading.json'

const Loading = (props) =>{

  

    return(
        <div style={{width:'100%',height:'100%'}}>
            <Lottie 
            options={
                {
                        loop: true,
                        autoplay: true,
                        animationData: animationData.default,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                }
            }
                height={100}
                width={100}
                isStopped={false}
                isPaused={false}
            />
        </div>
    )
}

export {Loading}