import React, { Component, useEffect, useState } from 'react';
import './App.css';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import LoginView from "./scene/LoginView"
import AADLoginComp from './component/common/AADLoginComp';
import B2CLoginComp from './component/common/B2CLoginComp';
import ADLoginView from "./scene/ADLoginView"
import LoginPage from './scene/LoginPage';
import MainView from "./scene/MainView"
import Main from "./scene/Main"
import RequesteBGView from './scene/RequesteBGView'
import CreateBGView from './scene/CreateBGView'
import VenderLoginView from './scene/VendorLoginView'
import MyTaskView from './scene/MyTasksView'
import AmendListView from './scene/AmendListView'
import CancelListView from './scene/CancelListView'
import DecreaseListView from './scene/DecreaseListView'
import RegisterCustomerView from './scene/RegisterCustomerView'
import RequesteBGPaperView from './scene/RequestBGPaperView'
import RegisterUserView from './scene/RegisterUserView'
import SearchDocumentView from './scene/SearchDocumentView'
import EditRequestPaperView from './scene/EditRequestPaperView'
import CreditBgDetailVC from './scene/CreditBgDetailVC'
import ReportView from './scene/ReportView'
import RequestLogView from './scene/RequestLogView'
import ChangeForgotPasswordView from './scene/ChangeForgotPasswordView'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
// pick utils
import MomentUtils from '@date-io/moment';
import Moment from 'moment'
import "moment/locale/th";

import { getCookie } from './utils/Utilities'
import { keys } from './utils/Constants'
import 'typeface-roboto';
// Azure Active Directory

//env
import { env } from "./env"

import { MsalProvider, useMsal } from '@azure/msal-react';
import { EncryptStorage } from 'encrypt-storage';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequestAAD, msalConfigAAD } from "./authConfigAAD";
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { tenants } from './Tenants';
import * as GlobalConfigApi from './apis/GlobalConfigApi'
const palette = {
  "common": {
    "black": "#000",
    "white": "#fff",
    highlightMenu: 'rgba(10, 51, 204, 0.2)',
    searchField: "rgba(255, 255, 255, 1)",
    headerTable: '#cbd5ff',
    borderGroup: '#D4D4D4',
    verticalColor: '#FFA200',
    textTitle: '#277DCC',
    textTitleRequest: '#757575',

  },
  "background": {
    "paper": "#fff",
    "default": "#fafafa"
  },
  "primary": {
    "light": "#fff",
    "main": "#fff",
    "dark": "#3D3D3D",
    "contrastText": "rgba(39, 39, 39, 1)",
    accent: "#3D3D3D"
  },
  "secondary": {
    "light": "#7986cb",
    "main": "rgba(9, 65, 123, 1)",
    "dark": "#303f9f",
    "contrastText": "#fff"
  },
  "error": {
    "light": "#e57373",
    "main": "#f44336",
    "dark": "#d32f2f",
    "contrastText": "#fff"
  },
  "success": {
    "light": "#3CF000",
    "main": "#37DB00",
    "dark": "#29A300",
    "contrastText": "#fff"
  },
  "text": {
    "primary": "#3D3D3D",
    "secondary": "#808080)",
    "disabled": "#999999",
    "hint": "#B8B8B8"
  },
  typography: {
    fontFamily:
      'Roboto',
  },


}

const theme = createMuiTheme({
  overrides: {
    MuiStepLabel: {
      label: {
        color: palette.text.primary,
        '&$completed': {
          color: palette.secondary.main
        },
        '&$active': {
          color: palette.secondary.main
        },
      },
    },
    MuiTypography: {
      subtitle2: { fontWeight: 'bold', color: 'black' },
    },
    MuiInputLabel: {
    },
    MuiGrid: {
      item: {
        padding: 0,
        '&$spacing-xs-4': {
          padding: 0
        }
      },

    },
    MuiStepIcon: {
      root: {
        color: palette.text.disabled,
        '&$completed': {
          color: palette.secondary.main,
        },
        '&$active': {
          color: palette.secondary.main
        },
      },
      text: {
        color: "white",
        fill: "white",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: palette.secondary.main,
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: palette.secondary.main,
      },
      current: {
        color: palette.secondary.main,
      },
    },
  },
  "palette": palette

})


const PrivateRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props =>
        getCookie(keys.IS_LOGIN) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        )
      }
    />
  )
};

const LoginRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props =>
        getCookie(keys.IS_LOGIN) ? (
          <Redirect
            to={{
              pathname: "/"
            }}
          />

        ) : (
          <Component {...props} />
        )
      }
    />
  )
};
let accountCheck = false;
function App() {
  const encryptStorage = new EncryptStorage('secret-key', {
    prefix: "@CLAIM"
  });
  let inStoreReduserLogin = useSelector((state) => {
    return state.login;
  });

  const { accounts, instance } = useMsal();
  const [accountState, setAccountState] = useState("")
  const [loginTypeState, setLoginTypeState] = useState("")
  const [tenantsState, setTenantsState] = useState([
  ]);
  const [msalInstanceState, setMsalInstanceState] = useState({
    // aad: new PublicClientApplication(msalConfigAAD),
    // b2c: new PublicClientApplication(msalConfigB2C)
  })
  const [msalInstanceStatusState, setMsalInstanceStatusState] = useState(false)
  const login = encryptStorage.getItem('login')
  useEffect(() => {
    Moment.locale('th');
    var th = Moment().locale('th');
    th.localeData().months(Moment([2562, 0]))
  }, [])

  useEffect(() => {
    checkAccount();
  }, [inStoreReduserLogin.accountLogin])

  useEffect(() => {
    getTenants();
  }, [])

  useEffect(() => {
    setmsalInstance();
  }, [tenantsState])

  const createMsalConfig = (data, data2) => {
    let auth = {
      // clientId: `${data2.client_id}`,
      // authority: `${data2.authority}`,
      // redirectUri: `http://localhost:3000/fe-azt-react-dev/signin-oidc-spa-AD-DO66012-BGE-DEV`,

      clientId: `${data2.client_id}`,
      authority: `${data2.authority}`,
      redirectUri: `${env.REACT_APP_REDIRECT}${data2.callbackPath}`,
    };
    if (data.b2c === "Y") {
      // auth.knownAuthorities = [`${data2.knownAuthorities}`];
      // auth.postLogoutRedirectUri = `http://localhost:3000`;
      // auth.navigateToLoginRequestUrl = false;
      auth.knownAuthorities = [`${data2.knownAuthorities}`];
      auth.postLogoutRedirectUri = `${env.REACT_APP_REDIRECT}`;
      auth.navigateToLoginRequestUrl = true;
    }
    return {
      ...msalConfigAAD,
      auth,
    };
  };

  const setmsalInstance = () => {

    try {
      if (tenantsState.length > 0) {
        let msalInstance = msalInstanceState
        tenantsState.forEach((data, index) => {
          const apps = data.apps
          if (apps) {
            return apps.map((data2, index2) => {
              if (data2.show === "Y") {
                let msalConfig = createMsalConfig(data, data2);
                msalInstance = {
                  ...msalInstance,
                  [data2.client_id]: new PublicClientApplication(msalConfig)
                }
              }
            })
          }
        })

        if (_.isEmpty(msalInstanceState)) {
          setMsalInstanceState({
            ...msalInstance
          })
          setMsalInstanceStatusState(true)
        }


      }

    } catch (error) {
      console.log(error)
    }

  }

  const getTenants = async () => {
    try {
      const res = await GlobalConfigApi.doserviceFindGlobalConfigByKey({ key: "TENANT" })
      if (res.Data) {
        let tenants = res.Data;
        // console.log('res', res)
        tenants = tenants.Value
        setTenantsState(tenants.tenants)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const checkAccount = () => {
    // if(!accountCheck) {
    accountCheck = true
    // const account = encryptStorage.getItem('account')
    const loginType = encryptStorage.getItem('loginType')

    setAccountState(inStoreReduserLogin.accountLogin)
    setLoginTypeState(loginType)

  }

  const renderPageAAD = (data, data2, tenantData) => {
    const tenant = encryptStorage.getItem('tenant')
    if (login && loginTypeState === "AAD" && data2.client_id === tenant.selectApps.client_id) {
      return <Main apps={data2} />
    } else {
      if (!login) {
        return <AADLoginComp btnName={`เข้าระบบ ${data.name} สำหรับ ${data2.app_display_name}`} tenant={tenantData} apps={data2} />
      }
    }
  }

  const renderPageB2C = (data, data2, tenantData) => {
    const tenant = encryptStorage.getItem('tenant')
    // console.log("############ App.js renderPageB2C (data) : ", data);
    // console.log("############ App.js renderPageB2C (data2) : ", data2);
    // console.log("############ App.js renderPageB2C (accounts) : ", accounts);

    if (login && loginTypeState === "B2C" && data2.client_id === tenant.selectApps.client_id) {
      return <Main apps={data2} tokenRequest={data2.tokenRequest} />
    } else {
      if (!login) {
        return <B2CLoginComp btnName={`เข้าระบบ ${data.name} สำหรับ ${data2.app_display_name}`} tokenRequest={data2.tokenRequest} tenant={tenantData} apps={data2} />
      }
    }
  }

  const RenderMainPage = (props) => {
    if (login) {
      return <>{props.children}</>
    } else {
      return <>
        <Switch>
          <LoginRoute exact path="/register" component={RegisterUserView} />
          <LoginPage>

            {props.children}
          </LoginPage>
        </Switch>
      </>

    }
  }

  const checkRender = (data, data2, tenantData) => {
    if (data.b2c === "Y") {
      return renderPageB2C(data, data2, tenantData);
    } else if (data.b2c === "N") {
      return renderPageAAD(data, data2, tenantData);
    }
  }

  const renderApp = () => {
    try {
      if (msalInstanceStatusState) {
        return tenantsState.map((data, index) => {
          const apps = data.apps
          if (apps) {
            return apps.map((data2, index2) => {
              if (data2.show === "Y") {
                let tenantData = data
                return <MsalProvider instance={msalInstanceState[data2.client_id]} key={index2}>
                  {checkRender(data, data2, tenantData)}
                </MsalProvider>
              }
            })
          }
        })
      }

    } catch (error) {
      console.log(error)
    }
  }

  const renderApp_Old = () => {
    return (
      <Switch>
        <LoginRoute exact path="/login" component={ADLoginView} />
        <LoginRoute exact path="/register" component={RegisterUserView} />
        <LoginRoute exact path="/customer/forgotpassword/:token" component={ChangeForgotPasswordView} />
        <Route exact path="/login/vendor/:token" component={VenderLoginView} />
        <MainView>
          <Switch>
            <PrivateRoute exact path='/' component={MyTaskView} />
            <PrivateRoute exact path='/customer/search' component={RegisterCustomerView} />
            <PrivateRoute exact path='/docment/search' component={SearchDocumentView} />
            <PrivateRoute exact path='/report' component={ReportView} />
            <PrivateRoute path='/amend' component={AmendListView} />
            <PrivateRoute path='/cancel' component={CancelListView} />
            <PrivateRoute path='/decrease' component={DecreaseListView} />
            <PrivateRoute path='/create' component={CreateBGView} />
            <PrivateRoute path='/request/:action/:requestNo' component={RequesteBGView} />
            <PrivateRoute key="request" exact path='/paper/request' component={RequesteBGPaperView} />
            <PrivateRoute key="view" exact path='/paper/request/:requestNo?' component={RequesteBGPaperView} />
            <PrivateRoute exact path='/log/request/:requestNo' component={RequestLogView} />
            <PrivateRoute exact path='/paper/edit' component={EditRequestPaperView} />
            <PrivateRoute exact path='/paper/detail/:requestNo/:documentId' component={CreditBgDetailVC} />
          </Switch>
        </MainView>
      </Switch>);
  }

  return (

    <BrowserRouter>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <div>

            <RenderMainPage>
              {renderApp()}
            </RenderMainPage>

          </div>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>




  );
}
export default App;
