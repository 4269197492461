
import React, { useRef, useState, useEffect } from 'react'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles, fade } from '@material-ui/core/styles';
import MInput from '@material-ui/core/Input';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid/Grid";
import InputBase from '@material-ui/core/InputBase/InputBase';
import Typography from '@material-ui/core/Typography/Typography';
import { Icon } from '@material-ui/core';
import { InlineDatePicker } from '../../lib/material-ui-pickers'
import Moment from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import { MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit,
        marginLeft: 0,
        width: '100%',
    },
    filed: {
        marginTop: -8
    },
    cssLabel: {
        '&$cssFocused': {
            color: theme.palette.primary.dark,
        },
    },
    textField: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '4px 4px 4px 8px',
        '&$focused': {
            boxShadow: `${fade(theme.palette.common.highlightMenu, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.common.highlightMenu,
        },
    },
    inputLabel: {
        marginBottom: 6
    },
    required: {
        color: 'red'
    },
    labelColor: {
        color: theme.palette.common.textTitle
    }
})

const MyGrid = styled(Grid)`
&.MuiGrid-item.MuiGrid-spacing-xs-4 {
  padding: 0;
}
`;


const MSelect = (withStyles(styles)((props) => {

    const { field, form: { touched, errors, isSubmitting }, classes, required, ...other } = props
    const isError = Boolean((errors[field.name] && touched[field.name]))

    // useEffect(() => {
    //         if (!isError || !isSubmitting) return;

    //         if (isError) {
    //             document.getElementById('select-'+field.name).focus()
    //         }
    //     })

    return (

        <div className={classes.filed}>
            <FormControl className={classes.formControl} error={isError} >
                {/* {props.label && <InputLabel required={required} htmlFor="age-native-simple">{props.label}</InputLabel>} */}
                <Grid container className={classes.inputLabel}>
                    <Grid item>
                        <Typography className={classes.labelColor} variant="body2">{props.label}</Typography>
                    </Grid>
                    <Grid item>
                        {required && <Typography variant="body2" className={classes.required}>&nbsp;*</Typography>}
                    </Grid>
                </Grid>

                <Select
                    
                    displayEmpty
                    {...field}
                    {...other}
                    input={<InputBase id={field.name} style={{ backgroundColor: props.disabled ? '#E0E0E0' : 'white' }} className={classes.textField} {...field} error={isError} helperText={isError && errors[field.name]}  {...other} placeholder={props.placeholder || props.label}></InputBase>}>
                    <MenuItem value="">
                        <div style={{ color: '#B3B3B3' }}>
                            {props.placeholder || props.label}
                        </div>
                    </MenuItem>
                    {props.children}
                </Select>
                {isError && <FormHelperText>{errors[field.name]}</FormHelperText>}

            </FormControl>
        </div>

    )
}))

function NumberFormatCustom(props) {
    const { inputRef, onChange, name, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            decimalScale={2}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                        name: name
                    },
                });
            }}
            thousandSeparator
            prefix=""
        />
    );
}

const MTextField = withStyles(styles)(
    (props) => {

        const { field, form: { touched, errors, isSubmitting }, classes, required, type, ...other } = props
        const isError = Boolean((errors[field.name] && touched[field.name]))

        // useEffect(() => {
        //     if (!isError || !isSubmitting) return;

        //     if (isError) {
        //         document.getElementById(field.name).focus()
        //     }
        // })

        return (
            <div className={classes.filed}>
                {/* <TextField  {...field} error={isError} helperText={isError && errors[field.name]}  {...other} /> */}

                <FormControl className={classes.formControl} error={isError} >
                    <Grid container className={classes.inputLabel}>
                        <Grid item>
                            <Typography className={classes.labelColor} variant="body2" >{props.label}</Typography>
                        </Grid>
                        <Grid item>
                            {required && <Typography variant="body2" className={classes.required}>&nbsp;*</Typography>}
                        </Grid>
                    </Grid>
                    <InputBase
                        id={field.name}
                        style={{ backgroundColor: props.disabled ? '#E0E0E0' : 'white' }}
                        className={classes.textField} {...field}
                        error={isError}
                        type={type === 'password' ? type : 'text'}
                        // type={type === 'password' ? type : (type === 'tax' ? 'number' : 'text')}
                        inputComponent={type === 'number' ? NumberFormatCustom : 'input'}
                        placeholder={props.placeholder || props.label}
                        helperText={isError && errors[field.name]}  {...other}
                        onWheel={event => { event.preventDefault(); }}
                        onChange={
                            event => {

                                var e = event
                                if (props.maxLength) {
                                    e.target.value = e.target.value.slice(0, props.maxLength);
                                }

                                if (props.positive && e.target.value.length > 0) {
                                    e.target.value = e.target.value.replace('-', "")
                                }
                                if (props.max && e.target.value > props.max) {
                                    e.target.value = props.max
                                }
                                if (props.min && e.target.value < props.min) {
                                    e.target.value = props.min
                                }


                                field.onChange(e)
                            }
                        }
                    ></InputBase>
                    {isError && <FormHelperText>{errors[field.name]}</FormHelperText>}
                </FormControl>



            </div>

        )
    }
)

const MDateField = withStyles(styles)(
    (props) => {


        const pickerRef = useRef(null);
        const [selectedDate, handleDateChange] = useState();

        const { field, form: { touched, errors, isSubmitting }, classes, onChange, required, InputLabelProps, ...other } = props
        const isError = Boolean((errors[field.name] && touched[field.name]))


        // useEffect(() => {
        //     document.getElementById(field.name).readOnly = true;

        //     if (!isError || !isSubmitting) return;

        //     if (isError) {
        //         document.getElementById(field.name).focus()
        //     }
            
        // })


        return (
            <div className={classes.filed}>
                <FormControl className={classes.formControl} error={isError} >
                    <Grid container className={classes.inputLabel}>
                        <Grid item>
                            <Typography className={classes.labelColor} variant="body2" >{props.label}</Typography>
                        </Grid>
                        <Grid item>
                            {required && <Typography variant="body2" className={classes.required}>&nbsp;*</Typography>}
                        </Grid>
                    </Grid>
                    <InputBase
                        style={{ backgroundColor: props.disabled ? '#E0E0E0' : 'white', }}
                        className={classes.textField}
                        {...field}
                        error={isError}
                        helperText={isError && errors[field.name]}
                        {...other}
                        readonly
                        type="text"
                        placeholder="dd/mm/yyyy"
                        inputProps={{
                            onClick: (e) => {

                                if (!props.disabled) {
                                    if (document.getElementById(field.name).value != "" && document.getElementById(field.name).value) {
                                        const initDate = Moment(document.getElementById(field.name).value, "DD/MM/YYYY").add("years", -543).format("DD/MM/YYYY")
                                        const newDate = Moment(initDate, "DD/MM/YYYY")


                                        handleDateChange(newDate)
                                    }

                                    if (pickerRef.current) {
                                        pickerRef.current.open(e);
                                    }
                                }


                            },
                            ...props.inputProps
                        }}
                        id={field.name}
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon color="disabled" >date_range</Icon>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={props.disabled}
                                    aria-label="toggle password visibility"
                                    onClick={() => {

                                        if (!props.disabled) {
                                            const event = {
                                                target: {
                                                    name: field.name,
                                                    value: ""
                                                }
                                            }

                                            props.onChange("", event)
                                        }


                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </InputAdornment>
                        }>

                    </InputBase>
                    {isError && <FormHelperText>{errors[field.name]}</FormHelperText>}
                </FormControl>




                <InlineDatePicker
                    style={{ display: 'none', }}
                    clearable
                    variant="outlined"
                    value={selectedDate}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    onChange={(date) => {

                        handleDateChange(date)
                        const newDate = Moment(date)
                        const returnDate = newDate.add('years', 543)


                        const event = {
                            target: {
                                name: field.name,
                                value: returnDate.format("DD/MM/YYYY")
                            }
                        }

                        props.onChange(returnDate.format("DD/MM/YYYY"), event, date)
                    }}
                    format="DD/MM/YYYY"
                    ref={pickerRef}
                />
            </div>

        )
    }
)

const MyTextField = withStyles(styles)(
    (props) => {

        const { field, classes, required, ...other } = props
        return (
         

            <FormControl className={classes.formControl} >
                <Grid container className={classes.inputLabel}>
                    <Grid item>
                        <Typography variant="body2">{props.label}</Typography>
                    </Grid>
                    <Grid item>
                        {required && <Typography variant="body2" className={classes.required}>*</Typography>}
                    </Grid>
                </Grid>
                <InputBase className={classes.textField} {...field}   {...other} placeholder={props.placeholder || props.label} 
                    onChange={ (event) => {
                    event.persist();
                    if(props.onChange){
                        props.onChange(event)
                    }
                }}
                ></InputBase>
            </FormControl>

        )
    }
)

const Input = withStyles(styles)(
    (props) => {
        const { classes, required, InputLabelProps, ...other } = props;
        const { field, form: { touched, errors, } } = props
        const isError = Boolean((errors[field.name] && touched[field.name]))

        return (

            <FormControl className={classes.formControl} error={isError} >
                <InputLabel required={required} shrink={InputLabelProps.shrink} htmlFor="name-error">{props.label}</InputLabel>
                <MInput {...field}   {...other} />
                {isError && <FormHelperText>{errors[field.name]}</FormHelperText>}

            </FormControl>
        )
    }
)

export {
    MTextField,
    MSelect,
    Input,
    MyTextField,
    MyGrid,
    MDateField
}