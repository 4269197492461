import React from 'react'
import '../../App.css'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { MDateField, MSelect, MTextField, Loading, MyTextField, Empty, ButtonSave, ButtonSubmit, ButtonApprove, ButtonReject, ButtonSearchCircle, TitleSection } from "../common"
import WithWebService from '../../utils/WebServiceInterface'
import { formatNumber, getCookie, capitalize } from "../../utils/Utilities"
import Moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { keys, roles, stepCode } from '../../utils/Constants'
import { withRouter } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogApp from '../common/DialogApp'
import { connect } from 'react-redux'
import { MenuItem } from '@material-ui/core';
import { actionGetStatusSave, actionNavigationenu } from '../../redux/actions'
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import axios from 'axios'
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactToPrint from "react-to-print";
import { Page, Document } from '@react-pdf/renderer';
import PersonIcon from '@material-ui/icons/Person';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { blue } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import { useDispatch } from "react-redux";
import { menuNavigationSlice } from "../../redux/feature/navigationSlice"

// const dispatchPage = useDispatch();

const isIE = /*@cc_on!@*/false || !!document.documentMode;

const styles = theme => ({
    content: {
        padding: 24,
        paddingTop: 0,
        paddingBottom: 8,
    },
    titleTable: {
        marginTop: 0,
        fontWeight: 'bold',
        fontSize: 18
    },
    containerGroup: {
        padding: 36
    },
    cssLabel: {
        '&$cssFocused': {
            color: theme.palette.primary.dark,
        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
    },
    cssFocused: {},
    notchedOutline: {},
    success: {
        backgroundColor: '#00C217'
    },
    labelColor: {
        color: theme.palette.common.textTitle
    },
    titleData: {
        color: theme.palette.common.textTitleRequest,
        fontSize: 14
    },
    valueData: {
        color: theme.palette.common.textTitle,
        fontSize: 14,
        overflowWrap: 'break-word',
        wordBreak: 'break-all'
    },
    containerHeaderdata: {
        padding: 24
    },
    contentHeader: {
        marginTop: 4
    },
    containerRemark: {
        padding: '8px 24px 8px 24px'
    },
    textRemark: {
        color: 'red'
    },
    clipFile: {
        marginTop: 4, marginLeft: 4,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    containerPdf: {
        padding: isIE ? 10 : 90,
        paddingBottom: isIE ? 10 : 30,
        backgroundColor: 'white',
        height: '89%'
    },
    textHistory: {
        color: theme.palette.common.textTitle
    },
    avatar: {
        backgroundColor: '#ffff',
        color: blue[600],
        width: 70,
        height: 70,
        marginRight: 16
    },


})

const style = {
    itemField: { paddingTop: 0, paddingBottom: 0 }
}

class RequestBG extends React.Component {

    constructor(props) {
        super(props)

        const creditDetail = this.props.creditDetail

        var minDueDate = new Date('1900-01-01')
        if (this.props.match.params.action === 'amend') {

            const initDate = Moment(creditDetail.collateralEndDate, "DD/MM/YYYY").add("years", -543).format("DD/MM/YYYY")
            minDueDate = Moment(initDate, "DD/MM/YYYY")
        }

        this.state = {
            isSaveSuccess: false,
            openPreview: false,
            torFlag: 'Y',
            periodMinTotal: 0,
            periodSubmit: 0,
            periodCheck: 0,
            periodWarranty: 0,
            periodFinance: 0,
            periodOther: 0,
            periodDelayed: 0,
            periodSubmitFlag: 'Y',
            requestDate: new Date(),
            collateralEnforceDate: new Date(),
            isLoading: false,
            isLoadingSave: false,
            isLoadingSearch: false,
            isLoadingSubmit: false,
            isLoadingApprove: false,
            isLoadingReject: false,
            isPopupSuccess: false,
            isPopupNote: false,
            isLoadingUploadFile: false,
            isPopupActionLog: false,
            remark: '',
            collateralModelList: [],
            collateralTypeList: [],
            sapBussinessTypeList: [],
            sapProductDeliveryList: [],
            sapAreaList: [],
            bankList: [],
            fileUploads: (creditDetail.attachFiles ? creditDetail.attachFiles.fileDataList : []) || [],
            beneficiaryName: creditDetail.beneficiaryName ? creditDetail.beneficiaryName : "บริษัท ปตท. จำกัด (มหาชน)",
            bciDocumentId: creditDetail.bciDocumentId ? creditDetail.bciDocumentId : "-",
            createDate: creditDetail.createDate ? creditDetail.createDate : Moment(new Date()).format('DD/MM/YYYY'),
            beneficiaryTax: creditDetail.beneficiaryTax ? creditDetail.beneficiaryTax : "0107544000108",
            createdBy: creditDetail.createdBy ? creditDetail.createdBy : "-",
            bankName: creditDetail.bankName ? creditDetail.bankName : "-",
            requestNo: creditDetail.requestNo ? creditDetail.requestNo : "",
            bgNo: creditDetail.bgNo ? creditDetail.bgNo : "-",
            contractEnforceDate: creditDetail.contractEnforceDate ? Moment(creditDetail.contractEnforceDate, 'DD/MM/YYYY').add("years", -543) : Moment(new Date()),
            contractExpireDate: creditDetail.contractExpireDate ? Moment(creditDetail.contractExpireDate, 'DD/MM/YYYY').add("years", -543) : Moment(new Date()).add("days", 365),
            documentId: creditDetail.documentId || '',
            requestId: creditDetail.requestId || '',
            minDueDate: minDueDate,
            fileUploadsDebtor: (creditDetail.attachFiles ? creditDetail.attachFiles.fileDataARList : []) || [],
            confirmArInformation: creditDetail.confirmArInformation ? (creditDetail.confirmArInformation === 'Y' ? true : false) : false,
            requireConfirmArInformationError: false,
            collateralModelCode: creditDetail.collateralModelCode || 'PTTPLC_MER_001_TH',
            creditDetail: creditDetail,
            actionLog: [],
            step:{
                "currentEbg": {
                    "requestNo": "",
                    "flowCode": "",
                    "flowName": ""
                },
                "create": "",
                "updateRequest": "",
                "verify": "",
                "approved": "",
                "bciProcess": "",
                "completed": ""
            },
            menuNavigation: null

        }
    }

    componentWillMount() {

        this.setState({ isLoading: true })
        Promise.all([this.getCollateralModel(), this.history(), this.getCollateralTypeList(), this.getBankList(), this.getSapBusinessArea(), this.getSapBusinessType(), this.getSapProductDelivery()]).then(response => {
            this.setState({ isLoading: false })
            this.setButtonNavigation()
        }).catch(error => {
            this.setState({ isLoading: false })
            this.setButtonNavigation()
        })

    }

    componentDidMount() {
        if (!this.props.isView) {
            this.setButtonNavigation()
        }
    }

    setButtonNavigation() {
        // this.props.actionNavigationenu(this.renderButton.bind(this))
        this.props.menuNavigationSlice(this.renderButton.bind(this))
        this.setState({ menuNavigation: this.renderButton.bind(this) })
    }

    componentWillUnmount() {
        this.props.actionNavigationenu(() => { })
        this.props.menuNavigationSlice(() => { })
    }

    history() {

        let parameter
        if (this.checkRoleCUS()) {
            parameter = { requestNo: this.props.match.params.requestNo, IsCustomer: true }
        }
        else {
            parameter = { requestNo: this.props.match.params.requestNo }
        }

        this.setState({ isLoading: true })

        this.props.webService.requestLog(parameter).then(response => {

            this.setState({
                isLoading: false,
                actionLog: response.data.data.actionLog,
            })

        }).catch(error => {
            this.setState({ isLoading: false })
        })
    }

    defaultLoadingButton() {
        this.setState({
            isLoadingSave: false,
            isLoadingApprove: false,
            isLoadingReject: false,
            isLoadingSubmit: false,
        })

        this.setButtonNavigation()
    }

    searchCustomerByTaxID(customerTax) {

        this.setState({ isLoading: true, isLoadingSearch: true })
        this.props.webService.searchCustomerByTaxID({ sapCustomerId: customerTax }).then(response => {

            this.setState({ isLoading: false, isLoadingSearch: false, })
            if (response.data.customer) {
                this.form.setFieldValue('companyName', response.data.customer.companyName)
                this.form.setFieldValue('customerTax', response.data.customer.customerTax)
            }

            if (!response.data) {

                setTimeout(() => {


                    this.dialogApp.showDialogFail(`ไม่พบ Customer ที่ค้นหา`, ``, () => {

                    })
                }, 300);
            }

        }).catch(error => {

            this.setState({ isLoading: false, isLoadingSearch: false })
        })

    }




    assign(status, requestNo) {


        if (this.props.match.params.action === 'amend') {
            this.assignmentsAmend(status, requestNo)
        } else if (this.props.match.params.action === 'decrease') {
            this.assignmentsDecrease(status, requestNo)
        } else if (this.props.match.params.action === 'cancel') {
            this.assignmentsCancel(status, requestNo)
        } else {
            this.assignments(status, requestNo)
        }

    }

    assignments(status, requestNo) {
        console.log("status ===>", status);
        console.log("status ===>", requestNo);
        const parameter = {
            "requestNo": requestNo,
            "actionAssign": status,
            "remark": this.state.remark
        }
        console.log("parameter ===>", parameter);
        this.setState({ isLoading: true })

        this.props.webService.assignments(parameter).then(response => {
            console.log("response ===>", response.data);
            this.setState({ isLoading: false })
            this.defaultLoadingButton()
            setTimeout(() => {
                this.dialogApp.showDialogSuccess("บันทึกเรียบร้อย", `บันทึกข้อมูลเรียบร้อย ใบคำขอเลขที่ ${response.data.data.requestNo} อยู่ระหว่างดำเนินการ`, () => {
                // this.dialogApp.showDialogSuccess("บันทึกเรียบร้อย", `บันทึกข้อมูลเรียบร้อย ใบคำขอเลขที่ ${parameter.requestNo} อยู่ระหว่างดำเนินการ`, () => {
                    this.props.history.push(`/`)
                })
            }, 300);
        }).catch(error => {

            this.setState({ isLoading: true })
            this.defaultLoadingButton()
        })
    }


    getCollateralModel() {

        return new Promise((resolve, reject) => {

            this.props.webService.getCollateralModel().then(response => {

                this.setState({ collateralModelList: response.data.data.collateralModelList })
                resolve()

            }).catch(error => {

                reject()
            })
        })

    }

    getBankList() {

        let parameter
        if (this.checkRoleARF()) {
            parameter = { IsARF: true }
        }

        return new Promise((resolve, reject) => {

            this.props.webService.getBankList(parameter).then(response => {

                this.setState({ bankList: response.data.data.bankList })
                resolve()

            }).catch(error => {

                reject()
            })
        })

    }

    getSapBusinessArea() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapBusinessArea().then(response => {

                this.setState({ sapAreaList: response.data.data.businessArea })
                resolve()

            }).catch(error => {

                reject()
            })
        })

    }

    getSapBusinessType() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapBusinessType().then(response => {

                this.setState({ sapBussinessTypeList: response.data.data.BusinessType })
                resolve()

            }).catch(error => {

                reject()
            })
        })

    }

    getSapProductDelivery() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapProductDelivery().then(response => {

                this.setState({ sapProductDeliveryList: response.data.data.productDelivery })
                resolve()

            }).catch(error => {

                reject()
            })
        })

    }





    getCollateralTypeList() {

        return new Promise((resolve, reject) => {

            this.props.webService.getCollateralTypeList().then(response => {

                this.setState({ collateralTypeList: response.data.data.collateralTypeList })
                resolve()

            }).catch(error => {

                reject()
            })
        })

    }


    addCreditDetail(values) {

        return new Promise((resolve, reject) => {



            const files = []
            this.state.fileUploads.forEach(element => {
                files.push(element)
            })

            const fileDataARList = []
            this.state.fileUploadsDebtor.forEach(element => {
                fileDataARList.push(element)
            })

            const valuesData = Object.assign(values, { attachFiles: { fileDataList: files, fileDataARList }, requestNo: this.state.requestNo, confirmArInformation: this.state.confirmArInformation ? 'Y' : 'N' })


            const creditDetail = this.props.creditDetail
            Object.assign(creditDetail, valuesData)



            this.props.webService.addCreditDetail(values).then(response => {


                resolve(response)

            }).catch(error => {

                reject(error)
            })

        })

    }

    checkRoleSale() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.SALES
    }

    checkArAccount() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.ARA
    }

    checkRoleCDF() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.CDF
    }

    checkRoleCDM() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.CDM
    }

    checkRoleArManager() {
        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.ARM
    }

    checkRoleCUS() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.CUS
    }

    checkRoleSM() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.SM
    }

    checkRoleSVP() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.SVP
    }

    checkRoleARF() {
        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.ARF
    }

    downloadFile(file) {

        const parameter = { filePath: file.filePath }
        this.props.webService.downloadFile(parameter).then(response => {


            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', file.fileName);
            // document.body.appendChild(link);
            // link.click();

            var newBlob = new Blob([response.data])

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, file.fileName);
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            // link.download = file.fileName;
            link.setAttribute('download', file.fileName);
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);


        }).catch(error => {

        })

    }

    handleChangeDate(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            const { periodSubmit,
                periodCheck,
                periodWarranty,
                periodFinance,
                periodOther,
                periodDelayed } = this.state
            const periodMinTotal = parseInt(periodSubmit) + parseInt(periodCheck) + parseInt(periodWarranty) + parseInt(periodFinance) + parseInt(periodOther) + parseInt(periodDelayed)
            this.setState({ periodMinTotal })
        })
    }

    render() {
        const { classes } = this.props;
        const { creditDetail } = this.props
        console.log("############ RequestBG.js (creditDetail) : ", creditDetail );
        console.log("############ RequestBG.js (stepCode) : ", stepCode );

        if (creditDetail != null) {
            return (
                <div className={classes.content}>

                    {this.props.creditDetail.requestNo && <Grid container>
                        <Grid item xs />
                        <Grid item>

                            <Button color="secondary" onClick={() => {
                                this.setState({ isPopupActionLog: true })
                            }}>ดูประวัติเอกสาร</Button>
                        </Grid>
                    </Grid>}

                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        key={`TopRight`}
                        open={this.state.isSaveSuccess}
                        onClose={() => {
                            this.setState({ isSaveSuccess: false })
                        }}
                        variant="success"
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        autoHideDuration={2000}
                        message={<span id="message-id">บันทึกข้อมูลแล้ว</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={() => {
                                    this.setState({ isSaveSuccess: false })
                                }}
                            >
                                <Icon>close</Icon>
                            </IconButton>,
                        ]}
                    />

                    <Formik
                        ref={ref => {
                            this.form = ref
                        }}
                        enableReinitialize={!this.props.isCreate}
                        initialValues={{
                            requestDate: creditDetail.createDate || '',
                            contractDocName: creditDetail.contractDocName || '',
                            contractObjectiveName: creditDetail.contractObjectiveName || '',
                            contractNumber: creditDetail.contractNumber || '',
                            contractEnforceDate: creditDetail.contractEnforceDate || '',
                            contractExpireDate: creditDetail.contractExpireDate || '',
                            gasStartDate: creditDetail.gasStartDate || '',
                            companyName: creditDetail.companyName || '',
                            customerTax: creditDetail.customerTax || '',
                            sapProductDeliveryId: creditDetail.sapProductDeliveryId || '',
                            bankCode: creditDetail.bankCode || '',
                            sapBusinessTypeId: creditDetail.sapBusinessTypeId || '',
                            sapShipToCode: creditDetail.sapShipToCode || '',
                            sapBusinessAreaId: creditDetail.sapBusinessAreaId || '',
                            saleAreaManager: creditDetail.saleAreaManager || '',
                            collateralModelCode: creditDetail.collateralModelCode || 'PTTPLC_MER_001_TH',
                            guaranteeAmount: creditDetail.guaranteeAmount || '',
                            sapCompanyId: creditDetail.sapCompanyId || '',
                            currencyCode: 'บาท',
                            collateralEnforceDate: creditDetail.collateralEnforceDate || '',
                            collateralEndDate: creditDetail.collateralEndDate || '',
                            collateralTypeCode: creditDetail.collateralTypeCode || '',
                            collateralAmendDate: creditDetail.collateralAmendDate || '',
                            collateralCancelDate: creditDetail.collateralCancelDate || '',
                            collateralDecreaseDate: creditDetail.collateralDecreaseDate || '',
                            overdueInformation: creditDetail.overdueInformation || ''

                        }}
                        validate={values => {
                            const requires = ['contractDocName', 'contractNumber', 'contractObjectiveName', 'guaranteeAmount', 'collateralEnforceDate', 'collateralEndDate', 'customerTax', 'companyName', 'contractEnforceDate', 'collateralModelCode', 'sapBusinessAreaId']
                            if (this.props.account && this.props.account.role === roles.CUS) {
                                requires.push('bankCode')
                            }

                            // if (this.checkArAccount() && creditDetail.stepCode === stepCode.VERIFY_BY_AR_ACCOUNTANT && this.props.match.params.action === "cancel") {
                            //     requires.push('overdueInformation')
                            // }

                            let errors = {};
                            requires.forEach(field => {
                                if (!values[field]) {
                                    errors[field] = 'Required';
                                }
                            });

                            if (values['customerTax'].toString().length < 13) {
                                errors['customerTax'] = 'กรุณากรอกเลขประจำตัวผู้เสียภาษี 13 หลัก'
                            }

                            if ((values['sapShipToCode'] && values['sapShipToCode'] != "") && values['sapShipToCode'].length > 10) {
                                errors["sapShipToCode"] = 'รหัสที่ส่งสินค้า (SAP ship-to) ตความยาต้องยสาวน้อยกว่า 10';
                            }

                            if (this.props.match.params.action === "amend" && (this.checkRoleSale() || this.checkRoleSM()) && creditDetail.stepCode === stepCode.CREATE) {
                                if (values['guaranteeAmount'] < creditDetail.temAmount) {
                                    errors["guaranteeAmount"] = `จำนวนเงินค้ำประกัน ต้องมากกว่า ${formatNumber(creditDetail.temAmount)}`
                                }
                            }

                            if (this.props.match.params.action === "decrease" && (this.checkRoleSale() || this.checkRoleSM()) && creditDetail.stepCode === stepCode.CREATE) {

                                if (values['guaranteeAmount'] >= creditDetail.temAmount) {
                                    errors["guaranteeAmount"] = `จำนวนเงินค้ำประกัน ต้องน้อยกว่า ${formatNumber(creditDetail.temAmount)}`
                                }

                                if (!values['guaranteeAmount']) {
                                    errors['guaranteeAmount'] = 'Required';
                                }

                            }



                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);

                            if (values.action === 'save') {
                                this.setState({ isLoading: true, isLoadingSave: true })
                                this.setButtonNavigation()
                                this.addCreditDetail(values).then((response) => {

                                    this.setState({ requestNo: response.data.data ? response.data.data.requestNo : '', })
                                    this.setState({ isLoading: false, isLoadingSave: false, isSaveSuccess: true, })
                                    this.setButtonNavigation()
                                    if (this.props.onSave) {
                                        this.props.onSave(response.data.data.requestNo)
                                    }

                                })
                            } else if (values.action != '') {

                                var isValidate = true
                                if (this.props.match.params.action === "cancel") {

                                    if (this.checkRoleArManager() &&
                                        this.props.match.params.action === "cancel" &&
                                        creditDetail.stepCode === stepCode.VERIFY_BY_AR_MANAGER && !this.state.confirmArInformation) {
                                        isValidate = false
                                        this.setState({ requireConfirmArInformationError: true })
                                    }
                                }

                                if (isValidate) {

                                    this.setState({ isLoading: true, isLoadingSubmit: true })
                                    this.setButtonNavigation()
                                    this.addCreditDetail(values).then(response => {

                                        this.assign(capitalize(values.action), response.data.data.requestNo)
                                    }).catch(error => {
                                        this.setState({ isLoading: false, isLoadingSave: false, isLoadingSubmit: false })
                                        this.defaultLoadingButton()
                                    })
                                }

                            }

                        }}
                        render={({ submitForm, isSubmitting, values, setFieldValue, handleChange, setFieldError }) => {

                            return (
                                <Form>

                                    <br />
                                    <Card>
                                        <div className={classes.containerHeaderdata}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>เอกสารเลขที่</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.requestNo}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>ผู้รับผลประโยชน์</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.beneficiaryName}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.contentHeader}>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>สร้างเมื่อ</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.createDate}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>เลขทะเบียนนิติบุคคล</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.beneficiaryTax}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.contentHeader}>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>สร้างโดย</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.createdBy}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>ธนาคาร</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.bankName}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Divider />
                                            <br />
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>เลขที่หนังสือค้ำประกัน</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.bgNo}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Typography className={classes.titleData}>เลขเอกสารอิเล็กทรอนิกส์</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography className={classes.valueData}>{this.state.documentId}</Typography>
                                                            <Typography className={classes.valueData}>{this.state.requestId}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>

                                    <br />
                                    {(this.props.creditDetail && this.props.creditDetail.remarkReject && this.props.creditDetail.remarkReject != '') && <Card>
                                        <div className={classes.containerRemark}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Icon color="error">info</Icon>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.textRemark}>
                                                        {this.props.creditDetail.remarkReject}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <div>
                                                <Typography variant="caption">
                                                    {`จาก : ${this.props.creditDetail.assignName}  เมื่อ : ${this.props.creditDetail.assignDate}`}
                                                </Typography>
                                            </div>

                                        </div>
                                    </Card>}

                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 1 ข้อมูลหนังสือสัญญา" />
                                        <div className={classes.containerGroup}>
                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractDocName")}
                                                                type="text"
                                                                name="contractDocName"
                                                                fullWidth
                                                                label="ชื่อหนังสือสัญญา"

                                                                margin="normal"
                                                                required
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField('contractNumber')}
                                                                type="text"
                                                                name="contractNumber"
                                                                fullWidth
                                                                label="เลขที่สัญญา"
                                                                margin="normal"
                                                                required
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractEnforceDate")}
                                                                name="contractEnforceDate"
                                                                fullWidth
                                                                label="วันที่สัญญามีผลบังคับใช้"
                                                                margin="normal"
                                                                required
                                                                maxDate={this.state.contractExpireDate}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {

                                                                    this.setState({ contractEnforceDate: dateAD })
                                                                    setFieldValue('contractEnforceDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractExpireDate")}
                                                                name="contractExpireDate"
                                                                fullWidth
                                                                label="วันที่สัญญาสิ้นสุด"
                                                                margin="normal"
                                                                minDate={this.state.contractEnforceDate}
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    this.setState({ contractExpireDate: dateAD })
                                                                    setFieldValue('contractExpireDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("gasStartDate")}
                                                                name="gasStartDate"
                                                                fullWidth
                                                                label="วันที่เริ่มจ่ายก๊าซ"
                                                                margin="normal"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    setFieldValue('gasStartDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={12} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("contractObjectiveName")}
                                                                type="text"
                                                                name="contractObjectiveName"
                                                                fullWidth
                                                                rows={10}
                                                                multiline
                                                                label="วัตถุประสงค์ของสัญญา"
                                                                margin="normal"
                                                                required
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    {!this.checkRoleCUS() && <Grid xs={12} md={6} item style={style.itemField}>
                                                        <div>
                                                            <Typography className={classes.labelColor} variant="body2">เอกสารแนบ(ไม่เกิน 10 MB / File)</Typography>
                                                            <br />
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item style={style.itemField}>
                                                                    <label htmlFor="button-file">
                                                                        <ButtonSearchCircle
                                                                            icon="attach_file"
                                                                            size="small"
                                                                            label="Search"
                                                                            containerElement='label'
                                                                            component="span"
                                                                            disabled={this.state.isLoading || this.checkDisableField("btnAttachFile")}
                                                                            isLoading={this.state.isLoadingUploadFile}
                                                                            onClick={() => {

                                                                            }} >
                                                                            <input
                                                                                id="button-file"
                                                                                multiple
                                                                                onChange={(e) => {

                                                                                    if (e.target.files.length <= 0) {
                                                                                        return
                                                                                    }
                                                                                    for (let index = 0; index < e.target.files.length; index++) {
                                                                                        const element = e.target.files[index];
                                                                                        if (((element.size / 1024) / 1024) > 10) {
                                                                                            this.dialogApp.showDialogFail("ผิดพลาด", "ไฟล์ขนาดใหญ่เกิน 10 MB", () => { })

                                                                                            return
                                                                                        }
                                                                                    }

                                                                                    this.uploadFiles(e.target.files, 'fileUploads')

                                                                                }}
                                                                                type="file"
                                                                                style={{
                                                                                    opacity: 0, overflow: 'hidden',
                                                                                    position: 'absolute',
                                                                                    width: 30,
                                                                                    zIndex: 1,
                                                                                }} />
                                                                        </ButtonSearchCircle>
                                                                    </label>


                                                                </Grid>
                                                                <Grid item xs style={style.itemField}>
                                                                    {this.state.fileUploads.map((element, index) => {
                                                                        return (
                                                                            <Chip
                                                                                className={classes.clipFile}
                                                                                key={index}
                                                                                label={element.fileName}
                                                                                onClick={() => {
                                                                                    this.downloadFile(element)
                                                                                }}
                                                                                onDelete={() => {
                                                                                    if (!this.checkDisableField("btnAttachFile")) {
                                                                                        this.removeFile(element, 'fileUploads')
                                                                                    }

                                                                                }}
                                                                            />

                                                                        )
                                                                    })}


                                                                </Grid>

                                                            </Grid>
                                                            <br />
                                                            <Divider />
                                                        </div>

                                                    </Grid>}

                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 2 ข้อมูลหนังสือค้ำประกัน" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("guaranteeAmount")}
                                                                type="number"
                                                                name="guaranteeAmount"
                                                                fullWidth
                                                                label="จำนวนเงินค้ำประกัน"
                                                                margin="normal"
                                                                required
                                                                maxLength={15}
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={true}
                                                                type="text"
                                                                name="currencyCode"
                                                                fullWidth
                                                                label="สกุลเงิน"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField()}
                                                                name="collateralEnforceDate"
                                                                fullWidth
                                                                label="วันที่มีผลบังคับใช้หนังสือค้ำประกัน"
                                                                required
                                                                margin="normal"
                                                                type="date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    setFieldValue('collateralEnforceDate', e)
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField('collateralEndDate')}
                                                                name="collateralEndDate"
                                                                fullWidth
                                                                label="วันที่ครบกำหนด"
                                                                required
                                                                margin="normal"
                                                                type="date"
                                                                minDate={this.state.minDueDate}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                component={MDateField}
                                                                onChange={(e, event, dateAD) => {
                                                                    setFieldValue('collateralEndDate', e)
                                                                }}
                                                            />
                                                        </Grid>




                                                        {this.props.match.params.action === 'cancel' &&
                                                            <Grid xs={12} md={6} item style={style.itemField}>
                                                                <Field
                                                                    disabled={this.checkDisableField("collateralCancelDate")}
                                                                    name="collateralCancelDate"
                                                                    fullWidth
                                                                    label="วันที่มีผลบังคับยกเลิกหนังสือค้ำประกัน"
                                                                    margin="normal"
                                                                    type="date"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    component={MTextField}
                                                                    onChange={(e) => {
                                                                        handleChange(e)

                                                                    }}
                                                                /></Grid>}
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField()}
                                                                fullWidth
                                                                type="text"
                                                                required
                                                                name="collateralModelCode"
                                                                label="แบบหนังสือค้ำประกัน"
                                                                options={this.state.collateralModelList}
                                                                component={MSelect}
                                                                onChange={e => {
                                                                    this.setState({ collateralModelCode: e.target.value })
                                                                    setFieldValue('collateralModelCode', e.target.value)
                                                                }}
                                                            >
                                                                {this.state.collateralModelList.map(option => (
                                                                    <MenuItem key={option.collateralModelCode} value={option.collateralModelCode}>
                                                                        {option.collateralModelName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 3 ข้อมูลลูกค้า" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item xs style={style.itemField}>
                                                                    <Field
                                                                        disabled={this.checkDisableField()}
                                                                        type="text"
                                                                        name="sapCompanyId"
                                                                        fullWidth
                                                                        label="รหัสลูกค้าในระบบ SAP"
                                                                        margin="normal"
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                            setFieldValue("sapCompanyId", e.target.value)


                                                                        }}
                                                                        component={MTextField}
                                                                    />
                                                                </Grid>

                                                                <Grid item style={style.itemField}>
                                                                    <ButtonSearchCircle
                                                                        icon="search"
                                                                        size="small"
                                                                        label="Search"
                                                                        disabled={this.state.isLoading || this.checkDisableField("btnSearch")}
                                                                        isLoading={this.state.isLoadingSearch}
                                                                        onClick={() => {

                                                                            if (values['sapCompanyId'].length >= 8 && values['sapCompanyId'].length <= 10) {
                                                                                this.searchCustomerByTaxID(values.sapCompanyId)
                                                                            } else {
                                                                                setFieldError('sapCompanyId', 'รหัสลูกค้าในระบบ SAP ต้องมากกว่า 8 และ น้อยกว่า 10')
                                                                            }

                                                                        }} />
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}></Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField() || values.sapCompanyId != ''}
                                                                type="text"
                                                                name="customerTax"
                                                                fullWidth
                                                                required
                                                                label="เลขประจำตัวผู้เสียภาษี"
                                                                margin="normal"
                                                                maxLength={13}
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField() || values.sapCompanyId != ''}
                                                                type="text"
                                                                required
                                                                name="companyName"
                                                                fullWidth
                                                                label="ชื่อบริษัทลูกค้า (ไทย)"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 4 ธนาคารผู้ออกหนังสือค้ำประกัน" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={12} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("bankCode")}
                                                                fullWidth
                                                                // required={this.props.account && this.props.account.role === roles.CUS}
                                                                required
                                                                type="text"
                                                                name="bankCode"
                                                                label="ธนาคาร"
                                                                options={this.state.bankList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.bankList.map(option => (
                                                                    <MenuItem key={option.bankCode} value={option.bankCode}>
                                                                        {option.bankName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />
                                    <Card>
                                        <TitleSection title="ส่วนที่ 5 ข้อมูลทั่วไป" />
                                        <div className={classes.containerGroup}>

                                            <Grid container spacing={2} justify="center">

                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField("sapShipToCode")}
                                                                type="text"
                                                                name="sapShipToCode"
                                                                fullWidth
                                                                label="รหัสที่ส่งสินค้า (SAP ship-to)"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>
                                                            <Field
                                                                disabled={this.checkDisableField('saleAreaManager')}
                                                                type="text"
                                                                name="saleAreaManager"
                                                                fullWidth
                                                                label="ผู้จัดการเขตการขาย"
                                                                margin="normal"
                                                                component={MTextField}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("sapBusinessTypeId")}
                                                                fullWidth
                                                                type="text"
                                                                name="sapBusinessTypeId"
                                                                label="ประเภทธุรกิจ"
                                                                options={this.state.sapBussinessTypeList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.sapBussinessTypeList.map(option => (
                                                                    <MenuItem key={option.sapBusinessTypeId} value={option.sapBusinessTypeId}>
                                                                        {option.sapBusinessTypeDescription}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("sapProductDeliveryId")}
                                                                fullWidth
                                                                type="text"
                                                                name="sapProductDeliveryId"
                                                                label="ช่องทางการขนส่งผลิตภัณฑ์"
                                                                options={this.state.sapProductDeliveryList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.sapProductDeliveryList.map(option => (
                                                                    <MenuItem key={option.sapProductDeliveryId} value={option.sapProductDeliveryId}>
                                                                        {option.sapProductDeliveryDescription}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid xs={12} md={6} item style={style.itemField}>

                                                            <Field
                                                                disabled={this.checkDisableField("sapBusinessAreaId")}
                                                                fullWidth
                                                                type="text"
                                                                required
                                                                name="sapBusinessAreaId"
                                                                label="Business Area"
                                                                options={this.state.sapAreaList}
                                                                component={MSelect}
                                                            >
                                                                {this.state.sapAreaList.map(option => (
                                                                    <MenuItem key={option.sapBusinessAreaId} value={option.sapBusinessAreaId}>
                                                                        {option.sapBusinessAreaDescription}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                    <br />

                                    {this.props.match.params.action === 'cancel' &&
                                        <Card>
                                            <TitleSection title="ส่วนที่ 6 ข้อมูลบัญชีลูกหนี้">
                                                <FormControl error={true} required component="fieldset" className={classes.formControl}>

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox disabled={this.checkDisableField("confirmArInformation")} checked={this.state.confirmArInformation} onChange={(event) => {
                                                                this.setState({ confirmArInformation: event.target.checked })
                                                            }} value="checkedA" />
                                                        }
                                                        label="ยืนยันข้อมูลลูกหนี้"
                                                    />
                                                    {this.state.requireConfirmArInformationError && <div>
                                                        <FormHelperText>Require</FormHelperText>
                                                        <br />
                                                    </div>}

                                                </FormControl>

                                            </TitleSection>

                                            <div className={classes.containerGroup}>

                                                <Grid container spacing={2} justify="center">

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={4}>
                                                            <Grid xs={12} md={12} item style={style.itemField}>

                                                                <Field
                                                                    disabled={this.checkDisableField("overdueInformation")}
                                                                    label="บันทึกลูกหนี้ค้างชำระ"
                                                                    required
                                                                    name="overdueInformation"
                                                                    multiline
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    rows={7}
                                                                    component={MTextField} />

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <div>
                                                    <Typography className={classes.labelColor} variant="body2">แนบไฟล์รายการข้อมูลลูกหนี้ค้างชำระ (ไม่เกิน 10 MB / File)</Typography>
                                                    <br />
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item style={style.itemField}>

                                                            <label htmlFor="button-file-debtor">
                                                                <ButtonSearchCircle
                                                                    icon="attach_file"
                                                                    size="small"
                                                                    label="Search"
                                                                    containerElement='label'
                                                                    disabled={this.state.isLoading || this.checkDisableField("btnAttachFileDebtor")}
                                                                    isLoading={this.state.isLoadingUploadFile}
                                                                    onClick={() => {

                                                                    }} >
                                                                    <input
                                                                        id="button-file-debtor"
                                                                        multiple
                                                                        onChange={(e) => {

                                                                            if (e.target.files.length <= 0) {
                                                                                return
                                                                            }

                                                                            for (let index = 0; index < e.target.files.length; index++) {
                                                                                const element = e.target.files[index];
                                                                                if (((element.size / 1024) / 1024) > 10) {
                                                                                    this.dialogApp.showDialogFail("ผิดพลาด", "ไฟล์ขนาดใหญ่เกิน 10 MB", () => { })

                                                                                    return
                                                                                }
                                                                            }

                                                                            this.uploadFiles(e.target.files, 'fileUploadsDebtor')

                                                                        }}
                                                                        type="file"
                                                                        style={{
                                                                            opacity: 0, overflow: 'hidden',
                                                                            position: 'absolute',
                                                                            width: 30,
                                                                            zIndex: 1,
                                                                        }} />
                                                                </ButtonSearchCircle>
                                                            </label>


                                                        </Grid>
                                                        <Grid item xs style={style.itemField}>
                                                            {this.state.fileUploadsDebtor.map((element, index) => {
                                                                return (
                                                                    <Chip
                                                                        className={classes.clipFile}
                                                                        key={index}
                                                                        label={element.fileName}
                                                                        onClick={() => {
                                                                            this.downloadFile(element)
                                                                        }}
                                                                        onDelete={() => {

                                                                            if (!this.checkDisableField("btnAttachFileDebtor")) {
                                                                                this.removeFile(element, 'fileUploadsDebtor')
                                                                            }

                                                                        }}
                                                                    />
                                                                )
                                                            })}


                                                        </Grid>

                                                    </Grid>
                                                    <br />
                                                    <Divider />
                                                </div>

                                            </div>
                                        </Card>}



                                    <br />

                                </Form>
                            )
                        }}
                    />

                    <Dialog
                        open={this.state.isPopupNote}
                        onClose={() => {

                        }}
                        fullWidth={true}
                        maxWidth={'sm'}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">หมายเหตุ</DialogTitle>
                        <DialogContent>
                            <MyTextField
                                name="remark"
                                multiline
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                rows={7}
                                value={this.state.remark}
                                onChange={e => {
                                    this.setState({ remark: e.target.value })
                                }}
                                // rows={7} 
                            />



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialogNote.bind(this)} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={() => {

                                if (this.state.remark.trim() === '') {

                                    this.dialogApp.showDialogFail(`กรุณาระบุสาเหตุ`, ``, () => {

                                    })

                                } else {
                                    this.setState({ isLoadingReject: true, isPopupNote: false })
                                    this.assign("Reject", this.props.creditDetail.requestNo)
                                }


                            }} color="secondary" >
                                OK
                             </Button>
                        </DialogActions>
                    </Dialog>

                    <DialogApp ref={ref => {
                        this.dialogApp = ref
                    }} />

                    {this.renderPreview()}
                    {this.renderActionLog()}

                </div>
            )
        } else {

            return (<div>
                {this.state.isLoading ? <Loading /> : <Empty label="ไม่สามารถแสดงข้อมูลได้" description="เนื่องจากมีข้อผิดพลาด กรุณาลองใหม่" />}
            </div>)
        }


    }


    handleCloseActionLog() {
        this.setState({ isPopupActionLog: false })
    }
    renderActionLog() {

        const { classes } = this.props;

        return (
            <Dialog
                fullWidth
                maxWidth="md"
                open={this.state.isPopupActionLog}
                onClose={this.handleCloseActionLog.bind(this)}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{`ประวัติเอกสารเลขที่ ${this.props.creditDetail.requestNo}`}</DialogTitle>
                <DialogContent dividers={true}>

                    <List>
                        {this.state.actionLog.map((element, index) => (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar} src={(element.image === '' ? require('../../resource/image/temp_acc.svg') : `data:image/jpeg;base64,${element.image}`)}>
                                        {(!element.image || element.image === '') && <PersonIcon fontSize={"large"} />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={element.name} secondary={
                                    <React.Fragment>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {element.activities}
                                                </Typography>

                                            </Grid>
                                            {(element.remark !== '' && element.remark) ?
                                                <Grid item>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="error"
                                                    >
                                                        {/* {`โดย ${element.employeeId} ${element.name}`} */}
                                                        {`สาเหตุการ reject: ${element.remark}`}
                                                    </Typography>

                                                </Grid>
                                                : ""}
                                            <Grid item>
                                                <Typography
                                                    component="span"
                                                    variant="caption"
                                                    color="textPrimary"
                                                >
                                                    {`${element.department}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>

                                } />
                                <ListItemSecondaryAction>
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        color="textPrimary"
                                    >
                                        {`${element.actionDate}`}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}

                    </List>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseActionLog.bind(this)} color="secondary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        )
    }

    checkDisableField(fieldName) {

        const { creditDetail } = this.props

        if (!this.disableRequest() && fieldName != "bankCode") {
            return false
        }

        if (this.props.match.params.action === 'issue' || window.location.pathname.includes('create')) {

            if ((!this.disableRequest() || creditDetail.stepCode === stepCode.CREATE) && fieldName != "bankCode" && (this.checkRoleSale() || this.checkRoleSM())) {
                return false
            }

            if (this.checkRoleCUS() && this.props.match.params.action === "issue" && creditDetail.stepCode === stepCode.UPDATE_REQUEST_BY_CUSTOMER && (fieldName == "bankCode" || fieldName == "collateralTypeCode")) {
                return false
            }

            if ((this.checkRoleCDF() && creditDetail.stepCode === stepCode.VERIFY_BY_CREDIT_OFFICER) &&
                (fieldName === 'contractExpireDate' ||
                    fieldName === 'gasStartDate' ||
                    fieldName === 'sapBusinessTypeId' ||
                    fieldName === 'sapShipToCode' ||
                    fieldName === 'sapProductDeliveryId' ||
                    fieldName === 'saleAreaManager' ||
                    fieldName === 'btnAttachFile' ||
                    fieldName === 'sapBusinessAreaId')) {

                return false
            }
        }

        if ((this.checkRoleSale() || this.checkRoleSM()) &&
            this.props.match.params.action === "amend" &&
            creditDetail.stepCode === stepCode.CREATE &&
            (fieldName == "contractDocName" ||
                fieldName === 'contractNumber' ||
                fieldName == "contractEnforceDate" ||
                fieldName == "contractExpireDate" ||
                fieldName == "gasStartDate" ||
                fieldName == "contractObjectiveName" ||
                fieldName == "guaranteeAmount" ||
                fieldName == "collateralEndDate" ||
                fieldName == "sapBusinessTypeId" ||
                fieldName == "sapShipToCode" ||
                fieldName == "sapProductDeliveryId" ||
                fieldName == "saleAreaManager" ||
                fieldName == "sapBusinessAreaId" ||
                fieldName == "btnAttachFile")) {
            return false
        }

        if (this.checkRoleCDF() &&
            this.props.match.params.action === "amend" &&
            creditDetail.stepCode === stepCode.VERIFY_BY_CREDIT_OFFICER &&
            (fieldName === "contractExpireDate" ||
                fieldName === 'gasStartDate' ||
                fieldName === "sapBusinessTypeId" ||
                fieldName === "sapShipToCode" ||
                fieldName === "sapProductDeliveryId" ||
                fieldName === "saleAreaManager" ||
                fieldName === "sapBusinessAreaId" ||
                fieldName === "btnAttachFile")) {
            return false
        }


        if ((this.checkRoleSale() || this.checkRoleSM()) &&
            this.props.match.params.action === "decrease" &&
            creditDetail.stepCode === stepCode.CREATE &&
            (fieldName == "guaranteeAmount" ||
                fieldName == "btnAttachFile")) {
            return false
        }

        if (this.checkRoleCDF() &&
            this.props.match.params.action === "decrease" &&
            creditDetail.stepCode === stepCode.VERIFY_BY_CREDIT_OFFICER &&
            (fieldName == "btnAttachFile")) {
            return false
        }


        if (this.checkRoleSM() &&
            this.props.match.params.action === "cancel" &&
            creditDetail.stepCode === stepCode.VERIFY_BY_SALES_MANAGER &&
            (fieldName == "contractExpireDate" ||
                fieldName == "gasStartDate" ||
                fieldName == "btnAttachFile" ||
                fieldName == "btnAttachFileDebtor")) {
            return false
        }

        if ((this.checkRoleSale() || this.checkRoleSM()) &&
            this.props.match.params.action === "cancel" &&
            creditDetail.stepCode === stepCode.CREATE &&
            (fieldName == "btnAttachFile" ||
                fieldName == "btnAttachFileDebtor")) {
            return false
        }

        if (this.checkRoleSM() &&
            this.props.match.params.action === "cancel" &&
            creditDetail.stepCode === stepCode.VERIFY_BY_SALES_MANAGER &&
            (fieldName == "btnAttachFile" ||
                fieldName == "btnAttachFileDebtor")) {
            return false
        }

        if (this.checkArAccount() &&
            this.props.match.params.action === "cancel" &&
            creditDetail.stepCode === stepCode.VERIFY_BY_AR_ACCOUNTANT &&
            (fieldName == "btnAttachFile" ||
                fieldName == "btnAttachFileDebtor" ||
                fieldName === "overdueInformation")) {
            return false
        }

        // if (this.checkArAccount() &&
        //     this.props.match.params.action === "cancel" &&
        //     creditDetail.stepCode === stepCode.VERIFY_BY_AR_ACCOUNTANT &&
        //     (fieldName == "btnAttachFile" ||
        //         fieldName == "btnAttachFileDebtor")) {
        //     return false
        // }


        if (this.checkRoleArManager() &&
            this.props.match.params.action === "cancel" &&
            creditDetail.stepCode === stepCode.VERIFY_BY_AR_MANAGER &&
            (fieldName == "btnAttachFile" ||
                fieldName == "btnAttachFileDebtor" ||
                fieldName == "overdueInformation")) {
            return false
        }


        if (this.checkRoleArManager() &&
            this.props.match.params.action === "cancel" &&
            creditDetail.stepCode === stepCode.VERIFY_BY_AR_MANAGER &&
            (fieldName == "confirmArInformation" ||
                fieldName == "overdueInformation")) {
            return false
        }





        return true

    }

    renderButton() {

        const { creditDetail, classes } = this.props

        var button = <div />

        if (this.checkArAccount() && creditDetail.stepCode === stepCode.VERIFY_BY_AR_ACCOUNTANT) {

            button = this.renderButtonCDF()
        } else if (this.checkRoleCDF() && creditDetail.stepCode === stepCode.VERIFY_BY_CREDIT_OFFICER) {

            button = this.renderButtonCDF()
        } else if (this.checkRoleCDM() && creditDetail.stepCode === stepCode.VERIFY_BY_CREDIT_MANAGE) {

            button = this.renderButtonCDM()
        } else if (this.checkRoleCDM() && creditDetail.stepCode === stepCode.APPROVE_BY_CREDIT_MANAGE) {

            button = this.renderButtonCDM()
        } else if (this.checkRoleCUS() && creditDetail.stepCode === stepCode.UPDATE_REQUEST_BY_CUSTOMER) {

            button = this.renderButtonCUS()
        } else if (this.checkRoleSM() && creditDetail.stepCode === stepCode.VERIFY_BY_SALES_MANAGER) {

            button = this.renderButtonCDM()
        }
        else if (this.checkRoleArManager() && creditDetail.stepCode === stepCode.VERIFY_BY_AR_MANAGER) {

            button = this.renderButtonCDM()
        } else if (this.checkRoleSVP() && creditDetail.stepCode === stepCode.APPROVE_BY_SALES_VP) {

            button = this.renderButtonCDM()
        } else if ((this.checkRoleSale() || this.checkRoleSM()) && !creditDetail.stepCode) {

            button = this.renderButtonRequest()
        } else if ((this.checkRoleSale() || this.checkRoleSM()) && creditDetail.stepCode === stepCode.CREATE) {

            button = this.renderButtonRequest()
        }


        return (
            <Grid container spacing={2}>
                <Grid item xs />
                {/* {!(this.props.step.completed === 'Y' && this.props.match.params.action === 'cancel') && <Grid item> */}
                {!(this.state.step.completed === 'Y' && this.state.match.params.action === 'cancel') && <Grid item>
                    {/* <ReactToPrint
                        onBeforeGetContent={() => {

                            var dataCredit = creditDetail
                            dataCredit = { ...this.form.state.values }

                            this.setState({ creditDetail: dataCredit }, () => {
                                console.log("onBeforePrint", this.state.creditDetail)
                            })

                        }}
                        ref={(ref) => {
                            this.print = ref
                        }}
                        trigger={() => <a style={{ textDecoration: 'none' }} href="#">
                            <Button variant="contained">
                                <Icon className={classes.leftIcon}>visibility</Icon>
                                Preview
                            </Button>
                        </a>}
                        content={() => this.printEbgPTTTH}
                    /> */}

                    <Button variant="contained" onClick={() => {
                        var dataCredit = Object.assign(creditDetail, this.form.state.values)

                        this.setState({ creditDetail: dataCredit }, () => {
                            this.setState({ openPreview: true })
                        })
                    }}>
                        <Icon className={classes.leftIcon}>visibility</Icon>
                        Preview
                            </Button>
                </Grid>}

                <Grid item>
                    {button}
                </Grid>
            </Grid>
        )

        //&& this.props.step.currentEbg.flowCode == flowCode.eBG01


    }

    renderButtonRequest(values) {

        return (
            <Grid container spacing={2}>
                <Grid item>
                    <ButtonSave variant="contained" disabled={this.state.isLoading} isLoading={this.state.isLoadingSave} onClick={async () => {


                        this.form.setFieldValue('action', 'save')
                        await Promise.resolve()
                        this.form.submitForm()
                    }} />
                </Grid>
                <Grid item>
                    <ButtonSubmit variant="contained" disabled={this.state.isLoading} isLoading={this.state.isLoadingSubmit} onClick={async () => {

                        this.checkCustomerStatus(this.form.getFormikContext().values.customerTax).then(async response => {

                            this.setState({ isLoading: false, })
                            if (response.data.data.statusTaxId === 'N') {
                                this.dialogApp.showDialogFail('ไม่สามารถทำรายการได้', 'เนื่องจากลูกค้ายังไม่ได้ลงทะเบียน')
                            } else {

                                this.form.getFormikContext().validateForm(this.form.getFormikContext().values).then(async response => {
                                    if (Object.entries(response).length === 0 && response.constructor === Object) {
                                        this.form.setFieldValue('action', 'submit')
                                        await Promise.resolve()
                                        this.form.submitForm()
                                    } else {
                                        this.dialogApp.showDialogFail(`กรอกข้อมูลไม่ถูกต้อง หรือ ไม่ครบถ้วน กรุณาตรวจสอบ`, ``, () => {

                                        })
                                    }
                                })

                            }
                        }).catch(error => {
                            this.setState({ isLoading: false, })
                        })






                    }} />
                </Grid>
            </Grid>
        )
    }



    renderButtonCDF() {
        return (
            <Grid container spacing={2}>
                <Grid item>
                    <ButtonSave variant="contained" disabled={this.state.isLoading} isLoading={this.state.isLoadingSave} onClick={async () => {
                        this.form.setFieldValue('action', 'save')
                        await Promise.resolve()
                        this.form.submitForm()
                    }} />
                </Grid>
                <Grid item>
                    <ButtonReject
                        isLoading={this.state.isLoadingReject}
                        label="Reject"
                        icon="clear"
                        disabled={this.state.isLoading}
                        color="secondary"
                        onClick={() => {
                            this.setState({ isPopupNote: true })
                        }} />
                </Grid>
                <Grid item>
                    <ButtonApprove
                        isLoading={this.state.isLoadingSubmit}
                        label="Verify"
                        icon="done"
                        disabled={this.state.isLoading}
                        color="secondary"
                        onClick={async () => {
                            // Check overdueInformation Step ARA Approve
                            
                            this.setState({ isLoading: false, })
                            if (this.props.match.params.action === "cancel"
                                && this.props.creditDetail.stepCode === stepCode.VERIFY_BY_AR_ACCOUNTANT
                                && this.form.getFormikContext().values.overdueInformation === '') {
                                this.dialogApp.showDialogFail('ไม่สามารถทำรายการได้', 'กรุณากรอกข้อมูล บันทึกลูกหนี้ค้างชำระ')
                            } else {
                                this.form.getFormikContext().validateForm(this.form.getFormikContext().values).then(async response => {
                                    if (Object.entries(response).length === 0 && response.constructor === Object) {
                                        this.setState({ isLoadingSubmit: true })
                                        this.form.setFieldValue('action', 'Approve')
                                        await Promise.resolve()
                                        this.form.submitForm()
                                    } else {
                                        this.dialogApp.showDialogFail(`กรอกข้อมูลไม่ถูกต้อง หรือ ไม่ครบถ้วน กรุณาตรวจสอบ`, ``, () => { })
                                    }
                                })
                            }
                        }} />
                </Grid>
            </Grid>
        )
    }

    renderButtonCDFAccept(values) {
        return (
            <Grid container spacing={2}>
                <Grid item>
                    <ButtonApprove
                        isLoading={this.state.isLoadingSubmit}
                        label="Accept"
                        icon="done"
                        disabled={this.state.isLoading}
                        color="secondary"
                        onClick={async () => {
                            this.setState({ isLoadingSubmit: true })
                            this.setButtonNavigation()
                            this.form.setFieldValue('action', 'Approve')
                            await Promise.resolve()
                            this.form.submitForm()
                        }} />
                </Grid>
            </Grid>
        )
    }

    renderButtonCDM(values) {
        return (
            <Grid container spacing={2}>

                <Grid item>
                    <ButtonReject
                        isLoading={this.state.isLoadingReject}
                        label="Reject"
                        icon="clear"
                        disabled={this.state.isLoading}
                        color="secondary"
                        onClick={() => {
                            this.setState({ isPopupNote: true })
                        }} />

                </Grid>
                <Grid item>

                    <ButtonApprove
                        isLoading={this.state.isLoadingSubmit}
                        label="Approve"
                        icon="done"
                        disabled={this.state.isLoading}
                        color="secondary"
                        onClick={async () => {
                            // Check overdueInformation Step ARM Approve                           
                            this.setState({ isLoading: false, })
                            if (this.props.match.params.action === "cancel"
                                && this.props.creditDetail.stepCode === stepCode.VERIFY_BY_AR_MANAGER
                                && this.state.confirmArInformation === false) {
                                this.dialogApp.showDialogFail('ไม่สามารถทำรายการได้', 'กรุณา ยืนยันข้อมูลลูกหนี้')
                            } else {
                                this.form.getFormikContext().validateForm(this.form.getFormikContext().values).then(async response => {
                                    if (Object.entries(response).length === 0 && response.constructor === Object) {
                                        this.setState({ isLoadingSubmit: true })
                                        this.form.setFieldValue('action', 'Approve')
                                        await Promise.resolve()
                                        this.form.submitForm()
                                    } else {
                                        this.dialogApp.showDialogFail(`กรอกข้อมูลไม่ถูกต้อง หรือ ไม่ครบถ้วน กรุณาตรวจสอบ`, ``, () => { })
                                    }
                                })
                            }
                        }} />

                </Grid>
            </Grid>
        )
    }

    renderButtonCUS() {
        return (
            <Grid container spacing={2}>

                <Grid item>


                    <ButtonReject
                        isLoading={this.state.isLoadingReject}
                        label="Reject"
                        icon="clear"
                        disabled={this.state.isLoading}
                        color="secondary"
                        onClick={() => {
                            this.setState({ isPopupNote: true })
                        }} />

                </Grid>
                <Grid item>

                    <ButtonApprove
                        isLoading={this.state.isLoadingSubmit}
                        label="Submit"
                        icon="done"
                        disabled={this.state.isLoading}
                        color="secondary"
                        onClick={async () => {

                            this.form.getFormikContext().validateForm(this.form.getFormikContext().values).then(async response => {
                                if (Object.entries(response).length === 0 && response.constructor === Object) {

                                    this.setState({ isLoadingSubmit: true })

                                    this.form.setFieldValue('action', 'submit')
                                    await Promise.resolve()
                                    this.form.submitForm()

                                } else {
                                    this.dialogApp.showDialogFail(`กรอกข้อมูลไม่ถูกต้อง หรือ ไม่ครบถ้วน กรุณาตรวจสอบ`, ``, () => {

                                    })
                                }
                            })





                        }} />


                </Grid>

            </Grid>
        )
    }

    renderPrintEbgPTTTH() {

        const { classes } = this.props;

        const data = this.state.creditDetail


        return (
            <div>
                {/* <PDFViewer> */}
                <Document>
                    <Page size="A4">
                        <div className={classes.containerPdf} ref={ref => {
                            this.printEbgPTTTH = ref
                        }}>


                            <Typography align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>
                                หนังสือค้ำประกันอิเล็กทรอนิกส์ของธนาคาร
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>
                                (หลักประกันสัญญา)
                    </Typography>
                            <Typography align="right" style={{ fontSize: 14 }}>
                                {`${data.bankName || ''} ${data.issuerTaxID || ''}`}
                            </Typography>
                            <br />
                            <Typography align="center" style={{ fontSize: 14 }}>
                                {`${data.requestDate || ''}`}
                            </Typography>
                            <br />
                            <Typography align="left" style={{ fontSize: 14 }}>
                                {`เลขที่ ${data.lgNumber || ''}`}
                            </Typography>
                            <br />
                            <Typography style={{ fontSize: 14 }} align="justify">
                                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้าพเจ้าธนาคาร ${data.bankName || ''} ${data.issuerTaxID || ''} ขอทำหนังสือค้ำประกันให้ไว้ต่อ ${data.beneficiaryName || ''} ${data.beneficiaryTax || ''} (ซึ่งต่อไปในหนังสือค้ำประกันฉบับนี้เรียกว่า “ผู้รับหนังสือค้ำประกัน”) ดังมีข้อความต่อไปนี้`}
                            </Typography>
                            <Typography style={{ fontSize: 14 }} align="justify">
                                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้อ1. ตามที่ ${data.companyName || ''} ${data.customerTax || ''} (ซึ่งต่อไปในหนังสือค้ำประกันฉบับนี้จะเรียกว่า  “คู่สัญญา”) ได้ตกลงทำ ${data.contractDocName || ''} เลขที่ ${data.contractNumber || ''} ลงวันที่ ${data.contractEnforceDate || ''} โดยมีวัตถุประสงค์ ${data.contractObjectiveName || ''} ซึ่งจะต้องวางหลักประกันการปฏิบัติตามเงื่อนไขในสัญญาต่อผู้รับหนังสือค้ำประกัน เป็นเงินไม่เกิน ${formatNumber(data.guaranteeAmount)} บาทนั้น`}
                            </Typography>
                            <Typography style={{ fontSize: 14 }} align="justify">
                                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้าพเจ้ายอมผูกพันตนเป็นผู้ค้ำประกัน คู่สัญญา ต่อ ผู้รับหนังสือค้ำประกัน เพื่อรับผิดอย่างลูกหนี้ร่วมในการชำระเงินตามสิทธิเรียกร้องของผู้รับหนังสือค้ำประกัน เป็นเงินไม่เกิน ${formatNumber(data.guaranteeAmount)} บาท กล่าวคือหากคู่สัญญาไม่ปฏิบัติตามเงื่อนไขในสัญญาที่ทำไว้กับผู้รับหนังสือค้ำประกัน หรือปฏิบัติผิดเงื่อนไขข้อหนึ่งข้อใดของสัญญาดังกล่าว ซึ่งผู้รับหนังสือค้ำประกันมีสิทธิริบหลักประกันและหรือเรียกค่าปรับและหรือค่าเสียหายใดๆจากคู่สัญญาได้แล้ว  ข้าพเจ้าตกลงชำระเงินแทนให้ผู้รับหนังสือค้ำประกัน โดยมิต้องเรียกร้องให้คู่สัญญาชำระก่อน`}
                            </Typography>
                            <Typography style={{ fontSize: 14 }} align="justify">
                                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้อ 2. หนังสือค้ำประกันนี้มีผลใช้บังคับนับตั้งแต่วันที่ ${data.collateralEnforceDate || ''} จนถึงวันที่ ${data.collateralEndDate || ''}  และไม่ว่ากรณีใดๆ ข้าพเจ้าจะไม่เพิกถอนการค้ำประกันภายในระยะเวลาที่กำหนดไว้`}
                            </Typography>
                            <Typography style={{ fontSize: 14 }} align="justify">
                                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้อ 3.  ข้าพเจ้ายอมรับรู้และยินยอมด้วยในกรณีที่ ผู้รับหนังสือค้ำประกัน ได้ยินยอมให้ผัดหรือผ่อนเวลาการปฏิบัติตามเงื่อนไขในสัญญา ให้แก่คู่สัญญาโดยให้ขยายระยะเวลาการค้ำประกันนี้ออกไปตลอดระยะเวลาที่ได้ขยายไปดังกล่าวข้างต้นด้วย`}
                            </Typography>
                            <br />
                            <Typography style={{ fontSize: 14 }} align="justify">
                                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`เพื่อเป็นหลักฐาน ข้าพเจ้าธนาคาร ${data.bankName || ''} ${data.issuerTaxID || ''} โดยผู้มีลงนามข้างท้ายนี้  เป็นผู้มีอำนาจลงนามทำนิติกรรม ซึ่งมีผลผูกพันธนาคารได้ลงลายมือชื่อและได้ประทับตราของธนาคาร (ถ้ามี) ให้ไว้ต่อหน้าพยานเป็นสำคัญ`}
                            </Typography>
                            <br /><br />
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (ลงชื่อ) ………………………………….. ผู้ค้ำประกัน
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (………………………………….)
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                ตำแหน่ง……………………………….
                    </Typography>
                            <br />
                            <Grid container>
                                <Grid item>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (ลงชื่อ) ………………………………….. พยาน
                    </Typography>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (………………………………….)
                    </Typography>
                                </Grid>
                                <Grid item xs />
                                <Grid item>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (ลงชื่อ) ………………………………….. พยาน
                    </Typography>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (………………………………….)
                    </Typography>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography style={{ fontSize: 14, }}>
                                *** รับผิดอย่างลูกหนี้ร่วม & ผ่อนเวลาล่วงหน้าได้
                    </Typography>


                        </div>
                    </Page>
                </Document>

                {/* </PDFViewer> */}

            </div >
        )
    }

    renderPrintEbgPTTEN() {

        const { classes } = this.props;
        const data = this.state.creditDetail

        return (
            <div>
                <Document>
                    <Page size="A4">
                        <div className={classes.containerPdf} ref={ref => {
                            this.printEbgPTTTH = ref
                        }}>

                            <Typography align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>
                                PERFORMANCE  SECURITY  / MERCHANDISE SECURITY BOND
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>
                                (Bank as Guarantor)
                    </Typography>
                            <Typography align="right" style={{ fontSize: 14 }}>
                                {`${data.issuerNameEN || ''} ${data.issuerTaxID || ''} Bank`}
                            </Typography>
                            <Grid container>
                                <Grid item>
                                    <Typography align="right" style={{ fontSize: 14 }}>
                                        {`Document No. ${data.lgNumber || ''}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                </Grid>
                                <Grid item>
                                    <Typography align="right" style={{ fontSize: 14 }}>
                                        {`Dated ${data.requestDate || ''}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Typography style={{ fontSize: 14 }} align="justify">
                                {`In  accordance  with  the  provision  of  the  Contract  for ${data.contractDocName || ''} ${data.contractNumber || ''} ${data.contractEnforceDate || ''} ${data.contractObjectiveName || ''} (hereinafter  referred  to  as  the  Contract)  the  contents  of  which  have  been  noted  by  us  that  Messrs., ${data.requesterNameEN || ''} ${data.customerTax || ''} (hereinafter  referred  to  as  the Contractor)  has  to  deposit  with ${data.beneNameEN || ''} ${data.beneficiaryTaxID || ''}  (hereinafter  referred  to  as  Beneficiary)  a  Performance  Security  for  the  proper  and  faithful  performance  of  the Contractor under the Contract  in  the  amount  not  exceeding THB ${formatNumber(data.guaranteeAmount)},  we  the  ${data.issuerNameEN || ''} ${data.issuerTaxID || ''} Bank,  as  instructed  by  Contractor,  irrevocably  and  unconditionally  agree  to  guarantee,  as  joint  obligor,  due  and  punctual  performance  of  the  Contractor under  the  Contract  and  hereby  undertake  to  promptly  make  the  payment  to  Beneficiary,  without  whatsoever  right  of  objection  on  our  part  and  without  its  first  claim  to  the  Contractor,  in  the  amount  not exceeding  THB ${formatNumber(data.guaranteeAmount)},  in  the  event  of  the  obligations  of  the Contractor expressed  in  the  above-mentioned  Contract  have not  been  fulfilled  or  become  the default  of  Contract  by  the Contractor,  which  giving Beneficiary  the  right  to  claim  for  penalties,  costs,  losses,  damages  or  any  expenses  for  which  the Contractor becomes  liable  to  Beneficiary.`}
                            </Typography>
                            <br />
                            <Typography style={{ fontSize: 14 }} align="justify">
                                {`This  Performance  Security  shall  be  valid  and  in  full  force  and  effect  from ${data.collateralEnforceDate || ''} to ${data.collateralEndDate || ''} and,  in  any  circumstances,  we  shall  not  revoke  this  Performance  Security  during  such  period.`}
                            </Typography>
                            <br />
                            <Typography style={{ fontSize: 14 }} align="justify">
                                {`We further  agree  that  no  extension  of  time,  which   may  be  made  between  Beneficiary  and  the Contractor,  shall  in  any  way  release  us  from  any  liability  under  the   Performance  Security,  and  the  guaranteed  period  shall  be  extended  throughout  such  extension  of  time.`}
                            </Typography>
                            <br />
                            <Typography style={{ fontSize: 14 }} align="justify">
                                {`In  witness  whereof,  we  the ${data.issuerNameEN || ''} ${data.issuerTaxID || ''} by  the  authorized  representative  has  signed  and  affixed  the seal  (if any)  in  the  presence  of  witnesses.`}
                            </Typography>
                            <br /><br />
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (Signed) ………………………………….. Bank
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (………………………………….)
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (Position)……………………………….
                    </Typography>
                            <br />
                            <Grid container>
                                <Grid item>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (Signed) ………………………………….. Witness
                    </Typography>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (………………………………….)
                    </Typography>
                                </Grid>
                                <Grid item xs />
                                <Grid item>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (Signed) ………………………………….. Witness
                    </Typography>
                                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                        (………………………………….)
                    </Typography>
                                </Grid>
                            </Grid>
                            <br />

                        </div>

                    </Page>
                </Document>

            </div >
        )
    }

    renderPreview() {

        const { classes } = this.props;

        return (
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={this.state.openPreview}
                onClose={() => {
                    this.setState({ openPreview: false })
                }}
                aria-labelledby="scroll-dialog-title"
            >
                <Grid container>
                    <Grid item>
                        <DialogTitle id="scroll-dialog-title">Preview</DialogTitle>

                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <div style={{ marginTop: 16, marginRight: 16 }}>
                            <ReactToPrint
                                ref={(ref) => {
                                    this.print = ref
                                }}
                                trigger={() => <a style={{ textDecoration: 'none' }} href="#">
                                    <Button variant="contained">
                                        <Icon className={classes.leftIcon}>print</Icon>
                                        Print
                            </Button>
                                </a>}
                                content={() => this.printEbgPTTTH}
                            />
                        </div>
                    </Grid>
                </Grid>

                <DialogContent>
                    {this.state.collateralModelCode === 'PTTPLC_MER_001_TH' && this.renderPrintEbgPTTTH()}
                    {this.state.collateralModelCode === 'PTTPLC_MER_001_EN' && this.renderPrintEbgPTTEN()}
                </DialogContent>
            </Dialog>
        )
    }

    handleCloseDialogNote() {
        this.setState({ isPopupNote: false })
    }

    subtractDate(startDate, endDate) {
        let diff = Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24);


        return diff
    }

    disableRequest() {

        const { creditDetail } = this.props

        return (creditDetail.requestNo && creditDetail.requestNo != "")
    }

    uploadFiles(files, stateUploadName) {

        this.setState({ isLoading: true, isLoadingUploadFile: true })

        const apiUploadFiles = []
        for (let index = 0; index < files.length; index++) {
            const element = files[index];
            apiUploadFiles.push(this.uploadFile(element))

        }
        axios.all(apiUploadFiles).then(response => {


            const fileUploads = this.state[stateUploadName]
            response.forEach(element => {
                fileUploads.push(element.data.data)
            })
            this.setState({ isLoading: false, isLoadingUploadFile: false, [stateUploadName]: fileUploads })
        }).catch(error => {

            this.setState({ isLoading: false, isLoadingUploadFile: false })
        })

    }

    uploadFile(attachFile) {

        const parameter = new FormData()
        parameter.append("file", attachFile)
        return this.props.webService.uploadFile(parameter)

    }

    removeFile(file, stateUploadName) {

        const parameter = { "filePath": file.filePath }
        this.setState({ isLoading: true, isLoadingUploadFile: true })
        const fileUploads = this.state[stateUploadName]

        this.props.webService.removeFile(parameter).then(response => {

            fileUploads.splice(fileUploads.indexOf(file), 1)
            this.setState({ isLoading: false, isLoadingUploadFile: false, [stateUploadName]: fileUploads, })
        }).catch(error => {
            this.setState({ isLoading: false, isLoadingUploadFile: false })
        })

    }

    checkCustomerStatus(customerTax) {

        const parameter = { "customerTax": customerTax }
        this.setState({ isLoading: true, })

        return this.props.webService.checkTaxId(parameter)

    }


    assignmentsAmend(status, requestNo) {

        const parameter = {
            "requestNo": requestNo,
            "actionAssign": status,
            "remark": this.state.remark
        }

        this.setState({ isLoading: true })
        this.setButtonNavigation()
        this.props.webService.assignmentsAmend(parameter).then(response => {

            this.setState({ isLoading: false, isPopupSuccess: true })
            this.defaultLoadingButton()
            setTimeout(() => {
                this.dialogApp.showDialogSuccess("บันทึกเรียบร้อย", `บันทึกข้อมูลเรียบร้อย ใบคำขอเลขที่ ${response.data.data.requestNo} อยู่ระหว่างดำเนินการ`, () => {
                    this.props.history.push(`/`)
                })
            }, 300);

        }).catch(error => {

            this.setState({ isLoading: false })
            this.defaultLoadingButton()
        })
    }

    assignmentsDecrease(status, requestNo) {

        const parameter = {
            "requestNo": requestNo,
            "actionAssign": status,
            "remark": this.state.remark
        }

        this.setState({ isLoading: true })

        this.props.webService.assignmentsDecrease(parameter).then(response => {

            this.setState({ isLoading: false, isPopupSuccess: true })
            this.defaultLoadingButton()
            setTimeout(() => {
                this.dialogApp.showDialogSuccess("บันทึกเรียบร้อย", `บันทึกข้อมูลเรียบร้อย ใบคำขอเลขที่ ${response.data.data.requestNo} อยู่ระหว่างดำเนินการ`, () => {
                    this.props.history.push(`/`)
                })
            }, 300);

        }).catch(error => {

            this.setState({ isLoading: false })
            this.defaultLoadingButton()
        })
    }

    assignmentsCancel(status, requestNo) {

        const parameter = {
            "requestNo": requestNo,
            "actionAssign": status,
            "remark": this.state.remark
        }

        this.setState({ isLoading: true })

        this.props.webService.assignmentsCancel(parameter).then(response => {

            this.setState({ isLoading: false, isPopupSuccess: true })
            this.defaultLoadingButton()
            setTimeout(() => {
                this.dialogApp.showDialogSuccess("บันทึกเรียบร้อย", `บันทึกข้อมูลเรียบร้อย ใบคำขอเลขที่ ${response.data.data.requestNo} อยู่ระหว่างดำเนินการ`, () => {
                    this.props.history.push(`/`)
                })
            }, 300);

        }).catch(error => {

            this.setState({ isLoading: false })
            this.defaultLoadingButton()
        })
    }


    //ถ้าทำ Flow Amend,Cancel,Decrease ให้ใส่ status ตามชื่อ Flow
    addGuaranteePeriod(values, ) {

        let status = ''
        switch (this.props.match.params.action) {
            case 'amend':
                status = 'Amend'
                break;
            case 'cancel':
                status = 'Cancel'
                break;
            case 'decrease':
                status = 'Decrease'
                break;
            case 'issue':
                status = 'Issue'
                break;

            default:
                break;
        }

        return new Promise((resolve, reject) => {


            const guaranteeDetail = this.state.guaranteeDetail
            guaranteeDetail.periodSubmitFlag = this.state.periodSubmitFlag
            guaranteeDetail.torFlag = this.state.torFlag
            values.startPeriodSubmit = Moment(values.startPeriodSubmit).format('DD/MM/YYYY')
            values.endPeriodSubmit = Moment(values.endPeriodSubmit).format('DD/MM/YYYY')

            values.collateralAmendDate = (values.collateralAmendDate != null && values.collateralAmendDate != "") ? Moment(values.collateralAmendDate).format('DD/MM/YYYY') : ""
            values.collateralCancelDate = (values.collateralCancelDate != null && values.collateralCancelDate != "") ? Moment(values.collateralCancelDate).format('DD/MM/YYYY') : ""

            const newValues = Object.assign(guaranteeDetail, values)
            const parameterValues = Object.assign(newValues, {
                periodMinTotal: this.state.periodMinTotal,
                status: status,
            })

            const parameter = JSON.stringify(parameterValues)



            this.setState({ isLoading: true })
            this.props.webService.addGuaranteePeriod(parameter).then(response => {

                this.setState({
                    isLoading: false, isPopupSuccess: true

                })

                this.defaultLoadingButton()
                resolve(response)

            }).catch(error => {

                this.setState({ isLoading: false })
                this.defaultLoadingButton()
                reject()
            })

        })


    }

}



const mapStateToProps = ({ account, step, dispatch }) => {

    return (
        {
            account: account.user,
            // step: step.step
            // menuNavigation: dispatchPage(navigationSlice.actions.menuNavigationSlice(this.renderButton.bind(this)))
            // menuNavigation: () => dispatch(this.renderButton.bind(this))
            menuNavigation: () => this.renderButton.bind(this)
        }
    )
}

export default connect(mapStateToProps, { actionGetStatusSave, actionNavigationenu, menuNavigationSlice })(withRouter(WithWebService(withStyles(styles)(RequestBG))))