import { MButton } from "./MButton"
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "../../css/login.css"
import { useMsal } from "@azure/msal-react";
import { EncryptStorage } from "encrypt-storage";
import React, { useEffect } from "react";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import LoginSlice from '../../redux/feature/loginSlice'
import userSlice from "../../redux/feature/userSlice"
import accountSlice from "../../redux/feature/accountSlice"
import jwt from "jsonwebtoken"
import { setCookie } from '../../utils/Utilities'
import { keys } from '../../utils/Constants'
import axios from 'axios'
import * as AuthApi from '../../apis/AuthApi'
import GlobalUtil from "../../util/GlobalUtil";

//env
import { env } from "../../env"

const useStyles = makeStyles(theme => ({

    root: {
        height: '100vh',
    },
    buttonLogin: {
        marginTop: 24
    },
    textHello: {
        color: 'white',
        fontSize: 28,
        marginLeft: 16,
        marginRight: 16,
        fontWeight: 'bold',

    },
    fabProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        zIndex: 1,
    },
    textWelcome: {
        color: 'white',
        fontSize: 24,
        marginLeft: 16,
        marginRight: 16,

    },
    textWelcomeDescription: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
        marginLeft: 16,
        marginRight: 16,
        marginTop: 16,

    },
    textOr: {
        color: 'white',
        fontSize: 16,
        marginLeft: 16,
        marginRight: 16,
        marginTop: 36,
    },
    cardLeft: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        height: "50vh",
    },
    logo: {
        width: 90,
        height: "auto",
        objectFit: 'contain'
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function B2CLoginComp(props) {
    const classes = useStyles;
    const { accounts, instance } = useMsal();
    const { tenant, btnName, apps, tokenRequest } = props
    const dispatchPage = useDispatch();
    let inStoreReduserLogin = useSelector((state) => {
        return state.login;
    });
    const encryptStorage = new EncryptStorage('secret-key', {
        prefix: "@CLAIM"
    });
    const login = encryptStorage.getItem('login')
    const handleLoginB2C = async () => {
        try {
            console.log('handleLoginB2C')
            tenant.selectApps = apps
            encryptStorage.setItem('loginType', "B2C");
            encryptStorage.setItem('tenant', JSON.stringify(tenant));
            instance.loginRedirect(apps.loginRequest)
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (accounts.length > 0 && !login) {
            autnLogin()
        }
    }, [accounts[0]])

    const autnLogin = async () => {
        try {
            // console.log("############ B2CLoginComp.js autnLogin (accounts[0]) : ", JSON.stringify(accounts[0]) );
            // window.localStorage.setItem("accountsB2C", JSON.stringify(accounts[0]));

            if(encryptStorage.getItem('loginType') === "B2C"){
                // dispatchPage(userSlice.actions.userDetailSlice(true))
                let tenant = encryptStorage.getItem('tenant')
                let userApp = {}

                // console.log("###### B2CLoginComp.js (tokenRequest) : ", tokenRequest);
                // console.log("###### B2CLoginComp.js (tenant) : ", tenant);

                await instance
                  .acquireTokenSilent({
                    ...tokenRequest,
                    account: accounts[0],
                  })
                  .then(
                    async (response) => {
                        console.log("###### B2CLoginComp.js (response) : ",response);
                        const data = {
                            access_token: response.accessToken,
                            client_id: tenant.selectApps.client_id,
                            tenant_id: tenant.tenant_id,
                            type: "CMS"
                        }
                        userApp = await AuthApi.doserviceAuthB2C(data, response.accessToken)
                        console.log('userApp B2C', userApp)
                        userApp = JSON.parse(GlobalUtil.decodeUFT8(userApp.resp_parameters[0].value));
                        console.log(" userApp in B2CLoginComp.js =>",userApp);

                        let parameter = JSON.stringify({
                            "username": userApp.email
                        });
                        console.log("###### B2CLoginComp.js (parameter) : ", parameter );
            
                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `${env.REACT_APP_API_ENDPOINT}/credit/getUserProfileV3`,
                            headers: { 
                              'Content-Type': 'application/json'
                            },
                            data : parameter
                        };
            
                        await axios.request(config)
                            .then((response) => {
                                console.log("###### B2CLoginComp.js (response.data) : ", response.data);
                                encryptStorage.setItem(keys.TOKEN, response.data.token)
                                setCookie(keys.TOKEN, response.data.token, 1)
                                setCookie(keys.USERDETAIL, JSON.stringify(response.data.data), 1)

                                // if(response.data){
                                //     let values = JSON.parse(JSON.stringify(response.data.data))

                                //     const parameterVerify = {
                                //         "username": values.userName,
                                //         "password": "x"
                                //     }
    
                                //     let configVerify = {
                                //         method: 'post',
                                //         maxBodyLength: Infinity,
                                //         url: 'https://ebg-test.pttplc.com/be-ptt-credit-service-dev/credit/login/verify',
                                //         headers: { 
                                //           'Content-Type': 'application/json'
                                //         },
                                //         data : parameterVerify
                                //     };

                                //     axios.request(configVerify)
                                //         .then((response) => {
                                //             console.log("###### B2CLoginComp.js Verify (response) : ", response);
                                //         })
                                //         .catch((error) => {
                                //             console.log("###### B2CLoginComp.js Verify (error) : ", error);
                                //         });
                                // }
                                // dispatchPage(accountSlice.actions.userAccount(response.data.data[0]))
                                // dispatchPage(userSlice.actions.userDetailSlice(JSON.stringify(response.data.data)))

                            })
                            .catch((error) => {
                                console.log("###### B2CLoginComp.js (error) : ", error);
                            });
                        
                    },
                    (error) => {
                      console.log("###### B2CLoginComp.js (error) : ", error);
                    }
                  );
                  dispatchPage(userSlice.actions.userDetailSlice(false))
            }

            // await axios
            //     .post("credit/getUserProfileV3", parameter)
            //     .then((response) => {
            //         console.log("############ B2CLoginComp.js autnLogin (response) : ", response.data);
            //         const token = jwt.sign({
            //             userId: response.data.userId,
            //             fullName: response.data.fullName,
            //             username: response.data.userName,
            //             role: response.data.roleCode,
            //             email: response.data.email,
            //             minPGRP: "",
            //             maxPGRP: ""
            //         },
            //             process.env.SECRETEBG, {
            //             expiresIn: 86400 // expires in 24 hours
            //         });
            //         encryptStorage.setItem(keys.TOKEN, token)
            //         setCookie(keys.TOKEN, token, 1)
            //     })
            //     .catch((error) => {
            //         console.log("############ B2CLoginComp.js autnLogin (error) : ", error);
            //     });

            if (accounts[0].localAccountId) {

                let login = inStoreReduserLogin
                if (!login.accountLogin) {
                    let payload = {
                        ...login,
                        accountLogin: accounts[0].environment
                    }
                    dispatchPage(LoginSlice.actions.updateLogin(payload));
                    encryptStorage.setItem('login', true);
                    encryptStorage.setItem('userName', accounts[0].environment);
                }


            }
        } catch (error) {
            console.log(error)
        }
    }
    return <>
        <Grid container spacing={3}>
            <Grid item xs={12} sm>
                <MButton id="login_b2c_btn" variant="contained" data-testid={`btn-login-${apps.client_id}`} fullWidth color="secondary" className={classes.buttonLogin} onClick={() => handleLoginB2C()}>LOGIN CUSTOMER</MButton>
            </Grid>
        </Grid>
    </>
}