import globalConfig from "../config/globalConfig"
import GlobalUtil from "../util/GlobalUtil"
import GlobalApi from "./GlobalApi"
import axios from "axios"
import { btoa } from "js-base64"
import { EncryptStorage } from "encrypt-storage"
import { genrateTranscriptID } from "../util/GlobalUtil"
import * as GlobalConfigApi from "../apis/GlobalConfigApi"
import axiosApiInstance from "./AuthHeader"

//env
import { env } from "../env"

const encryptStorage = new EncryptStorage("secret-key", {
  prefix: globalConfig.GLOBALCONFIGKEY.PREFIX
})
const userName = encryptStorage.getItem("userName")

//none using
export const authenLogin = async data => {
  return await GlobalApi.CallFunctionCommon("azt/doservice", {
    function_id: "F100011",
    app_user: env.REACT_APP_USER,
    // app_password: env.reactAppPassword,
    app_password: "5kTfm$kj0",
    req_transaction_id: GlobalUtil.genrateTranscriptID(),
    state_name: "",
    req_parameters: [
      {
        k: "data",
        v: btoa(data)
      },
      {
        k: "by",
        v: btoa("admin")
      }
    ]
  })
}

export const doserviceAuthAD = async (data, accessToken = "") => {
  let dataValue = {
    access_token: accessToken,
    tenant_id: data.tenant_id,
    client_id: data.client_id,
    b2c: "Y",
    validated_claims: "",
    type: "CMS"
  }
  const resp = await GlobalConfigApi.doserviceFindGlobalConfigByKey({
    key: "FUNCTION_F100011_AAD"
  })

  let func = resp.Data.Value
  let req_para = func.req_parameters

  let indexdata = req_para.findIndex(({ k }) => k === "data")
  let model = req_para[indexdata].v

  model = model.replace("##tenant_id##", dataValue.tenant_id)
  model = model.replace("##client_id##", dataValue.client_id)
  model = model.replace("##access_token##", dataValue.access_token)
  model = model.replace("##type##", dataValue.type)

  func.req_parameters[indexdata].v = GlobalUtil.encodeBase64(model)

  func.req_transaction_id = genrateTranscriptID()

  const url = new URL(`${env.REACT_APP_ENGINE_URL}auth/ad`)
  return new Promise((resolve, reject) => {
    axiosApiInstance
      .post(url, func, {
        headers: {
          "Content-Type": "application/json"
          // 'Authorization': `Bearer ${accessToken}`
          // 'Access-Control-Allow-Origin': '*'
        }
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const doserviceAuthB2C = async (data, accessToken = "") => {
  let dataValue = {
    tenant_id: data.tenant_id,
    client_id: data.client_id,
    b2c: "Y",
    acess_token: "",
    validated_claims: accessToken,
    type: "CMS"
  }
  // const dataBody = {

  const resp = await GlobalConfigApi.doserviceFindGlobalConfigByKey({
    key: "FUNCTION_F100011_B2C"
  })

  let func = resp.Data.Value
  let req_para = func.req_parameters

  let indexdata = req_para.findIndex(({ k }) => k === "data")
  let model = req_para[indexdata].v
  // let indexby = req_para.findIndex(({ k }) => k === "by");
  // let by = req_para[indexby].v;

  model = model.replace("##tenant_id##", dataValue.tenant_id)
  model = model.replace("##client_id##", dataValue.client_id)
  model = model.replace("##type##", dataValue.type)
  model = model.replace("##validated_claims##", dataValue.validated_claims)
  model = model.replace("##object_id##", "")

  // by = by.replace('##by##', userName);

  func.req_transaction_id = genrateTranscriptID()
  func.req_parameters[indexdata].v = GlobalUtil.encodeBase64(model)
  // func.req_parameters[indexby].v = GlobalUtil.encodeBase64(by);

  console.log("func =>", func)
  console.log("model =>", model)

  const url = new URL(`${env.REACT_APP_ENGINE_URL}auth/b2c/${data.client_id}`)
  return new Promise((resolve, reject) => {
    axios
      .post(url, func, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
          // 'Access-Control-Allow-Origin': '*'
        }
      })
      .then(res => {
        console.log('auth/b2c resp',res)
        // res.data.data = JSON.parse(GlobalUtil.decodeUFT8(res.data.data));
        if (res.data.resp_parameters.length > 0) {
          if(typeof res.data.resp_parameters[0].value !== 'undefined'){
            res.data.data = JSON.parse(
              GlobalUtil.decodeUFT8(res.data.resp_parameters[0].value)
            )
          }
        }
        // res.data.response_message = GlobalUtil.decodeUFT8(
        //   res.data.response_message
        // );
        console.log("AuthApi.doserviceAuthB2C => ", res.data)
        resolve(res.data)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const authRefresh = token => {
  const url = new URL(`${env.REACT_APP_ENGINE_URL}token/getRefreshToken`)
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
            // "Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(res => {
        resolve(res.data)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const Logout = () => {
  encryptStorage.clear()
}
