import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../resource/animation/empty.json'
import Typography from '@material-ui/core/Typography';

const Empty = (props) => {

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Lottie
                options={
                    {
                        loop: true,
                        autoplay: true,
                        animationData: animationData.default,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }
                }
                height={300}
                width={300}
                isStopped={false}
                isPaused={false}
            />

            <Typography align="center" variant="title" style={{ color: '#999999', marginBottom: 8 }}>{props.label && props.label != ""}</Typography>
            <Typography align="center" style={{ color: '#999999', marginBottom: 56,fontSize:14 }}>{props.description && props.description != ""}</Typography>
        </div>
    )
}

export { Empty }