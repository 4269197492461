import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { MTextField, ButtonSearch, } from "../component/common"
import Grid from '@material-ui/core/Grid';
import WithWebService from '../utils/WebServiceInterface'
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import ExportExcelReport from '../component/scene/ExportExcelReport'
import { formatNumber } from '../utils/Utilities';

const styles = theme => ({

    tab: { fontSize: 12, backgroundColor: '#D6D6D6', height: 70, color: theme.palette.text.main, },
    tabActive: { fontSize: 12, backgroundColor: '#fff', height: 70, color: theme.palette.secondary.main },
    rootTab: { borderWidth: 1, borderColor: 'red' },
    content: {
        padding: 36
    },
    containerTab: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    title: { fontSize: 24, },
    layoutField: { margin: 80 },
    buttonSubmit: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 24
    },
    buttonSearch: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 16
    },
    customButton: {
        backgroundColor: '#07C52A'
    },
    contentCard: {
        padding: 24
    },
    rootTable: {
        overflowX: 'auto'
    },
    table: {
        minWidth: 700,

    },
    tableRowOrange: {
        backgroundColor: 'rgba(255, 162, 0,0.5)'
    },
    tableRowRed: {
        backgroundColor: 'rgba(255, 43, 0,0.5)'
    },
    tableCellHeaderCustomer: {
        fontSize: 14,
        minWidth: 150,
        backgroundColor: '#7189bf',
        color: 'white',
    },
    tableCellHeaderBankGuarantee: {
        fontSize: 14,
        minWidth: 150,
        backgroundColor: '#71a0a5',
        color: 'white',
    },
    tableCellHeaderContract: {
        fontSize: 14,
        minWidth: 150,
        backgroundColor: '#679186',
        color: 'white',
    },
    header: {
        color: theme.palette.primary.dark,

    },


})


class ReportView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            myTask: [],
            isLoading: false,
            docType: "",
            page: 0,
            rowsPerPage: 10,
        }
    }

    componentWillMount() {

    }



    render() {

        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <div className={classes.content}>

                <Card>
                    <br />
                    <br />
                    <div>
                        <Container maxWidth="md">
                            <Grid item xs>

                                <Formik
                                    initialValues={{
                                        fullName: '',
                                        companyName: '',
                                        customerTax: '',
                                    }}
                                    validate={values => {
                                        const requires = []
                                        let errors = {};
                                        requires.forEach(field => {
                                            if (!values[field]) {
                                                errors[field] = 'Required';
                                            }
                                        });


                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        this.report(values)
                                    }}
                                    render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                                        <Form>
                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="companyName"
                                                        fullWidth
                                                        placeholder="กรอกชื่อบริษัทลูกค้า"
                                                        label="กรอกชื่อบริษัทลูกค้า"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="customerTax"
                                                        fullWidth
                                                        placeholder="รหัสลูกค้า"
                                                        label="รหัสลูกค้า"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>

                                            </Grid>



                                            <div className={classes.buttonSearch}>
                                                <ButtonSearch
                                                    label="Search"
                                                    disabled={this.state.isLoading}
                                                    isLoading={this.state.isLoading}
                                                    onClick={submitForm} />
                                            </div>

                                            <br />
                                            <br />
                                        </Form>
                                    )}
                                />
                            </Grid>
                        </Container>
                    </div>

                </Card>
                <br />

                {this.state.myTask.length > 0 &&
                    <Card>
                        <div className={classes.contentCard}>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Typography paragraph variant="subheading" className={classes.title}>รายงาน</Typography>
                                </Grid>
                                <Grid item>
                                    <ExportExcelReport dataSet={this.state.myTask} />
                                </Grid>
                            </Grid>
                            <Divider />
                            <div className={classes.rootTable}>
                                <Table className={classes.table}>
                                    <TableHead className={classes.header}>
                                        <TableRow >
                                            <TableCell colSpan={7} style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>Customer</TableCell>
                                            <TableCell colSpan={10} style={{ padding: 8 }} className={classes.tableCellHeaderBankGuarantee} align="center">Bank Guarantee</TableCell>
                                            <TableCell colSpan={8} style={{ padding: 8 }} className={classes.tableCellHeaderContract} align="center">Contract</TableCell>

                                        </TableRow>

                                    </TableHead>
                                    <TableHead className={classes.header}>
                                        <TableRow >
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>รหัสลูกค้า</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>รหัสส่งสินค้า</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>ชื่อลูกค้า</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>ประเภทธุรกิจ</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>Product Delivery</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>Business Area</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderCustomer}>Sale Area Manager</TableCell>


                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>แบบหนังสือค้ำประกัน</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>ประเภทหนังสือค้ำประกัน</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>จำนวนเงินค้ำประกัน</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>วันที่ต้องคืนหลักประกัน</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>ธนาคาร</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>สาขา</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>เลขที่หนังสือค้ำประกัน</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>วันที่ขอออกหนังสือค้ำประกัน</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>วันที่มีผลบังคับใช้หนังสือค้ำประกัน</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderBankGuarantee}>วันที่ครบกำหนด</TableCell>

                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>เลขที่สัญญา</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>วันที่สัญญามีผลบังคับใช้</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>วันที่สัญญาสิ้นสุด</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>วันที่เรื่มจ่ายก๊าซ</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>ระยะเวลาที่ต้องวาง BG</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>เลขที่การทำรายการจากระบบ</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>สาเหตุ</TableCell>
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeaderContract}>สถานะ</TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {this.state.myTask.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={row.customerId}>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.customerId}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.sapShipToCode}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="left">
                                                        {row.customerName}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.sapBusinessType}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.sapProductDelivery}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.sapBusinessArea}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.saleAreaManager}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.requestType}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.reportType}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {formatNumber(row.guaranteeAmount)}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.collateralCancelDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="left">
                                                        {row.bankName}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="left">
                                                        {row.bankBranch}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="left">
                                                        {row.bgNo}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.issueDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.collateralEnforceDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.collateralEndDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.contractNumber}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.contractEnforceDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.contractExpireDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.gasStartDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.periodeBGDate}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.requestNo}
                                                    </TableCell>

                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.note}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.statusEBG}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>


                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={this.state.myTask.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </div>
                    </Card>
                }

            </div>
        )
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: this.state.page, rowsPerPage: event.target.value });
    };

    report(values) {

        const parameter = values

        this.setState({ isLoading: true })


        this.props.webService.report(parameter).then(response => {

            this.setState({
                isLoading: false,
                myTask: response.data.data.bgList
            })


        }).catch(error => {

            this.setState({ isLoading: false })
        })
    }


}





export default WithWebService(withRouter(withStyles(styles)(ReportView)))