import { createSlice, current } from "@reduxjs/toolkit";

const configData = {
    menuNavigation: null,
}

const navigation = createSlice({
    name: "navigation",
    initialState: configData,
    reducers: {
        menuNavigationSlice: (state, action) => {
            state.menuNavigation = action.payload
            console.log("############ navigationSlice.js (current(state)) : ", current(state) );
            console.log("############ navigationSlice.js (action.payload) : ", action.payload );
            return state
        }
    }
});
export const { menuNavigationSlice } = navigation.actions
export default navigation;