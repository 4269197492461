import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withRouter } from 'react-router-dom'
import { keys, roles, } from '../utils/Constants'
import { deleteCookie } from '../utils/Utilities'
import { connect } from 'react-redux'
import { Link as LinkRouter } from 'react-router-dom'
import ScrollUpButton from "react-scroll-up-button";
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import '../css/main.css'
//env
import { env } from "../env"

import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar';
import Box from '@material-ui/core/Box/Box';
import WithWebService from '../utils/WebServiceInterface'
import { aadMsalInstance, b2cMsalInstance } from "../index"
import { useSelector } from "react-redux";
import { getCookie } from '../utils/Utilities'
import { withMsal,MsalContext  } from "@azure/msal-react";
import { EncryptStorage } from "encrypt-storage";
const drawerWidth = 240;

const styles = theme => ({
    spacing: 8,
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    root: {
        display: 'flex',
        minHeight: '100vh'


    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        // [theme.breakpoints.up('sm')]: {
        //     width: drawerWidth,
        //     flexShrink: 0,
        // },
        width: drawerWidth,
        flexShrink: 0,

    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        // [theme.breakpoints.down('xs')]: {
        //     transition: theme.transitions.create(['margin', 'width'], {
        //         easing: theme.transitions.easing.sharp,
        //         duration: theme.transitions.duration.leavingScreen,
        //     }),
        // },
        // [theme.breakpoints.up('sm')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     marginLeft: drawerWidth,
        //     transition: theme.transitions.create(['margin', 'width'], {
        //         easing: theme.transitions.easing.easeOut,
        //         duration: theme.transitions.duration.enteringScreen,
        //     }),
        // },
        marginLeft: drawerWidth,
        paddingTop: 56,
        paddingBottom: 56,

    },
    titleNavigation: {
        [theme.breakpoints.only('xs')]: {
            marginLeft: 0
        },
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: 50
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 200
        }
    },
    contentChild: {
        minHeight: '80vh',
        marginTop: 56,
    },
    avatar: {
        width: 30,
        height: 30,
        marginRight: 8,
    },
    avatarDrawer: {
        width: 40,
        height: 40,
        marginRight: 8,
    },
    toolbar: theme.mixins.toolbar,
    iconItemList: {
        marginLeft: 16,
        marginRight: 8,
        fontSize: 20
    },
    iconSubItemList: {
        marginRight: 8,
        fontSize: 20,
        color: 'white'
    },
    subItemList: {
        height: 40,
        '&:hover,&:focus': {
            opacity: 1,
            backgroundColor: theme.palette.secondary.highlightMenu,
            color: theme.palette.secondary.main,
        },
        fontSize: 14
    },
    titleSection: {
        fontSize: 16
    },
    titlePage: {
        fontSize: 16,
        color: 'white'
    },
    logo: {
        width: 95,
        objectFit: 'contain'
    },
    appName: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white'
    },
    layoutBreadcrumbs: {
        marginLeft: 36,
    },
    toolbarThird: {
        backgroundColor: theme.palette.secondary.main,
        background: `url(${require('../resource/image/bg_page_header.png')})`
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.searchField, 0.4),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.searchField, 0.8),
        },
        marginLeft: 0,
        width: "auto",
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        fontSize: 12,
        width: 60,
        height: 10,
        borderRadius: theme.shape.borderRadius,
        '&:focus': {
            width: 200,
            backgroundColor: fade(theme.palette.common.searchField, 1),
        },
    },
    textFooter: {
        color: '#A6A6A6',
        fontSize: 14,
        textDecoration: 'none'
    },
    footer: {
        marginTop: 36,
    },
    textMenu: {
        color: 'white'
    },
    divider: {
        backgroundColor: 'rgba(255,255,255,0.4)'
    },
    textName: {
        color: 'white',
        fontSize: 16
    },
    textPosition: {
        color: 'white',
        fontSize: 12
    },
    buttonFlow: {
        marginRight: 5
    },
    backgroundLogo: {
        backgroundColor: 'white'
    },

});

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const encryptStorage = new EncryptStorage('secret-key', {
    prefix: "@CLAIM"
});

class MainView extends React.Component {
    
    static contextType = MsalContext;

    constructor(props) {
        super(props)

        this.state = {
            isDrawerOpen: false,
            isDrawerMobileOpen: false,
            anchorProfile: null,
            imageProfile: "",
            role:'',
            user: {},
            menuNavigation: null
        }
    }

    handleClickProfile = event => {
        this.setState({ anchorProfile: event.currentTarget });
    };

    handleCloseProfile(event) {
        this.setState({ anchorProfile: null });
    };

    logout() {
        
       const _instance = this.context.instance;
       console.log("############ MainView.js (logout) _instance: ", _instance );
        deleteCookie(keys.IS_LOGIN)
        setTimeout(() => {
            deleteCookie(keys.USERNAME)
            deleteCookie(keys.USERDETAIL)
            deleteCookie(keys.TOKEN)
        }, 500);

        // instance.logoutRedirect(logoutRequest);
        // instance.logoutRedirect({ postLogoutRedirectUri: `${process.env.PUBLIC_URL}` })
        // this.props.history.replace('/login')
        _instance.logoutRedirect({ postLogoutRedirectUri: `/` })
        encryptStorage.clear();
       // this.props.history.replace('/login')
    }

    componentDidMount() {

        if(getCookie(keys.USERDETAIL)){
            // console.log("############ MainView.js (getCookie(keys.USERDETAIL)) : ", getCookie(keys.USERDETAIL) );
            // this.setState({ user: JSON.parse(getCookie(keys.USERDETAIL)) })
            const checkUser = JSON.parse(getCookie(keys.USERDETAIL))
            this.setState({ user: checkUser })
            // console.log("############ MainView.js (checkUser) : ", checkUser );
            if(checkUser && checkUser.role !== roles.CUS){
                // console.log("############ MainView.js getEmployeeImage " );
                this.getEmployeeImage(checkUser.officerCode)
            }
        }
        // if (this.props.account && this.props.account.role != roles.CUS) {
        //     this.getEmployeeImage()
        // }
        // if (this.props.userReducer.user && this.props.userReducer.user.role != roles.CUS) {
        //     this.getEmployeeImage()
        // }
    }

    getCurrentMenu() {

        var title = ''
        if (window.location.pathname.includes('create')) {
            title = 'ขอวางหลักประกัน (หนังสือค้ำประกันอิเล็กทรอนิกส์)'
        } else if (window.location.pathname.includes('issue')) {
            title = 'ขอวางหลักประกัน (หนังสือค้ำประกันอิเล็กทรอนิกส์)'
        } else if (window.location.pathname.includes('amend')) {
            title = 'ขอแก้ไขหลักประกันสัญญา (หนังสือค้ำประกันอิเล็กทรอนิกส์)'
        } else if (window.location.pathname.includes('decrease')) {
            title = 'ขอลดวงเงินหลักประกัน (หนังสือค้ำประกันอิเล็กทรอนิกส์)'
        } else if (window.location.pathname.includes('cancel')) {
            title = 'ขอคืนหลักประกัน (หนังสือค้ำประกันอิเล็กทรอนิกส์)'
        } else if (window.location.pathname.includes('log/request')) {
            title = 'ประวัติ'
        }else if (window.location.pathname.includes('request')) {
            title = 'บันทึกรับวางหลักประกัน'
        } else if (window.location.pathname.includes('tasks')) {
            title = 'งานของฉัน'
        } else if (window.location.pathname.includes('customer/register')) {
            title = 'ลงทะเบียนลูกค้า'
        } else if (window.location.pathname.includes('customer/search')) {
            title = 'ค้นหาลูกค้า'
        } else if (window.location.pathname.includes('paper/detail')){
            title = 'ประวัติเอกสาร'
        } else if (window.location.pathname.includes('paper')) {
            title = 'บันทึกรับหลักประกันแบบกระดาษ'
        }

        return title
    }

    render() {

        const { classes, } = this.props;
        console.log("############ MainView.js (this.props) : ", this.props );

        return (
            <div>
               
                <AppBar position="fixed" className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <Grid container alignItems="center" >
                            <Grid item>
                                <Box mr={1}>
                                    <a href='/'>
                                        <img alt="logo" src={require('../resource/image/ptt-logo2.png')} className={classes.logo} />
                                    </a>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.titleNavigation} variant="h6">{this.getCurrentMenu()}</Typography>
                            </Grid>
                            <Grid item xs ></Grid>
                            <Grid item>
                                {/* {this.props.menuNavigation && this.props.menuNavigation()} */}
                                {this.props.navigation.menuNavigation && this.props.navigation.menuNavigation()}
                                {/* {this.state.menuNavigation && this.props.menuNavigation()} */}
                            </Grid>

                        </Grid>

                    </Toolbar>

                </AppBar>


                <nav className={classes.drawer}>

                    {/* <Hidden smUp implementation="css">
                        <Drawer
                            className={classes.drawer}
                            variant="temporary"
                            classes={{
                                paper: 'drawer',
                            }}
                            open={this.state.isDrawerOpen} onClose={this.onDrawerHandle(false)}>
                            <List>
                                <ListItem>
                                    <Grid container alignItems="center">
                                        <Avatar alt="Profile" src={this.state.imageProfile} className={classes.avatarDrawer} />
                                        <Typography>{this.props.account && this.props.fullName}</Typography>
                                    </Grid>
                                </ListItem>
                                <ItemProfile classes={classes} />
                                <ItemProfile classes={classes} />

                            </List>
                            <Divider />
                            {this.renderDrawer()}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: 'drawer',
                            }}
                            open>
                            <div className={classes.toolbar} />
                            {this.renderDrawer()}
                        </Drawer>
                    </Hidden> */}

                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: 'drawer',
                        }}
                        open>
                        <div className={classes.toolbar} />
                        {this.renderDrawer()}
                    </Drawer>


                </nav>
                <div className={classes.content}>

                    <Grid container justify="center" className={classes.contentChild}>
                        <Grid item xs={11} >
                            {this.props.children}
                            <br />
                        </Grid>
                    </Grid>

                    <Grid container justify="center" alignItems="center" className={classes.footer}>
                        <Grid item>
                            <Box mr={6}><div className={classes.textFooter}>&copy; 2019 PTTPLC.</div></Box>
                            <Box mr={6}><div className={classes.textFooter}>{this.activeAccount??'null'}</div></Box>
                        </Grid>
                        <Grid item>
                            <Box mr={6}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <div className={classes.textFooter}><Icon>help_outline</Icon></div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.textFooter}>Need help?</div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box mr={6}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <div className={classes.textFooter}><Icon>phone</Icon></div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.textFooter}><a className={classes.textFooter} href="tel:3456;">3456</a></div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box mr={6}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <div className={classes.textFooter}><Icon>email</Icon></div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.textFooter}><a className={classes.textFooter} href="mailto:servicedesk@pttdigital.com;">servicedesk@pttdigital.com</a></div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                    </Grid>
                </div>
                <ScrollUpButton />
            </div>
        )
    }

    onDrawerHandle = (state) => () => {
        this.setState({ isDrawerOpen: state})
    }

    handleBreadcrumbs(event) {

    }

    getEmployeeImage(userName) {


        // const parameter = { employeeId: this.props.account.officerCode }
        const parameter = { employeeId: userName }
        this.props.webService.getEmployeeImage(parameter).then(response => {


            const image = new Buffer(response.data, 'binary').toString('base64')
            this.setState({ imageProfile: image })

        }).catch(error => {

        })

      

    }

    renderDrawer() {

        const { classes, } = this.props;

        return (
            <List disablePadding>

                <ListItem />
                <ListItem>
                    <ListItemAvatar>
                    
                        {this.state.imageProfile === "" ? <Avatar alt="Profile" src={require('../resource/image/temp_acc.svg')} className={classes.avatarDrawer} /> : <Avatar alt="Profile" src={`data:image/jpeg;base64,${this.state.imageProfile}`} className={classes.avatarDrawer} />}
                        
                    </ListItemAvatar>

                    <div>
                        <Typography className={classes.textName}>{this.state.user && this.state.user.fullName}</Typography>
                        <Typography className={classes.textPosition}>{this.state.user && this.state.user.position}</Typography>
                    </div>
                </ListItem>
                <ListItem />
                <Divider className={classes.divider} />

                <ListItem />
                <ItemSubMenu classes={classes} icon="home" route='/' label="หน้าหลัก" onClick={this.onDrawerHandle(false)} />
                <ListItem />
                <Divider className={classes.divider} />
                {(this.state.user && (this.state.user.role === roles.ARF && this.state.user.role !== roles.SALES_VIEW)) && <div>
                    <ListItem />
                    <ItemSubMenu classes={classes} icon="add" route='/paper/request' label="บันทึกรับวางหลักประกัน" onClick={this.onDrawerHandle(false)} />
                    {/* <ItemSubMenu classes={classes} icon="edit" route='/paper/edit' label="แก้ไขรับวางหลักประกัน" onClick={this.onDrawerHandle(false)} /> */}
                    <ListItem />
                    <Divider className={classes.divider} />
                </div>}

                


                {(this.state.user && ((this.state.user.role === roles.SALES || this.state.user.role === roles.SM) && this.state.user.role !== roles.SALES_VIEW)) && <div>

                    <ListItem>
                        <ListItemText primary="สร้างใบคำขอ" className={classes.textMenu} />
                    </ListItem>

                    <ItemSubMenu classes={classes} icon="note_add" route='/create' label="ขอวางหลักประกัน (Issue)" onClick={this.onDrawerHandle(false)} />
                    <ItemSubMenu classes={classes} icon="edit" route='/amend' label="ขอแก้ไขหลักประกัน (Amend)" onClick={this.onDrawerHandle(false)} />
                    <ItemSubMenu classes={classes} icon="archive" route='/decrease' label="ขอลดวงเงินหลักประกัน (Decrease)" onClick={this.onDrawerHandle(false)} />
                    <ItemSubMenu classes={classes} icon="restore_page" route='/cancel' label="ขอคืนหลักประกัน (Return)" onClick={this.onDrawerHandle(false)} />
                    <ListItem />
                    <Divider className={classes.divider} />
                </div>
                }

                <ListItem />
                <ItemSubMenu classes={classes} icon="search" route='/docment/search' label="ค้นหาหลักประกัน" onClick={this.onDrawerHandle(false)} />
                
                {(this.state.user && (this.state.user.role !== roles.ARF && this.state.user.role !== roles.CUS)) && <div>
                <ItemSubMenu classes={classes} icon="assignment" route='/report' label="รายงาน" onClick={this.onDrawerHandle(false)} />
                </div>}

                {(this.state.user && (this.state.user.role === roles.SM)) && <div>
                 
                    <ListItem />
                    <Divider className={classes.divider} />
                    <ListItem>
                        <ListItemText primary="Customer" className={classes.textMenu} />
                    </ListItem>
                    <ItemSubMenu classes={classes} route='/customer/search' icon="search" label="ค้นหาลูกค้า" onClick={this.onDrawerHandle(false)} />
                    {/* <ItemSubMenu classes={classes} route='/customer/register' icon="note_add" label="ลงทะเบียนลูกค้า" onClick={this.onDrawerHandle(false)} /> */}

                </div>}

                <ListItem />
                <Divider className={classes.divider} />
                <ListItem />
                {/* <ItemSubMenu classes={classes} icon="logout" route='/' label="Logout" onClick={this.logout.bind(this)} /> */}
                <ItemSubMenu classes={classes} icon="logout" route='/' label="Logout" onClick={ () => this.logout() } />
            </List>
        )
    }

}

const ItemProfile = ({ classes, route }) => {

    return (
        <ListItem button className={classes.subItemList}  >
            <Icon className={classes.iconItemList}>notifications</Icon>
            <div>Tawatchai</div>
        </ListItem>
    )
}


const ItemSubMenu = ({ classes, icon, label, route, onClick }) => {

    return (
        <ListItem button className={classes.subItemList} component={LinkRouter} to={route} onClick={onClick}>
            <Icon className={classes.iconSubItemList}>{icon}</Icon>
            <div component="span" className={classes.textMenu}>{label}</div>
        </ListItem>
    )
}

const mapStateToProps = ({ account, navigation }) => {

    return {
        account: account.user,
        navigation: navigation,
        // menuNavigation: navigation.menuNavigation
    }
}

export default WithWebService(connect(mapStateToProps)(withRouter(withStyles(styles, { withTheme: true })(MainView))))