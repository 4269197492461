import { Box, Button, Container, createTheme, Grid, Paper, Stack, ThemeProvider, Typography, Divider, IconButton, CssBaseline } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import "../css/login.css"

import { MButton, MTextField, MyTextField } from "../component/common"

//env
import { env } from "../env"

import React, { useEffect, useState } from "react";

import { tenants } from "../Tenants"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux/store";
import pageSlice from '../redux/feature/pageSlice'
import userSlice from "../redux/feature/userSlice"
import { EncryptStorage } from 'encrypt-storage';
import * as GlobalConfigApi from '../apis/GlobalConfigApi'
import globalConfig from "../config/globalConfig";
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import RegisterUserView from './RegisterUserView'
import ChangeForgotPasswordView from './ChangeForgotPasswordView'

let firstLoad = false;
const encryptStorage = new EncryptStorage('secret-key', {
    prefix: '@CLAIM',
});
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));
export default function LoginPage(props) {
    const classes = useStyles();
    // console.log('props',props)
    const dispatchPage = useDispatch();
    const [tenantsState, setTenantsState] = useState([]);
    let inStoreReduserPage = useSelector((state) => {
        return state.page;
    });
    let { userLogin } = useSelector((state) => state.userReducer )
    useEffect(() => {
        if (!firstLoad) {
            firstLoad = true
            getTenants()
        }
    }, [])

    useEffect(() => {
        if(encryptStorage.getItem('loginType')){
            dispatchPage(userSlice.actions.userDetailSlice(true))
        }
    }, [])

    const getTenants = async () => {
        try {
            const res = await GlobalConfigApi.doserviceFindGlobalConfigByKey({ key: "TENANT" })
            if (res.Data) {
                let tenants = res.Data;
                console.log('res', res)
                tenants = tenants.Value
                setTenantsState(tenants.tenants)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const clickLogin = (type) => {
        if (type === "Y") {
            props.clickLogin("B2C")
        } else {
            props.clickLogin("AAD")
        }

    }

    const setLoginButton = () => {
        try {
            return <>
                {tenantsState.map((data, index) => {
                    const apps = data.apps
                    if (apps) {
                        return apps.map((data2, index2) => {
                            return (
                                <Button style={{ textTransform: 'none' }} key={index2} onClick={() => clickLogin(data.b2c)} variant="contained" fullWidth >เข้าระบบ {data.name} สำหรับ {data2.app_display_name}</Button>    
                            )
                        })
                    }
                })}
            </>
        } catch (error) {
            console.log(error)
        }

    }
    const goToRegister =()=> {
       window.location.replace(`${env.REACT_APP_REDIRECT}/register`)
       
    }

    
    return (
        <div >
            <Container maxWidth="lg" sx={{ pt: 5 }}>
                <Paper
                    sx={{
                        margin: 'auto',
                        flexGrow: 1,
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20
                    }}
                >
                    <Grid container direction="row">
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <Box sx={{ pr: 15, pl: 15, pt: 15, pb: 15 }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography id="cms_web_app_welcome_to_txt" variant="overline" display="block" gutterBottom sx={{ textAlign: "center", fontSize: 16, color: "#616161" }}>
                                        welcome to
                                    </Typography>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <img
                                                src={`/images/ptt-log-color.png`}
                                                width="120"
                                                alt={`/images/ptt-log-color.png`}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm>
                                        <Box>
                                            <Typography
                                                id="cms_web_app_cms_web_application_txt_001"
                                                variant="overline"
                                                display="block"
                                                gutterBottom
                                                sx={{
                                                    fontSize: 21,
                                                    color: "#0d47a1",
                                                    mt: 0,
                                                    textShadow: `1px 1px 2px #616161`
                                                }}
                                                style={{
                                                    fontSize: 18,
                                                    color: "#0d47a1",
                                                    mt: 0,
                                                    textShadow: `1px 1px 2px #616161`
                                                }}
                                            >
                                                {globalConfig.UTILITIES.SYSTEM_NAME}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography id="cms_web_app_Sign_in_with_your_organizational_account_txt" sx={{ color: "#616161" }}>
                                            Sign in with your organizational account
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box sx={{ '& button': { mt: 1 }, mt: 2 }}>


                                    {/* {setLoginButton()} */}
                                    {props.children}


                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Box mt={1}>

                                                <Button size="small" onClick={() => goToRegister()}>

                                                    Create New Account

                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Box sx={{
                                height: 600,
                                position: "relative",
                                textAlign: "center",
                                color: "white"
                            }}
                            lg={{
                                width: 550,
                                height: 600,
                                position: "relative",
                                textAlign: "center",
                                color: "white"
                            }}
                            >
                                <Box sx={{
                                    backgroundImage: `url(/images/bg-login.png)`,
                                    height: "100%",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    borderTopRightRadius: 20,
                                    borderBottomRightRadius: 20
                                }}>

                                </Box>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}
                                >
                                    <img src={`/images/white-logo.png`} width="100" alt={`/images/white-logo.png`} />
                                    <Typography
                                        id="cms_web_app_cms_web_application_txt_002"
                                        variant="overline"
                                        display="block"
                                        gutterBottom
                                        sx={{
                                            fontSize: 18,
                                            textShadow: `1px 1px 2px #616161`,
                                            mt: -1
                                        }}
                                    >
                                        {globalConfig.UTILITIES.SYSTEM_NAME}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            {userLogin && (
                <>
                    {/* <Dialog
                        fullWidth={true}
                        maxWidth="md"
                        open={true}
                        onClose={handleCloseIdCard}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogContent style={{ overflow: 'hidden' }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                            >
                                <Grid item xs={12} style={{ padding: "9rem" }} >
                                    <CircularProgress  color="secondary" disableShrink  size={300}  />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog> */}
                    <Backdrop className={classes.backdrop} open={true} >
                        <CircularProgress color="inherit" disableShrink size={200} />
                    </Backdrop>
                </>
            )}
        </div>
    );
}