import React from "react"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import RequestBG from '../component/scene/RequestBG'
import { Loading } from '../component/common'
import WithWebService from '../utils/WebServiceInterface'

const styles = theme => ({
    root: {
        marginTop: 0
    },
    title: { fontSize: 24, },
    layoutSubTitle: {
        margin: 36,
    },
    connectorActive: {
        '& $connectorLine': {
            borderColor: '#2d9714',
            borderWidth: 3

        },
    },
    connectorCompleted: {
        '& $connectorLine': {
            borderColor: '#2d9714',
            borderWidth: 3
        },
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: theme.palette.grey[100],
            borderWidth: 3
        },
    },
    connectorLine: {
        transition: theme.transitions.create('border-color'),
        borderWidth: 3

    },
    stepIcon: {
        width: 40,
        height: 40
    },
    rootSteper:{
        backbroundColor:'transparent'
    }
})



class CreateBGView extends React.Component {


    constructor(props) {
        super(props)
        // const { poNumber, requestNo } = this.props.match.params

        this.state = {
            isLoading: false,
            creditDetail:{},
            steps: [
                { label: 'สร้างคำขอ', decscription:'(Sales)', completed: false, },
                { label: 'ตรวจสอบ', decscription: '(Credit Officer)', completed: false, },
                { label: 'อนุมัติ', decscription: '(Credit Manager)', completed: false, },
                { label: 'ส่งคำขอ', decscription: '(Customer)', completed: false, },
                { label: 'ดำเนินการ', decscription: '(BCI)', completed: false, },
                { label: 'เสร็จสิ้น', decscription: '(Bank)', completed: false, }],
        }
    }

 
    getCreditDetail(requestNo) {

        this.setState({ isLoading: true })
        const parameter = { requestNo: requestNo}
        this.props.webService.getCreditDetail(parameter).then(response => {
           
            this.setState({ isLoading: false, creditDetail: response.data.data })


        }).catch(error => {
          
            this.setState({ isLoading: false, })
        })

    }

    render() {

        const { classes } = this.props;

        const connector = (
            <StepConnector/>
        );
    
        return (
            <div className={classes.root}>

                <Stepper nonLinear alternativeLabel activeStep={0} connector={connector} className={classes.rootSteper} style={{backgroundColor:'transparent'}}>

                    {this.state.steps.map((step, index) => {
                  
                        return (
                            <Step key={step.label} completed={false} >

                                <StepLabel> 
                                    <div>
                                        <div>
                                            {step.label}
                                        </div>
                                        <div>
                                            {step.decscription}
                                        </div>
                                    </div>
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>

                <main>
                    <div>
                        {this.state.isLoading ? <Loading /> : <RequestBG creditDetail={this.state.creditDetail} isCreate onSave={(requestNo) => {
                            this.getCreditDetail(requestNo)
                        }}/>}
                    </div>
                </main>

            </div>
        )
    }


}



export default WithWebService(withRouter(withStyles(styles, { withTheme: true })(CreateBGView)))