import React from 'react'
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RequestBG from './RequestBG'
import WithWebService from '../../utils/WebServiceInterface'
import { Empty, ButtonSubmit } from "../common"
import { capitalize} from '../../utils/Utilities'
import { Loading} from '../common'

const styles = theme => ({

    layoutField: { width: '90%' },
    fabProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSubmit: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 24,
    },
    buttonSearch: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 16
    },
    customButton: {
        backgroundColor: '#07C52A'
    },
    content: {
        marginBottom: 110
    }
})

class PopupPoDetailChangeFlow extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpenDialog: false,
            isLoading: false,
            creditDetail: null,
        }

        props.onRef(this)
    }

    handleClose = () => {
        this.setState({ isOpenDialog: false });
        if (this.state.onClose != null) {
            this.state.onClose()
        }
    };

    showDialog(requestNo) {
        this.setState({ isOpenDialog: true, })
        this.getCreditDetail(requestNo)
    }


    render() {
        const { classes, } = this.props;
        const { creditDetail, } = this.state

        return (
            <Dialog
                open={this.state.isOpenDialog}
                onClose={this.handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                    <DialogTitle>
                        {capitalize(this.props.flow)}
                    </DialogTitle>

                    <DialogContent classes={{ root: classes.layoutField }}>
                        <div>
                            {creditDetail != null ? <div className={classes.content}>
                                <RequestBG creditDetail={creditDetail} isView />
                                <div className={classes.buttonSubmit}>
                                    <ButtonSubmit
                                        label="Submit"
                                        disabled={this.state.isLoading}
                                        isLoading={this.state.isLoading}
                                        onClick={() => {
                                            this.changeStatusFlow(creditDetail.requestNo)

                                        }} />

                                   
                                </div>
                            </div> : this.state.isLoading ? <Loading /> : <Empty label="ไม่พบข้อมูล"/>}
                        </div>

                    </DialogContent>
                </div>
            </Dialog>
        )
    }

    getCreditDetail(requestNo) {

        this.setState({ isLoading: true })
        const parameter = { requestNo: requestNo }
        this.props.webService.getCreditDetail(parameter).then(response => {
            this.setState({ isLoading: false, creditDetail: response.data.data })


        }).catch(error => {
            this.setState({ isLoading: false, })
        })

    }

    changeStatusFlow() {

        const { creditDetail, } = this.state 
        creditDetail.status = this.props.flow


        this.setState({ isLoading: true })
        this.props.webService.addCreditDetail(creditDetail).then(response => {
            this.setState({ isLoading: false }, () => {
                this.props.onSubmit(response.data.data.requestNo)
            })

        }).catch(error => {
            this.setState({ isLoading: false })
        })

    }



}

export default WithWebService(withStyles(styles)(PopupPoDetailChangeFlow)) 