import React from "react";
import { get, post } from "./WebService";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

const WithWebService = (Component) => {
  return class ComponentWithWebService extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpenDialog: false,
        message: "",
      };
    }

    render() {
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <Component {...this.props} webService={this} />
          <Dialog
            open={this.state.isOpenDialog}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"xs"}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <DialogTitle>
                <Icon style={{ fontSize: 50 }} color="error">
                  error_outline
                </Icon>
              </DialogTitle>

              {/* <Typography variant="h6">Fail</Typography> */}

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={this.handleClose}
                  color="secondary"
                  style={{ marginBottom: 24 }}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      );
    }

    handleClose = () => {
      this.setState({ isOpenDialog: false });
    };

    login(parameter) {
      return this.post("credit/login/verify", parameter);
    }

    searchCustomer(parameter) {
      return this.post("credit/customer/findData", parameter);
    }

    findDocument(parameter) {
      return this.post("credit/findDocument", parameter);
    }

    checkTaxId(parameter) {
      return this.post("credit/checkTaxId", parameter);
    }

    requestLog(parameter) {
      return this.post("credit/actionLog", parameter);
    }

    registerCustomer(parameter) {
      return this.post("credit/customer/register", parameter);
    }

    historyDocument(parameter) {
      return this.post("credit/historyDocument", parameter);
    }

    report(parameter) {
      return this.post("credit/report", parameter);
    }

    customerList(parameter) {
      return this.post("credit/customer/findData", parameter);
    }

    activeUser(parameter) {
      return this.post("credit/activeUser", parameter);
    }

    createAccount(parameter) {
      return this.post("credit/createAccount", parameter);
    }

    getCollateralModel() {
      return this.get("credit/getCollateralModel");
    }

    forgotPassword(parameter) {
      return this.post("credit/forgotPassword", parameter);
    }

    changePassword(parameter) {
      return this.post("credit/changePassword", parameter);
    }

    getCollateralTypeList() {
      return this.get("credit/getCollateralTypeList");
    }

    poDetail(parameter) {
      return this.post("ebg/getPoDetail", parameter);
    }

    getOfficerProfile(parameter) {
      return this.post("ebg/getOfficerProfile", parameter);
    }

    getCollaterralDetail(parameter) {
      return this.post("ebg/getCollaterralDetail", parameter);
    }

    getGuaranteePeriod(parameter) {
      return this.post("ebg/getGuaranteePeriod", parameter);
    }

    addGuaranteePeriod(parameter) {
      return this.post("ebg/addGuaranteePeriod", parameter);
    }

    createCreditPaper(parameter) {
      return this.post("credit/createCreditPaper", parameter);
    }

    addPartner(parameter) {
      return this.post("ebg/addPartner", parameter);
    }

    getPartner(parameter) {
      return this.post("ebg/getPartner", parameter);
    }

    getMyTask(parameter) {
      return this.post("credit/myTasks", parameter);
    }

    getMyTaskAmend(parameter) {
      return this.post("ebg/amend/myTasks", parameter);
    }

    getAmendTask(parameter) {
      return this.post("credit/findByebg", parameter);
    }

    stepPaperInfo(parameter) {
      return this.post("credit/stepPaperInfo", parameter);
    }

    getCreditPaper(parameter) {
      return this.post("credit/getCreditPaper", parameter);
    }

    createPoDetail(parameter) {
      return this.post("ebg/createPoDetail", parameter);
    }

    assignments(parameter) {
      return this.post("credit/issue/assignments", parameter);
    }

    assignmentsAmend(parameter) {
      return this.post("credit/amend/assignments", parameter);
    }

    assignmentsDecrease(parameter) {
      return this.post("credit/decrease/assignments", parameter);
    }

    assignmentsCancel(parameter) {
      return this.post("credit/cancel/assignments", parameter);
    }

    getAcceptCollateral(parameter) {
      return this.post("ebg/getAcceptCollateral", parameter);
    }

    searchCustomerByTaxID(parameter) {
      return this.post("credit/customer/findSapCustomer", parameter);
    }

    addCreditDetail(parameter) {
      return this.post("credit/addCreditDetail", parameter);
    }
    getCreditDetail(parameter) {
      return this.post("credit/getCreditDetail", parameter);
    }

    addAcceptCollateral(parameter) {
      return this.post("ebg/addAcceptCollateral", parameter);
    }

    getDocTypeList() {
      return this.get("ebg/getDocTypeList");
    }

    getBankList() {
      return this.get("credit/getBankList");
    }

    masterSapBusinessArea() {
      return this.get("credit/masterSapBusinessArea");
    }

    masterSapBusinessType() {
      return this.get("credit/masterSapBusinessType");
    }

    masterSapProductDelivery() {
      return this.get("credit/masterSapProductDelivery");
    }

    getBankBranchList() {
      return this.get("ebg/getBanch");
    }

    searchEmployee(parameter) {
      return this.post("ebg/searchOfficer", parameter);
    }

    saveEmployee(parameter) {
      return this.post("ebg/addOfficer", parameter);
    }

    conditionCancel() {
      return this.get("ebg/conditionCancel");
    }

    modifyPoNumber(parameter) {
      return this.post("ebg/modifyPoDetail", parameter);
    }

    createWorkflow(parameter) {
      return this.post("ebg/createWorkflow", parameter);
    }

    uploadFile(parameter) {
      return this.post("credit/file/upload", parameter, true, true);
    }

    removeFile(parameter) {
      return this.post("credit/file/remove", parameter, true, true);
    }

    getEmployeeImage(parameter) {
      return this.post("credit/getEmployeeImage", parameter, true, false, {
        responseType: "arraybuffer",
      });
    }

    downloadFile(parameter) {
      return this.post("credit/file/download", parameter, true, false, {
        responseType: "blob",
      });
    }

    downloadFileFormCustomer(parameter) {
      return this.get("credit/file/download/formCustomer", parameter, true, {
        responseType: "blob",
      });
    }

    getUserProfileV3(parameter) {
      return this.post("credit/getUserProfileV3", parameter);
    }

    post(path, parameter, isShowError = true, isMultipart, config = {}) {
      return new Promise((resolve, reject) => {
        return post(path, parameter, isMultipart, config)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (isShowError) {
              this.handleError(error, reject);
            } else {
              reject(error);
            }
          });
      });
    }

    get(path, parameter, isShowError = true, config = {}) {
      return new Promise((resolve, reject) => {
        return get(path, parameter, config)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (isShowError) {
              this.handleError(error, reject);
            } else {
              reject(error);
            }
          });
      });
    }

    handleError(error, reject) {
      var errorMessage = "";
      if (error.response != null && error.response.data.resultDesc) {
        errorMessage = error.response.data.resultDesc;
      } else {
        errorMessage = error.message;
      }

      reject(error);
      setTimeout(() => {
        this.setState({ isOpenDialog: true, message: errorMessage });

        // if (error.response.status === 401) {
        //     setTimeout(() => {
        //         deleteCookie(this.$keys.IS_LOGIN);
        //         deleteCookie(this.$keys.USERNAME);
        //         deleteCookie(this.$keys.USERDETAIL);
        //         deleteCookie(this.$keys.TOKEN);

        //         this.$router.replace("/login");
        //     }, 1000);
        // } else if (error.response.status === 404) {
        //     setTimeout(() => {
        //         this.$router.replace("/404");
        //     }, 1000);
        // }
      }, 500);
    }
  };
};

export default WithWebService;
