import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { ButtonSearch, MSelect, MTextField, MyTextField} from "../component/common"
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import WithWebService from '../utils/WebServiceInterface'
import Container from '@material-ui/core/Container';
import { MenuItem, } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DialogApp from '../component/common/DialogApp'

const styles = theme => ({

    tab: { fontSize: 12, backgroundColor: '#D6D6D6', height: 70, color: theme.palette.text.main, },
    tabActive: { fontSize: 12, backgroundColor: '#fff', height: 70, color: theme.palette.secondary.main },
    rootTab: { borderWidth: 1, borderColor: 'red' },
    content: {
        padding: 36
    },
    containerTab: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    title: { fontSize: 24, },
    layoutField: { margin: 80 },
    buttonSubmit: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 24
    },
    buttonSearch: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 16
    },
    customButton: {
        backgroundColor: '#07C52A'
    },
    contentCard: {
        padding: 24
    },
    table: {
        minWidth: 700,
    },
    tableRowOrange: {
        backgroundColor: 'rgba(255, 162, 0,0.5)'
    },
    tableRowRed: {
        backgroundColor: 'rgba(255, 43, 0,0.5)'
    },
    tableCellHeader: {
        fontSize: 14
    },
    header: {
        color: theme.palette.primary.dark,

    },


})


class RegisterCustomerView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            myTask: [],
            isLoading: false,
            docType: "",
            page: 0,
            rowsPerPage: 10,
            sapAreaList: [],
            openDialogFile:false,
            selectCompanyFiles:[],
            selectCustomerFile:[],
            isPopupNote: false,
            remark:"",
            userSelect:{},


        }
    }

    componentWillMount() {
        this.getSapBusinessArea()
    }

    handleCloseDialogNote() {
        this.setState({ isPopupNote: false })
    }

    render() {

        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <div className={classes.content}>

                <Card>
                    <br />
                    <br />
                    <div>
                        <Container maxWidth="md">
                            <Grid item xs>

                                <Formik
                                ref={ref =>{
                                    this.form = ref
                                }}
                                    initialValues={{
                                        fullName: '',
                                        companyName: '',
                                        businessAreaCode: '',
                                    }}
                                    validate={values => {
                                        const requires = []
                                        let errors = {};
                                        requires.forEach(field => {
                                            if (!values[field]) {
                                                errors[field] = 'Required';
                                            }
                                        });


                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        this.findUserList(values)
                                    }}
                                    render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                                        <Form>
                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="companyName"
                                                        fullWidth
                                                        placeholder="กรอกชื่อบริษัทลูกค้า"
                                                        label="กรอกชื่อบริษัทลูกค้า"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="fullName"
                                                        fullWidth
                                                        placeholder="ชื่อผู้มาติดต่อ"
                                                        label="ชื่อผู้มาติดต่อ"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        fullWidth
                                                        type="text"
                                                        name="businessAreaCode"
                                                        label="Business Area"
                                                        options={this.state.sapAreaList}
                                                        component={MSelect}
                                                    >
                                                        {this.state.sapAreaList.map(option => (
                                                            <MenuItem key={option.sapBusinessAreaCode} value={option.sapBusinessAreaCode}>
                                                                {option.sapBusinessAreaDescription}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                </Grid>
                                            </Grid>



                                            <div className={classes.buttonSearch}>
                                                <ButtonSearch
                                                    label="Search"
                                                    disabled={this.state.isLoading}
                                                    isLoading={this.state.isLoading}
                                                    onClick={submitForm} />
                                            </div>

                                            <br />
                                            <br />
                                        </Form>
                                    )}
                                />
                            </Grid>
                        </Container>
                    </div>

                </Card>
                <br />

                {this.state.myTask.length > 0 &&
                    <Card>
                        <div className={classes.contentCard}>
                            <Typography paragraph variant="subheading" className={classes.title}>ผลการค้นหา</Typography>
                            <Divider />
                            <div className={classes.rootTable}>
                                <Table className={classes.table}>
                                    <TableHead className={classes.header}>
                                        <TableRow >
                                            <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeader}>ชื่อบริษัท</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">ชื่อผู้ติดต่อ</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">Email</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">Bussiness Area</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">เอกสารแนบ</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center"></TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center"></TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {this.state.myTask.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={row.requestNo}>
                                                    <TableCell style={{ padding: 8 }} align="left">
                                                        <div>
                                                            <div>{row.companyName}</div>
                                                            <div>{row.companyTaxId}</div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="left">
                                                        {`${row.fullName}`}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="left">
                                                        {`${row.customerEmail}`}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">{`${row.sapBusinessArea}`}</TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center"><IconButton onClick={() =>{
                                                        this.setState({ openDialogFile: true, selectCompanyFiles: row.attachFiles.companyList, selectCustomerFile: row.attachFiles.customerList})
                                                    }}><Icon>description</Icon></IconButton></TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">

                                                        {row.statusApprove === '' && <Button style={{width:110}} variant="contained" color="secondary" onClick={() => {
                                                           
                                                           this.setState({isPopupNote:true,userSelect:row})
                                                           
                                                        }}>
                                                            <Icon>close</Icon>
                                                        ไม่อนุมัติ
                                                        </Button>}
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        {row.statusApprove === '' && <Button style={{ width: 100 }}  variant="contained" color="secondary" onClick={() => {
                                                         
                                                            this.dialogApp.showDialogConfirm('ยืนยัน', `ต้องการอนุมัติ ${row.fullName} หรือไม่`, () => {

                                                            }, () => {
                                                                    this.setStatusUser(row.customerEmail, 'Y')
                                                            })
                                                            
                                                        }}>
                                                            <Icon>done</Icon>
                                                            อนุมัติ
                                                        </Button>}
                                                       
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>


                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={this.state.myTask.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </div>
                    </Card>
                }

                {this.renderDialogFile()}

                <Dialog
                    open={this.state.isPopupNote}
                    onClose={() => {

                    }}
                    fullWidth={true}
                    maxWidth={'sm'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">สาเหตุ</DialogTitle>
                    <DialogContent>
                        <MyTextField
                            name="remark"
                            multiline
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            rows={7}
                            value={this.state.remark}
                            onChange={e => {
                                this.setState({ remark: e.target.value })
                            }}
                            rows={7} />



                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogNote.bind(this)} color="secondary">
                            Cancel
                            </Button>
                        <Button onClick={() => {

                            if(this.state.remark.trim() === ""){

                                this.dialogApp.showDialogFail("ผิดพลาด", "ถรุณากรอกสาเหตุ", () => { })
                            }else{

                                this.setStatusUser(this.state.userSelect.customerEmail, 'N',this.state.remark)

                            }
                            

                        }} color="secondary" >
                            OK
                             </Button>
                    </DialogActions>
                </Dialog>

                <DialogApp ref={ref => {
                    this.dialogApp = ref
                }} />


            </div>
        )
    }

    renderDialogFile(){

        return(
            <Dialog 
                fullWidth
                maxWidth={'sm'}
            onClose={() =>{
                this.setState({openDialogFile:false})
            }} aria-labelledby="simple-dialog-title" open={this.state.openDialogFile}>
                <DialogTitle id="simple-dialog-title">เอกสารแนบ</DialogTitle>
                <List>
                    <ListSubheader>เอกสารแนบของบริษัท</ListSubheader>
                    {this.state.selectCompanyFiles.map(file => (
                        <ListItem button onClick={() => {
                            this.downloadFile(file)
                        }} key={file.fileId}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Icon>get_app</Icon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={file.fileName} />
                        </ListItem>
                    ))}

                    <ListSubheader>เอกสารแนบอื่นๆ</ListSubheader>
                    {this.state.selectCustomerFile.map(file => (
                        <ListItem button onClick={() => {
                            this.downloadFile(file)
                        }} key={file.fileId}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Icon>get_app</Icon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={file.fileName} />
                        </ListItem>
                    ))}

                </List>
            </Dialog>
        )
    }

    downloadFile(file) {

        const parameter = { filePath: file.filePath }
        this.props.webService.downloadFile(parameter).then(response => {


            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', file.fileName);
            // document.body.appendChild(link);
            // link.click();

            var newBlob = new Blob([response.data])

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, file.fileName);
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            // link.download = file.fileName;
            link.setAttribute('download', file.fileName);
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);


        }).catch(error => {

        })

    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: this.state.page, rowsPerPage: event.target.value });
    };
    
    getSapBusinessArea() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapBusinessArea().then(response => {
                
                
                this.setState({ sapAreaList: response.data.data.businessArea })
                resolve()

            }).catch(error => {
                
                reject()
            })
        })

    }


    findUserList(values) {


        this.setState({ isLoading: true })


        this.props.webService.customerList(values).then(response => {

            this.setState({
                isLoading: false,
                myTask: response.data.data.customerList
            })


        }).catch(error => {
            
            this.setState({ isLoading: false })
        })
    }

    setStatusUser(email, status, remark="") {

        const parameter = {
            email,
            approve:status,
            remark: remark

        }

        this.setState({ isLoading: true })


        this.props.webService.activeUser(parameter).then(response => {
         
            this.setState({
                isLoading: false,
                isPopupNote:false
            })

            this.form.submitForm()


        }).catch(error => {
          
            this.setState({ isLoading: false })
        })
    }


}





export default WithWebService(withRouter(withStyles(styles)(RegisterCustomerView)))