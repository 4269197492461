import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import "../css/login.css"
import axios from "axios"
import { MButton, MTextField, ButtonSearchCircle, MSelect } from "../component/common"
import WithWebService from '../utils/WebServiceInterface'
import { withRouter } from 'react-router-dom'
import { validateEmail } from '../utils/Utilities'
import { actionLogin } from '../redux/actions'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import DialogApp from '../component/common/DialogApp'

const styles = theme => ({

    root: {
        height: '100vh',
    },
    buttonLogin: {
        width: 150,
        marginTop: 24
    },
    textHello: {
        color: 'white',
        fontSize: 28,
        marginLeft: 16,
        marginRight: 16,
        fontWeight: 'bold',

    },
    textWelcome: {
        color: 'white',
        fontSize: 24,
        marginLeft: 16,
        marginRight: 16,

    },
    textWelcomeDescription: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
        marginLeft: 16,
        marginRight: 16,
        marginTop: 16,

    },
    textOr: {
        color: 'white',
        fontSize: 16,
        marginLeft: 16,
        marginRight: 16,
        marginTop: 36,
    },
    cardLeft: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        height: "100%",
    },
    logo: {
        width: 90,
        height: "auto",
        objectFit: 'contain'
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    content: {
        paddingTop: 86,
        paddingBottom: 24
    },
    required: {
        color: 'red'
    },
    labelColor: {
        color: theme.palette.common.textTitle
    }
})

const style = {
    itemField: { paddingTop: 0, paddingBottom: 0 }
}

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

class RegisterUserView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            isLoading: false,
            showError: true,
            companyList: [],
            customerList: [],
            isLoadingAttachFileCompany: false,
            isLoadingAttachFileCustomer: false,
            isLoadingButtonRegister: false,
            sapAreaList: [],

        }

    }

    componentDidMount() {
        this.getSapBusinessArea()
    }



    render() {

        const { classes, } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="absolute" style={{
                    background: 'primary',
                }} elevation={0}>
                    <Toolbar>
                        <Grid container spacing={8} alignItems="center">
                            <Grid item>
                                <img alt="logo" src={require("../resource/image/ptt-logo2.png")} className={classes.logo} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    Electronic Bank Guarantee
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Grid container alignContent="center" alignItems="center" justify="center" className={classes.content}>
                    <Grid item xs={11} md={9} sm={9} lg={9} xl={7}>
                        <Card elevation={5}>

                            <Box pt={5} pb={5}>
                                <Grid container justify="center">
                                    <Grid item xs={9}>
                                        <div className="containerForm">
                                            <Box mb={4}> <Typography variant="h5">Create an Account</Typography></Box>
                                            <Formik
                                                validate={values => {
                                                    const requires = ['companyTaxId', 'companyNameTh', 'companyNameEn', 'fullName', 'businessTypeCode', 'password']
                                                    let errors = {};
                                                    requires.forEach(field => {
                                                        if (!values[field]) {
                                                            errors[field] = 'Required';
                                                        }
                                                    });

                                                    if (values['companyTaxId'].toString().length < 13) {
                                                        errors['companyTaxId'] = 'กรุณากรอกเลขประจำตัวผู้เสียภาษี 13 หลัก'
                                                    }

                                                    if (!validateEmail(values['customerEmail'])) {
                                                        errors['customerEmail'] = 'Invalid email format';
                                                    }



                                                    if (values['password'] != values['confirmPassword']) {
                                                        errors['confirmPassword'] = 'Password not match'
                                                    }

                                                    return errors;
                                                }}
                                                initialValues={{
                                                    companyTaxId: '',
                                                    companyNameTh: '',
                                                    companyNameEn: '',
                                                    fullName: '',
                                                    customerEmail: '',
                                                    businessTypeCode: '',
                                                    password: '',
                                                    confirmPassword: ''

                                                }}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setSubmitting(false);
                                                    if (this.state.companyList.length > 0 && this.state.customerList.length > 0) {
                                                        this.sendRegister(values)
                                                    }

                                                    // setCookie(keys.IS_LOGIN, true, 1)
                                                    // this.props.history.replace('/request/guarantee')
                                                }}
                                                render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                                                    <Form>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12} md={6} style={style.itemField}>
                                                                <Field
                                                                    type="tax"
                                                                    name="companyTaxId"
                                                                    fullWidth
                                                                    label="เลขประจำตัวผู้เสียภาษี"
                                                                    margin="normal"
                                                                    maxLength={13}
                                                                    required
                                                                    component={MTextField}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sm={12} md={6} style={style.itemField}>
                                                                <Field
                                                                    type="text"
                                                                    name="companyNameTh"
                                                                    fullWidth
                                                                    label="ชื่อบริษัท (ภาษาไทย)"
                                                                    margin="normal"
                                                                    required
                                                                    component={MTextField}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} sm={12} md={6} style={style.itemField}>
                                                                <Field
                                                                    type="text"
                                                                    name="companyNameEn"
                                                                    fullWidth
                                                                    label="ชื่อบริษัท (ภาษาอังกฤษ)"
                                                                    margin="normal"
                                                                    required
                                                                    component={MTextField}
                                                                />
                                                            </Grid>
                                                            <Grid xs={12} sm={12} md={6} item style={style.itemField}>
                                                                <FormControl className={classes.formControl} error={this.state.companyList.length <= 0}>
                                                                    <div>
                                                                        <Grid container className={classes.inputLabel}>
                                                                            <Grid item>
                                                                                <Typography className={classes.labelColor} variant="body2" >เลือกเอกสารแนบของบริษัท (ไม่เกิน 10 MB / File)</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="body2" className={classes.required}>&nbsp;*</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <br />
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item style={style.itemField}>

                                                                                <HtmlTooltip
                                                                                    title={
                                                                                        <React.Fragment>
                                                                                            <Typography color="inherit">เอกสารแนบของบริษัท</Typography>
                                                                                            <div>1. เอกสารรับรองบริษัท</div>
                                                                                            <div>2. หนังสือมอบอำนาจ (ถ้ามี)</div>
                                                                                            <div>3. สำเนาบัตรประชาชนของผู้มีอำนาจ หรือผู้มอบอำนาจและผู้รับมอบอำนาจ</div>
                                                                                            <div>4. แบบฟอร์มใช้บริการ PTT e-BG</div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                >
                                                                                    <span>
                                                                                        <input
                                                                                            multiple
                                                                                            id="button-file"
                                                                                            onChange={(e) => {

                                                                                                if (e.target.files.length <= 0) {
                                                                                                    return
                                                                                                }

                                                                                                for (let index = 0; index < e.target.files.length; index++) {
                                                                                                    const element = e.target.files[index];
                                                                                                    if (((element.size / 1024) / 1024) > 10) {
                                                                                                        this.dialogApp.showDialogFail("ผิดพลาด", "ไฟล์ขนาดใหญ่เกิน 10 MB", () => { })

                                                                                                        return
                                                                                                    }
                                                                                                }

                                                                                                this.setState({ isLoadingAttachFileCompany: true })
                                                                                                this.uploadFiles(e.target.files, 'companyList')

                                                                                            }}
                                                                                            type="file"
                                                                                            style={{
                                                                                                opacity: 0,
                                                                                                width: 30,
                                                                                                overflow: 'hidden',
                                                                                                position: 'absolute',
                                                                                                zIndex: 1,
                                                                                            }} />

                                                                                        <label htmlFor="button-file">
                                                                                            <ButtonSearchCircle
                                                                                                icon="attach_file"
                                                                                                size="small"
                                                                                                label="file"
                                                                                                containerElement='label'
                                                                                                component="span"
                                                                                                disabled={this.state.isLoading}
                                                                                                isLoading={this.state.isLoadingAttachFileCompany}>

                                                                                            </ButtonSearchCircle>
                                                                                        </label>
                                                                                    </span>
                                                                                </HtmlTooltip>





                                                                            </Grid>
                                                                            <Grid item xs style={style.itemField}>
                                                                                {this.state.companyList.map((element, index) => {
                                                                                    return (
                                                                                        <Chip
                                                                                            className={classes.clipFile}
                                                                                            key={index}
                                                                                            label={element.fileName}
                                                                                            onClick={() => {
                                                                                                this.downloadFile(element)
                                                                                            }}
                                                                                            onDelete={() => {

                                                                                                this.setState({ isLoadingAttachFileCompany: true })
                                                                                                this.removeFile(element, 'companyList')

                                                                                            }}
                                                                                        />

                                                                                    )
                                                                                })}


                                                                            </Grid>

                                                                        </Grid>
                                                                        <br />
                                                                        <Divider />
                                                                    </div>

                                                                    {this.state.companyList.length <= 0 && <FormHelperText>กรุณาเลือกเอกสารแนบของบริษัท</FormHelperText>}

                                                                </FormControl>


                                                            </Grid>
                                                        </Grid>

                                                        <Box mt={6}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={12} md={6} style={style.itemField}>
                                                                    <Field
                                                                        type="text"
                                                                        name="fullName"
                                                                        fullWidth
                                                                        label="ชื่อ-นามสกุล"
                                                                        margin="normal"
                                                                        required
                                                                        component={MTextField}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={6} style={style.itemField}>
                                                                    <Field
                                                                        type="text"
                                                                        name="customerEmail"
                                                                        fullWidth
                                                                        label="อีเมล"
                                                                        margin="normal"
                                                                        required
                                                                        component={MTextField}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={6} style={style.itemField}>
                                                                    <Field
                                                                        name="password"
                                                                        type="password"
                                                                        fullWidth
                                                                        label="Password"
                                                                        margin="normal"
                                                                        required
                                                                        component={MTextField}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={6} style={style.itemField}>
                                                                    <Field
                                                                        name="confirmPassword"
                                                                        type="password"
                                                                        fullWidth
                                                                        label="Confirm password"
                                                                        margin="normal"
                                                                        required
                                                                        component={MTextField}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} style={style.itemField}>

                                                                    <Field
                                                                        fullWidth
                                                                        required
                                                                        type="text"
                                                                        name="businessTypeCode"
                                                                        label="ประเภทธุรกิจของ ปตท. ที่ต้องการใช้บริการ"
                                                                        options={this.state.sapAreaList}
                                                                        component={MSelect}
                                                                    >
                                                                        {this.state.sapAreaList.map(option => (
                                                                            <MenuItem key={option.sapBusinessAreaId} value={option.sapBusinessAreaId}>
                                                                                {option.sapBusinessAreaDescription}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>

                                                                </Grid>

                                                            </Grid>
                                                            <br />

                                                            <Grid xs={12} item style={style.itemField}>
                                                                <FormControl className={classes.formControl} error={this.state.customerList.length <= 0} >
                                                                    <div>
                                                                        <Grid container className={classes.inputLabel}>
                                                                            <Grid item>
                                                                                <Typography className={classes.labelColor} variant="body2" >เลือกเอกสารแนบ (ไม่เกิน 10 MB / File)</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="body2" className={classes.required}>&nbsp;*</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <br />
                                                                        <Grid container spacing={1} alignItems="center">
                                                                            <Grid item style={style.itemField}>

                                                                                <HtmlTooltip
                                                                                    title={
                                                                                        <React.Fragment>
                                                                                            <Typography color="inherit">เอกสารของผู้ใช้งานระบบ</Typography>
                                                                                            <div>1. สำเนาบัตรประชาชนของผู้ใช้งานระบบ</div>
                                                                                            <div>2. หนังสือมอบอำนาจ (ถ้ามี)</div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                >
                                                                                    <span>

                                                                                        <input
                                                                                            multiple
                                                                                            id="button-file-cus"
                                                                                            onChange={(e) => {

                                                                                                if (e.target.files.length <= 0) {
                                                                                                    return
                                                                                                }

                                                                                                for (let index = 0; index < e.target.files.length; index++) {
                                                                                                    const element = e.target.files[index];
                                                                                                    if (((element.size / 1024) / 1024) > 10) {
                                                                                                        this.dialogApp.showDialogFail("ผิดพลาด", "ไฟล์ขนาดใหญ่เกิน 10 MB", () => { })

                                                                                                        return
                                                                                                    }
                                                                                                }
                                                                                                this.setState({ isLoadingAttachFileCustomer: true })
                                                                                                this.uploadFiles(e.target.files, 'customerList')

                                                                                            }}
                                                                                            type="file"
                                                                                            style={{
                                                                                                opacity: 0, overflow: 'hidden',
                                                                                                width: 30,
                                                                                                position: 'absolute',
                                                                                                zIndex: 1,
                                                                                            }} />

                                                                                        <label htmlFor="button-file-cus">
                                                                                            <ButtonSearchCircle
                                                                                                icon="attach_file"
                                                                                                size="small"
                                                                                                label="Search"
                                                                                                containerElement='label'
                                                                                                component="span"
                                                                                                disabled={this.state.isLoading}
                                                                                                isLoading={this.state.isLoadingAttachFileCustomer}
                                                                                                onClick={() => {

                                                                                                }} >

                                                                                            </ButtonSearchCircle>

                                                                                        </label>
                                                                                    </span>
                                                                                </HtmlTooltip>


                                                                            </Grid>
                                                                            <Grid item xs style={style.itemField}>
                                                                                {this.state.customerList.map((element, index) => {
                                                                                    return (
                                                                                        <Chip
                                                                                            className={classes.clipFile}
                                                                                            key={index}
                                                                                            label={element.fileName}
                                                                                            onClick={() => {
                                                                                                this.downloadFile(element)
                                                                                            }}
                                                                                            onDelete={() => {

                                                                                                this.setState({ isLoadingAttachFileCustomer: true })
                                                                                                this.removeFile(element, 'customerList')

                                                                                            }}
                                                                                        />

                                                                                    )
                                                                                })}


                                                                            </Grid>

                                                                        </Grid>
                                                                        <br />
                                                                        <Divider />
                                                                    </div>

                                                                    {this.state.customerList.length <= 0 && <FormHelperText>กรุณาแนบเอกสาร</FormHelperText>}

                                                                </FormControl>


                                                            </Grid>

                                                        </Box>

                                                        <Grid container>
                                                            <Grid item>
                                                                <Box mt={4}>
                                                                    <MButton isLoading={this.state.isLoadingButtonRegister} disabled={this.state.isLoading} onClick={submitForm} variant="contained" color="secondary" >
                                                                        Create an Account
                                                                    </MButton>
                                                                </Box>

                                                            </Grid>
                                                            <Grid item xs />
                                                            <Grid item>
                                                                <Box mt={4}>
                                                                    <MButton isLoading={this.state.isLoadingButtonRegister} disabled={this.state.isLoading} onClick={this.downloadFileFormCustomer.bind(this)} variant="contained" color="secondary" >
                                                                        Download Application Form
                                                                    </MButton>
                                                                </Box>

                                                            </Grid>
                                                        </Grid>

                                                        <Box mt={2} onClick={this.goLogin.bind(this)}>

                                                            <Button style={{ textTransform: "none" }} variant="contained" color="primary" size="small"> Already have an account? Sign-in</Button>
                                                        </Box>

                                                    </Form>
                                                )}
                                            />

                                        </div>
                                    </Grid>

                                </Grid>
                            </Box>

                        </Card>
                    </Grid>
                </Grid>

                <DialogApp ref={ref => {
                    this.dialogApp = ref
                }} />

            </div>
        )
    }



    getSapBusinessArea() {

        return new Promise((resolve, reject) => {

            this.props.webService.masterSapBusinessArea().then(response => {

                this.setState({ sapAreaList: response.data.data.businessArea })
                resolve()

            }).catch(error => {
                reject()
            })
        })

    }

    uploadFiles(files, stateUploadName) {

        this.setState({ isLoading: true, })

        const apiUploadFiles = []
        for (let index = 0; index < files.length; index++) {
            const element = files[index];
            apiUploadFiles.push(this.uploadFile(element))

        }
        axios.all(apiUploadFiles).then(response => {

            const fileUploads = this.state[stateUploadName]
            response.forEach(element => {
                fileUploads.push(element.data.data)
            })
            this.setState({ isLoading: false, isLoadingAttachFileCompany: false, isLoadingAttachFileCustomer: false, [stateUploadName]: fileUploads })
        }).catch(error => {

            this.setState({ isLoading: false, isLoadingAttachFileCompany: false, isLoadingAttachFileCustomer: false, })
        })

    }

    uploadFile(attachFile) {

        const parameter = new FormData()
        parameter.append("file", attachFile)
        return this.props.webService.uploadFile(parameter)

    }

    removeFile(file, stateUploadName) {

        const parameter = { "filePath": file.filePath }
        this.setState({ isLoading: true, })
        const fileUploads = this.state[stateUploadName]

        this.props.webService.removeFile(parameter).then(response => {

            fileUploads.splice(fileUploads.indexOf(file), 1)
            this.setState({ isLoading: false, isLoadingAttachFileCustomer: false, isLoadingAttachFileCompany: false, [stateUploadName]: fileUploads, })
        }).catch(error => {
            this.setState({ isLoading: false, isLoadingAttachFileCustomer: false, isLoadingAttachFileCompany: false })
        })

    }


    sendRegister(values) {

        const customerList = []
        this.state.customerList.forEach(element => {
            customerList.push(element)
        })

        const companyList = []
        this.state.companyList.forEach(element => {
            companyList.push(element)
        })


        const valuesData = Object.assign(values, { attachFiles: { customerList, companyList }, })


        this.setState({ isLoading: true, isLoadingButtonRegister: true })
        this.props.webService.createAccount(valuesData).then(response => {

            this.setState({ isLoading: false, isLoadingButtonRegister: false })
            setTimeout(() => {

                this.dialogApp.showDialogSuccess(`ระบบได้รับข้อมูลเรียบร้อยแล้ว`, ``, () => {

                    this.goLogin()
                })


            }, 300);

        }).catch(error => {
            this.setState({ isLoading: false, isLoadingButtonRegister: false })

        })


    }

    downloadFile(file) {

        const parameter = { filePath: file.filePath }
        this.props.webService.downloadFile(parameter).then(response => {


            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', file.fileName);
            // document.body.appendChild(link);
            // link.click();

            var newBlob = new Blob([response.data])

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, file.fileName);
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            // link.download = file.fileName;
            link.setAttribute('download', file.fileName);
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);


        }).catch(error => {

        })

    }

    downloadFileFormCustomer(file) {

        this.props.webService.downloadFileFormCustomer().then(response => {


            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', "ApplicationForm.pdf");
            // document.body.appendChild(link);
            // link.click();

            var newBlob = new Blob([response.data])

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, "ApplicationForm.pdf");
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            // link.download = file.fileName;
            link.setAttribute('download', "ApplicationForm.pdf");
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);

        }).catch(error => {

        })

    }

    goLogin() {
        this.props.history.replace('/login')
    }

}


export default connect(null, { actionLogin })(withRouter(withStyles(styles, { withTheme: true })(WithWebService(RegisterUserView))))