import { EncryptStorage } from "encrypt-storage"
import globalConfig from "../config/globalConfig"

export const genrateTranscriptID = () => {
  let d = new Date()
  let year = d.getFullYear().toString()
  let month = addZero(d.getMonth() + 1)
  let day = addZero(d.getDate())
  let hours = addZero(d.getHours())
  let minutes = addZero(d.getMinutes())
  let seconds = addZero(d.getSeconds())
  let ms = addZero(d.getMilliseconds(), 3)
  return year + month + day + hours + minutes + seconds + ms
}

const addZero = (number, size = 2) => {
  let str = number.toString()
  while (str.length < size) {
    str = "0" + str
  }
  return str
}

const hexToRgbA = (hex, opacity) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    )
  }
  throw new Error("Bad Hex")
}

const getUsername = () => {
  const encryptStorage = new EncryptStorage("secret-key", {
    prefix: globalConfig.GLOBALCONFIGKEY.PREFIX
  })
  const userName = encryptStorage.getItem("userName")

  return userName
}

const decodeUFT8 = str => {
  return decodeURIComponent(escape(window.atob(str)))
}

const encodeBase64 = str => {
  return window.btoa(unescape(encodeURIComponent(str)))
}

const tryJsonParse = text => {
  try {
    // JSON.parse(text);
    return JSON.parse(text)
  } catch (error) {
    return text
  }
}

const convertResponseData = res => {
  if (res.data.Data != null) {
    res.data.Data = tryJsonParse(decodeUFT8(res.data.Data))
    res.data.Data = tryJsonParse(res.data.Data)
  } else {
    res.data.Data = []
  }
  res.data.RespMessage = decodeUFT8(res.data.RespMessage)

  // if(res.data.value !== undefined){
  //     res.data.Data.value = tryJsonParse(res.data.Data.value);
  // }
  return res.data
}

const checkThilanguage = text => {
  if (/^[a-zA-Z0-9\s]*$/.test(text)) {
    return true
  } else {
    return false
  }
}

const validateThaiLanguage = text => {
  if (GlobalUtil.checkThilanguage(text)) {
    return true
  } else {
    return globalConfig.UTILITIES.ERRORS.VALIDATE_THAI_LANGUAGE
  }
}

const validateFormatDate = date => {
  if (date && !(date instanceof Date)) {
    return "Invaild Format Date" // Not a valid Date object
  } else if (date) {
    const today = new Date()
    if (today < date) {
      return "More Than Current Date"
    }

    const day = date.getDate()
    const month = date.getMonth() + 1 // Months are zero-based
    const year = date.getFullYear()
    // Check if the components of the date are valid
    if (
      isNaN(day) ||
      day < 1 ||
      day > 31 ||
      isNaN(month) ||
      month < 1 ||
      month > 12 ||
      isNaN(year) ||
      year < 1000 // Adjust the range if needed
    ) {
      return false // Invalid components
    }
  }
  return true // Date is valid
}

const handleOnChangeEventDate = (event, onChange) => {
  if (event && event.$d) {
    const selectedDate = event.$d
    const isValidDate = validateFormatDate(selectedDate)
    if (isValidDate) {
      onChange(event.$d)
    } else {
      onChange("Invalid Date")
    }
  } else {
    onChange(undefined)
  }
}

const GlobalUtil = {
  genrateTranscriptID,
  hexToRgbA,
  getUsername,
  decodeUFT8,
  encodeBase64,
  tryJsonParse,
  convertResponseData,
  checkThilanguage,
  validateThaiLanguage,
  validateFormatDate,
  handleOnChangeEventDate
}
export default GlobalUtil
