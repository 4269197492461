import axios from "axios"
import globalConfig from "../config/globalConfig"
import { EncryptStorage } from "encrypt-storage"
import * as AuthApi from "../apis/AuthApi"

const encryptStorage = new EncryptStorage("secret-key", {
  prefix: globalConfig.GLOBALCONFIGKEY.PREFIX
})

// const { instance } = useMsal();
let jwt = {
  access_token: "",
  refresh_token: ""
}
let jwtLocal = encryptStorage.getItem("jwt")
if (jwtLocal) {
  jwt = jwtLocal
}

const axiosApiInstance = axios.create()
// const dispatchPage: AppDispatch = useDispatch();

// Request interceptor for API calls
// Add a request interceptor
axiosApiInstance.interceptors.request.use(function(config) {
  // config.headers.Authorization =  jwt.access_token;
  let jwt = encryptStorage.getItem("jwt")
  if (jwt) {
    config.headers.Authorization = "Bearer " + jwt.access_token
  }
  return config
})

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  response => {
    return response
  },
  async function(error) {
    const originalRequest = error.config
    console.log("error axiosApiInstance ==> ", error)
    //   return null
    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true
        const token = await AuthApi.authRefresh(jwt.refresh_token)
        console.log("token =====", token)
        if (token.result === "Error") {
          AuthApi.Logout()
          window.location.reload()
          return
        }
        if (token.data) {
          encryptStorage.setItem("jwt", token.data[0])
        }
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + token.data[0].access_token
        return axiosApiInstance(originalRequest)
      } catch (error) {
        AuthApi.Logout()
        window.location.reload()
        return
      }
    }
    return Promise.reject(error)
  }
)

export default axiosApiInstance
