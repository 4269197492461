import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import "../css/login.css"
import CircularProgress from '@material-ui/core/CircularProgress';
import { MButton, MTextField, MyTextField } from "../component/common"
import WithWebService from '../utils/WebServiceInterface'
import { withRouter } from 'react-router-dom'
import { setCookie } from '../utils/Utilities'
import { keys } from '../utils/Constants'
import { actionLogin } from '../redux/actions'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogApp from '../component/common/DialogApp'
import { withMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { MsalProvider  } from "@azure/msal-react";
import AzureADLogin from '../component/common/AADLoginComp';
import AzureB2CLogin from '../component/common/B2CLoginComp';

import MainView from "./MainView"
const styles = theme => ({

    root: {
        height: '100vh',
    },
    buttonLogin: {
        marginTop: 24
    },
    textHello: {
        color: 'white',
        fontSize: 28,
        marginLeft: 16,
        marginRight: 16,
        fontWeight: 'bold',

    },
    fabProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        zIndex: 1,
    },
    textWelcome: {
        color: 'white',
        fontSize: 24,
        marginLeft: 16,
        marginRight: 16,

    },
    textWelcomeDescription: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
        marginLeft: 16,
        marginRight: 16,
        marginTop: 16,

    },
    textOr: {
        color: 'white',
        fontSize: 16,
        marginLeft: 16,
        marginRight: 16,
        marginTop: 36,
    },
    cardLeft: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        height: "50vh",
    },
    logo: {
        width: 90,
        height: "auto",
        objectFit: 'contain'
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})


class ADLoginView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            isLoading: false,
            showError: true,
            isPopupEmail: false,
            email: ''
        }

    }

    render() {

        const { classes, } = this.props;
        
        
        return (
            <div className={classes.root}>
                <AppBar position="absolute" style={{
                    background: 'primary',
                }} elevation={0}>
                    <Toolbar>
                        <Grid container spacing={8} alignItems="center">
                            <Grid item>
                                <img alt="logo" src={require("../resource/image/ptt-logo2.png")} className={classes.logo} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    Electronic Bank Guarantee
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Grid container alignContent="center" alignItems="center" justify="center" className={classes.root}>
                    <Grid item xs={10} md={8} sm={10} lg={6} xl={4}>
                        <Card elevation={5}>

                            <Box mt={8}>
                                <Grid container alignItems="center" justify="center">
                                    <Grid item>
                                        <img alt="logo" src={require("../resource/image/ptt-logo2.png")} className={classes.logo} />
                                    </Grid>
                                    <Grid item>
                                        <Box ml={4}>
                                            <Typography variant="h5">Electronic Bank Guarantee</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={4} mb={6}>
                                <Grid container justify="center">
                                    <Grid item xs={7}>
                                        <div className="containerForm">
                                            
                                        </div>
                                    </Grid>

                                </Grid>
                            </Box>


                        </Card>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.isPopupEmail}
                    onClose={() => {

                    }}
                    fullWidth={true}
                    maxWidth={'sm'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Please enter your register email</DialogTitle>
                    <DialogContent>
                        <MyTextField
                            name="remark"
                            margin="normal"
                            variant="outlined"
                            type="email"
                            label="Email"
                            fullWidth
                            rows={7}
                            value={this.state.email}
                            onChange={e => {
                                this.setState({ email: e.target.value })
                            }} />



                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={this.state.isLoading}
                            onClick={this.handleCloseDialogEmail.bind(this)} color="secondary">
                            Cancel
                            </Button>
                        <Button
                            disabled={this.state.isLoading}
                            onClick={() => {

                                this.sendForgotPassword()

                            }} color="secondary" >
                            SEND {this.state.isLoading && <CircularProgress size={20} className={classes.fabProgress} />}
                        </Button>
                    </DialogActions>
                </Dialog>

                <DialogApp ref={ref => {
                    this.dialogApp = ref
                }} />

            </div>
        )
    }


    handleCloseDialogEmail() {
        this.setState({ isPopupEmail: false })
    }


    sendForgotPassword() {
        const parameter = {
            "email": this.state.email,
        }

        this.setState({ isLoading: true })
        this.props.webService.forgotPassword(parameter).then(response => {

            this.setState({ isLoading: false, isPopupEmail: false })

            setTimeout(() => {
                this.dialogApp.showDialogSuccess("เรียบร้อย", `ระบบบได้ส่งลิงค์เปลี่ยนรหัสของท่านทางอีเมลเรียบร้อยแล้ว กรุณาตรวจสอบอีเมลของท่าน`, () => {

                })
            }, 300);


        }).catch(error => {
            this.setState({ isLoading: false })
        })

    }


    sendLogin(values) {
        const parameter = {
            "username": values.username,
            "password": values.password
        }

        this.setState({ isLoading: true })
        this.props.webService.login(parameter).then(response => {
            this.setState({ isLoading: false })
            setCookie(keys.IS_LOGIN, true, 1)
            setCookie(keys.TOKEN, response.data.token, 1)
            setCookie(keys.USERDETAIL, JSON.stringify(response.data), 1)
            this.props.actionLogin(response.data)

            this.props.history.replace('/')
        }).catch(error => {
            this.setState({ isLoading: false })
        })

    }

    goToRegister() {
        this.props.history.push('/register')
    }

}


export default connect(null, { actionLogin })(withRouter(withStyles(styles, { withTheme: true })(WithWebService(ADLoginView))))