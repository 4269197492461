import React from 'react'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

const MyButton = (props) =>{
    const { classes, theme, isLoading, children,disabled,...other } = props;

    return(
        <Button disabled={isLoading || disabled} {...other} >
            {children}
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
    )
}

const MButton = withStyles(styles, { withTheme: true })(MyButton)

export { MButton }