import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import TaskView from './TaskView'
import { Formik, Form, Field } from 'formik';
import { MTextField, ButtonSearch, MSelect } from "../component/common"
import Grid from '@material-ui/core/Grid';
import WithWebService from '../utils/WebServiceInterface'
import Container from '@material-ui/core/Container';
import { MenuItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({

    tab: { fontSize: 12, backgroundColor: '#D6D6D6', height: 70, color: theme.palette.text.main, },
    tabActive: { fontSize: 12, backgroundColor: '#fff', height: 70, color: theme.palette.secondary.main },
    rootTab: { borderWidth: 1, borderColor: 'red' },
    content: {
        padding: 36
    },
    containerTab: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    title: { fontSize: 24, },
    layoutField: { margin: 80 },
    buttonSubmit: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 24
    },
    buttonSearch: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 16
    },
    customButton: {
        backgroundColor: '#07C52A'
    },
    contentCard: {
        padding: 24
    }


})


class DecreaseListView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            myTask: [],
            isLoading: false,
            collateralModelList: [],
            docType: "",
            messageResponse: ''
        }
    }

    componentWillMount() {
        this.getCollateralModel()
    }

    getCollateralModel() {

        return new Promise((resolve, reject) => {

            this.props.webService.getCollateralModel().then(response => {
                this.setState({ collateralModelList: response.data.data.collateralModelList })
                resolve()

            }).catch(error => {
                reject()
            })
        })

    }


    render() {

        const { classes } = this.props;

        return (
            <div className={classes.content}>

                <Card>
                    <br />
                    <br />
                    <div>
                        <Container maxWidth="md">
                            <Grid item xs>

                                <Formik
                                    initialValues={{
                                        requestNo: '',
                                        contractName: '',
                                        contractNumber: '',
                                        customerTax: '',
                                        companyName: '',
                                        collateralTypeCode: '',
                                        collateralModelCode:''
                                    }}
                                    validate={values => {
                                        const requires = []
                                        let errors = {};
                                        requires.forEach(field => {
                                            if (!values[field]) {
                                                errors[field] = 'Required';
                                            }
                                        });


                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        this.getDecreaseList(values)
                                    }}
                                    render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                                        <Form>
                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="companyName"
                                                        fullWidth
                                                        placeholder="กรอกชื่อบริษัทลูกค้า"
                                                        label="กรอกชื่อบริษัทลูกค้า"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="bgNo"
                                                        fullWidth
                                                        placeholder="เลขที่หนังสือค้ำประกัน"
                                                        label="กรอกเลขที่หนังสือค้ำประกัน"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="customerId"
                                                        fullWidth
                                                        label="รหัสลูกค้า"
                                                        placeholder="รหัสลูกค้า"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        fullWidth
                                                        type="text"
                                                        name="collateralModelCode"
                                                        label="ประเภทหนังสือค้ำประกัน"
                                                        options={this.state.collateralModelList}
                                                        component={MSelect}
                                                    >
                                                        {this.state.collateralModelList.map(option => (
                                                            <MenuItem key={option.collateralModelCode} value={option.collateralModelCode}>
                                                                {option.collateralModelName}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                            </Grid>

                                            <div className={classes.buttonSearch}>
                                                <ButtonSearch
                                                    label="Search"
                                                    disabled={this.state.isLoading}
                                                    isLoading={this.state.isLoading}
                                                    onClick={submitForm} />
                                            </div>

                                            <br />
                                            <br />
                                        </Form>
                                    )}
                                />
                            </Grid>
                        </Container>
                    </div>

                </Card>
                <br />

                {this.state.myTask.length > 0 ?
                    <Card>
                        <div className={classes.contentCard}>
                            <Typography paragraph variant="subheading" className={classes.title}>ผลการค้นหา</Typography>
                            <Divider />
                            <TaskView isLoading={this.state.isLoading} task={this.state.myTask} type='AssignToMe' action="decrease" />
                        </div>
                    </Card>:
                    <div>
                        <br />
                        <Typography align="center" variant="h6">
                            {this.state.messageResponse}
                        </Typography>
                    </div>
                }

            </div>
        )
    }


    getDecreaseList(values) {

        this.setState({ isLoading: true })


        this.props.webService.getAmendTask(values).then(response => {

            this.setState({
                isLoading: false,
                myTask: response.data.data.bgList,
                messageResponse: response.data.data.bgList.length <= 0 ? 'ไม่พบข้อมูลที่ค้นหา' : ''
            })


        }).catch(error => {
            this.setState({ isLoading: false })
        })
    }



}





export default WithWebService(withRouter(withStyles(styles)(DecreaseListView)))