import { createSlice, current } from "@reduxjs/toolkit";

const configData = {
    userLogin:false,
}

const user = createSlice({
    name: "user",
    initialState: configData,
    reducers: {
        userDetailSlice: (state, action) => {
            state.userLogin = action.payload
            console.log("############ userSlice.js (current(state)) : ", current(state) );
            console.log("############ userSlice.js (action.payload) : ", action.payload );
            return state
        }
    }
});
export const { userDetailSlice } = user.actions
export default user;