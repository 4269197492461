import React, { useEffect, useRef, useState } from "react";
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withRouter } from 'react-router-dom'
import { keys, roles, } from '../utils/Constants'
import { deleteCookie } from '../utils/Utilities'
// import { connect } from 'react-redux'
import { Link as LinkRouter } from 'react-router-dom'
import ScrollUpButton from "react-scroll-up-button";
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import '../css/main.css'
import ListItemAvatar from '@material-ui/core/ListItemAvatar/ListItemAvatar';
import Box from '@material-ui/core/Box/Box';
import axios from 'axios'
import { AppDispatch, AppState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { EncryptStorage } from "encrypt-storage";
import { useMsal } from "@azure/msal-react";
import accountSlice from "../redux/feature/accountSlice";
import userSlice from "../redux/feature/userSlice"
import pageSlice from "../redux/feature/pageSlice";
import * as AuthApi from '../apis/AuthApi'
import GlobalUtil from "../util/GlobalUtil";
import globalConfig from "../config/globalConfig";
import { setCookie } from '../utils/Utilities'
import jwt from "jsonwebtoken"
//env
import { env } from "../env"

import LoginView from "./LoginView"
import ADLoginView from "./ADLoginView"
import LoginPage from './LoginPage';
import MainView from "./MainView"
import RequesteBGView from './RequesteBGView'
import CreateBGView from './CreateBGView'
import VenderLoginView from './VendorLoginView'
import MyTaskView from './MyTasksView'
import AmendListView from './AmendListView'
import CancelListView from './CancelListView'
import DecreaseListView from './DecreaseListView'
import RegisterCustomerView from './RegisterCustomerView'
import RequesteBGPaperView from './RequestBGPaperView'
import RegisterUserView from './RegisterUserView'
import SearchDocumentView from './SearchDocumentView'
import EditRequestPaperView from './EditRequestPaperView'
import CreditBgDetailVC from './CreditBgDetailVC'
import ReportView from './ReportView'
import RequestLogView from './RequestLogView'
import ChangeForgotPasswordView from './ChangeForgotPasswordView'
import { getCookie } from '../utils/Utilities'
import Swal from "sweetalert2";
const drawerWidth = 240;

const styles = theme => ({
    spacing: 8,
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    root: {
        display: 'flex',
        minHeight: '100vh'


    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        // [theme.breakpoints.up('sm')]: {
        //     width: drawerWidth,
        //     flexShrink: 0,
        // },
        width: drawerWidth,
        flexShrink: 0,

    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        // [theme.breakpoints.down('xs')]: {
        //     transition: theme.transitions.create(['margin', 'width'], {
        //         easing: theme.transitions.easing.sharp,
        //         duration: theme.transitions.duration.leavingScreen,
        //     }),
        // },
        // [theme.breakpoints.up('sm')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     marginLeft: drawerWidth,
        //     transition: theme.transitions.create(['margin', 'width'], {
        //         easing: theme.transitions.easing.easeOut,
        //         duration: theme.transitions.duration.enteringScreen,
        //     }),
        // },
        marginLeft: drawerWidth,
        paddingTop: 56,
        paddingBottom: 56,

    },
    titleNavigation: {
        [theme.breakpoints.only('xs')]: {
            marginLeft: 0
        },
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: 50
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 200
        }
    },
    contentChild: {
        minHeight: '80vh',
        marginTop: 56,
    },
    avatar: {
        width: 30,
        height: 30,
        marginRight: 8,
    },
    avatarDrawer: {
        width: 40,
        height: 40,
        marginRight: 8,
    },
    toolbar: theme.mixins.toolbar,
    iconItemList: {
        marginLeft: 16,
        marginRight: 8,
        fontSize: 20
    },
    iconSubItemList: {
        marginRight: 8,
        fontSize: 20,
        color: 'white'
    },
    subItemList: {
        height: 40,
        '&:hover,&:focus': {
            opacity: 1,
            backgroundColor: theme.palette.secondary.highlightMenu,
            color: theme.palette.secondary.main,
        },
        fontSize: 14
    },
    titleSection: {
        fontSize: 16
    },
    titlePage: {
        fontSize: 16,
        color: 'white'
    },
    logo: {
        width: 95,
        objectFit: 'contain'
    },
    appName: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white'
    },
    layoutBreadcrumbs: {
        marginLeft: 36,
    },
    toolbarThird: {
        backgroundColor: theme.palette.secondary.main,
        background: `url(${require('../resource/image/bg_page_header.png')})`
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.searchField, 0.4),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.searchField, 0.8),
        },
        marginLeft: 0,
        width: "auto",
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        fontSize: 12,
        width: 60,
        height: 10,
        borderRadius: theme.shape.borderRadius,
        '&:focus': {
            width: 200,
            backgroundColor: fade(theme.palette.common.searchField, 1),
        },
    },
    textFooter: {
        color: '#A6A6A6',
        fontSize: 14,
        textDecoration: 'none'
    },
    footer: {
        marginTop: 36,
    },
    textMenu: {
        color: 'white'
    },
    divider: {
        backgroundColor: 'rgba(255,255,255,0.4)'
    },
    textName: {
        color: 'white',
        fontSize: 16
    },
    textPosition: {
        color: 'white',
        fontSize: 12
    },
    buttonFlow: {
        marginRight: 5
    },
    backgroundLogo: {
        backgroundColor: 'white'
    },

});
const PrivateRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props =>
                getCookie(keys.IS_LOGIN) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: ""
                        }}
                    />
                )
            }
        />
    )
};

const LoginRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props =>
                getCookie(keys.IS_LOGIN) ? (
                    <Redirect
                        to={{
                            pathname: "/"
                        }}
                    />

                ) : (
                    <Component {...props} />
                )
            }
        />
    )
};
function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function Main(props) {
    const { tokenRequest, apps, history } = props
    const dispatchPage = useDispatch();
    const { accounts, instance } = useMsal();

    let inStoreReduser = useSelector((state) => {
        return state;
    });
    const encryptStorage = new EncryptStorage('secret-key', {
        prefix: "@CLAIM"
    });
    const account = encryptStorage.getItem('account')

    const [userDataState, setUserDataState] = useState({
        menu: {},
        name: {
            first: "",
            last: "",
        }
    });



    useEffect(() => {
        const loginType = encryptStorage.getItem('loginType')
        if (!inStoreReduser.account.rolename) {
            if (checkAccount()) {
                if (loginType === globalConfig.UTILITIES.LOGIN_TYPE_AAD) {
                    getAccessTokenAAD()
                } else {
                    getAccessTokenB2C()
                }
                
                // console.log("############ Main.js (getCookie(keys.USERDETAIL)) : ", JSON.stringify(getCookie(keys.USERDETAIL)) );
                // console.log("############ Main.js (getCookie(keys.USERDETAIL)) : ", getCookie(keys.USERDETAIL) );
                // const user = JSON.parse(getCookie(keys.USERDETAIL))
                // console.log("############ Main.js (user.roleCode) : ", user.roleCode );
                // if (user.roleCode && user.roleCode !== "CUS") {
                //     console.log("############ Main.js getEmployeeImage " );
                // }else{
                //     console.log("############ Main.js NOT getEmployeeImage " );
                // }
            }
        }
    }, []);

    const checkAccount = () => {
        try {
            if (accounts[0]) {
                return accounts[0]
            } else if (account) {
                return account
            }
        } catch (error) {
            console.log(error)
            return null
        }

    }

    const getAccessTokenAAD = async () => {
        try {
            await instance.acquireTokenSilent({
                ...apps.loginRequest,
                account: accounts[0]
            }
            ).then(async (response) => {
                console.log("############ Main.js getAccessTokenAAD (response) : ", response );
                await setValueAuthLoginEngine(response.accessToken, "")     
            }, (error) => {
                console.log("############ Main.js getAccessTokenAAD (error) : ", error );
                Swal.fire({
                    icon: "error",
                    text: globalConfig.UTILITIES.ERRORS.USER_NOT_EXIST_SYSTEM,
                }).then(async (result) => {
                    instance.logoutRedirect({ postLogoutRedirectUri: `${env.PUBLIC_URL}` })
                });
            },
            );
        } catch (error) {
            console.log(error)
        }
    }

    const getAccessTokenB2C = async () => {
        try {
            if (accounts[0].idTokenClaims.idp_access_token) {
                await setValueAuthLoginEngine(accounts[0].idTokenClaims.idp_access_token, "")
                // setCookie(keys.TOKEN, accounts[0].idTokenClaims.idp_access_token, 1)
            } else {
                await instance.acquireTokenSilent({
                    ...tokenRequest,
                    account: accounts[0]
                }
                ).then(async (response) => {
                    console.log("############ Main.js getAccessTokenB2C (response) : ", response );
                    if (response.accessToken) {
                        await setValueAuthLoginEngine(response.accessToken, "B2C");
                    } else {
                        console.log("############ Main.js getAccessTokenB2C (error) : ");
                        Swal.fire({
                            icon: "error",
                            text: globalConfig.UTILITIES.ERRORS.USER_NOT_EXIST_SYSTEM,
                        }).then(async (result) => {
                            instance.logoutRedirect({ postLogoutRedirectUri: `${env.PUBLIC_URL}` })
                        });
                    }
                },
                    (error) => {
                        console.log("############ Main.js getAccessTokenB2C (error) : ", error );
                        Swal.fire({
                            icon: "error",
                            text: globalConfig.UTILITIES.ERRORS.USER_NOT_EXIST_SYSTEM,
                        }).then(async (result) => {
                            instance.logoutRedirect({ postLogoutRedirectUri: `${env.PUBLIC_URL}` })
                        });
                    },
                );

            }
        } catch (error) {
            console.log(error)
        }
    }

    const setValueAuthLoginEngine = async (accessToken, type) => {
        try {
            let tenant = encryptStorage.getItem('tenant')
            const data = {
                access_token: accessToken,
                client_id: tenant.selectApps.client_id,
                tenant_id: tenant.tenant_id,
                type: "CMS"
            }
            let userApp = {}
            if (type !== "B2C") {
                userApp = await AuthApi.doserviceAuthAD(data, accessToken)
                console.log('userApp AD', userApp)
            } else {
                userApp = await AuthApi.doserviceAuthB2C(data, accessToken)
                console.log('userApp B2C', userApp)
            }

            // console.log("############ Main.js setValueAuthLoginEngine (userApp) : ", userApp );
            if (userApp.result_status === "SUCCESS") {
                userApp = JSON.parse(GlobalUtil.decodeUFT8(userApp.resp_parameters[0].value));
                // userDataState
                console.log(" userApp in Main.js =>",userApp);
                let account = inStoreReduser.account
                // let page = inStoreReduser.page
                if (!account.name.first) {
                    // let menu = userApp.menu
                    // let payload = {
                    //     ...account,
                    //     name: { ...userApp.name[0] },
                    //     rolename: menu[0].rolename,
                    //     roletype: menu[0].roletype,
                    //     jwt: userApp.jwt

                    // }
                    // dispatchPage(accountSlice.actions.updateAccount(payload));

                    // let menuList = JSON.parse(menu[0].menu)
                    // let payload2 = {
                    //     ...page,
                    //     menu: [...menuList]
                    // }
                    encryptStorage.setItem('jwt', userApp.jwt);
                    // let parameter = JSON.stringify({
                    //     "username": userApp.email
                    // });
                    // console.log("############ AADLoginComp.js autnLogin (parameter) : ", parameter );
              
                    // let config = {
                    //     method: 'post',
                    //     maxBodyLength: Infinity,
                    //     url: 'https://ebg-test.pttplc.com/be-ptt-credit-service-dev/credit/getUserProfileV3',
                    //     headers: { 
                    //       'Content-Type': 'application/json'
                    //     },
                    //     data : parameter
                    // };
              
                    // await axios.request(config)
                    //     .then((response) => {
                    //         console.log("############ AADLoginComp.js autnLogin (response.data) : ", response.data);
                    //         encryptStorage.setItem(keys.TOKEN, response.data.token)
                    //         setCookie(keys.TOKEN, response.data.token, 1)
                    //         setCookie(keys.USERDETAIL, JSON.stringify(response.data.data) , 1)
                    //         // dispatchPage(accountSlice.actions.userAccount(response.data.data[0]))
                    //         dispatchPage(userSlice.actions.userDetailSlice(JSON.stringify(response.data.data)))
                    //     })
                    //     .catch((error) => {
                    //         console.log("############ AADLoginComp.js autnLogin (error) : ", error);
                    //     });
                    
                    // this.setState({ isLoading: false })
                    // setCookie(keys.IS_LOGIN, true, 1)
                    // setCookie(keys.TOKEN, response.data.token, 1)
                    // setCookie(keys.USERDETAIL, JSON.stringify(response.data), 1)
                    // this.props.actionLogin(response.data)
                    // dispatchPage(pageSlice.actions.updatePage(payload2));

                }
            } else {
                console.log("############ Main.js setValueAuthLoginEngine (error) : ");
                Swal.fire({
                    icon: "error",
                    text: atob(userApp.response_message),
                }).then(async (result) => {
                    instance.logoutRedirect({ postLogoutRedirectUri: `${env.PUBLIC_URL}` })
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <MainView>
            <Switch>
                <Route  path="/register" component={RegisterUserView} />
                <Route  path="/customer/forgotpassword/:token" component={ChangeForgotPasswordView} />
                <Route exact path='/' component={MyTaskView} />
                <Route exact path='/customer/search' component={RegisterCustomerView} />
                <Route exact path='/docment/search' component={SearchDocumentView} />
                <Route exact path='/report' component={ReportView} />
                <Route path='/amend' component={AmendListView} />
                <Route path='/cancel' component={CancelListView} />
                <Route path='/decrease' component={DecreaseListView} />
                <Route path='/create' component={CreateBGView} />
                <Route path='/request/:action/:requestNo' component={RequesteBGView} />
                <Route key="request" exact path='/paper/request' component={RequesteBGPaperView} />
                <Route key="view" exact path='/paper/request/:requestNo?' component={RequesteBGPaperView} />
                <Route exact path='/log/request/:requestNo' component={RequestLogView} />
                <Route exact path='/paper/edit' component={EditRequestPaperView} />
                <Route exact path='/paper/detail/:requestNo/:documentId' component={CreditBgDetailVC} />
            </Switch>
        </MainView>
        // <Switch>
        //     <Route  path='/' component={MyTaskView} />
        //     <Route  path='/customer/search' component={RegisterCustomerView} />
        //     <Route  path='/docment/search' component={SearchDocumentView} />
        //     <Route  path='/report' component={ReportView} />
        //     <Route path='/amend' component={AmendListView} />
        //     <Route path='/cancel' component={CancelListView} />
        //     <Route path='/decrease' component={DecreaseListView} />
        //     <Route path='/create' component={CreateBGView} />
        //     <Route path='/request/:action/:requestNo' component={RequesteBGView} />
        //     <Route key="request" exact path='/paper/request' component={RequesteBGPaperView} />
        //     <Route key="view" exact path='/paper/request/:requestNo?' component={RequesteBGPaperView} />
        //     <Route  path='/log/request/:requestNo' component={RequestLogView} />
        //     <Route  path='/paper/edit' component={EditRequestPaperView} />
        //     <Route  path='/paper/detail/:requestNo/:documentId' component={CreditBgDetailVC} />
        // </Switch>
    );
}

export default withRouter(Main);