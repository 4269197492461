import React from "react"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import {actionGetStatusSave} from '../redux/actions'
import { connect } from 'react-redux'
import RequestBG from '../component/scene/RequestBG'
import {Loading} from '../component/common'
import WithWebService from '../utils/WebServiceInterface'
import { keys, roles,  } from '../utils/Constants'
import {getCookie} from '../utils/Utilities' 
import Axios from "axios";


const styles = theme => ({
    root: {
        marginTop: 36
    },
    connectorActive: {
        '& $connectorLine': {
            borderColor: theme.palette.secondary.main,
            borderWidth:2
            
        },
    },
    connectorCompleted: {
        '& $connectorLine': {
            borderColor: theme.palette.secondary.main,
            borderWidth:2
        },
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: theme.palette.grey[100],
            borderWidth:2
        },
    },
    connectorLine: {
        transition: theme.transitions.create('border-color'),
        borderWidth: 2
       
    },
    stepComplete:{
        backgroundImage: `url(${require('../resource/image/icons/milestone-green.svg')})`,
    },
    title: { fontSize: 24, },
    layoutSubTitle: {
        margin: 36,
    },
    stepIcon:{
        width:40,
        height:40
    }
})



class RequesteBGView extends React.Component {


    constructor(props) {
        super(props)
        // const { poNumber, requestNo } = this.props.match.params

        this.state = {
            creditDetail:{},
            isLoading:false,
            steps: [
                { label: 'สร้างคำขอ', decscription: '(Sales)', completed: false, },
                { label: 'ตรวจสอบ', decscription: '(Credit Officer)', completed: false, },
                { label: 'อนุมัติ', decscription: '(Credit Manager)', completed: false, },
                { label: 'ส่งคำขอ', decscription: '(Customer)', completed: false, },
                { label: 'ดำเนินการ', decscription: '(BCI)', completed: false, },
                { label: 'เสร็จสิ้น', decscription: '(Bank)', completed: false, }],
            stepsDescrese: [
                { label: 'สร้างคำขอ', decscription: '(Sales)', completed: false, },
                { label: 'ตรวจสอบ', decscription: '(Credit Officer)', completed: false, },
                { label: 'อนุมัติ', decscription: '(Credit Manager)', completed: false, },
                { label: 'ดำเนินการ', decscription: '(BCI)', completed: false, },
                { label: 'เสร็จสิ้น', decscription: '(Bank)', completed: false, }],
            stepsIssueCus: [
                { label: 'สร้างคำขอ', decscription: '(Sales)', completed: false, },
                { label: 'ส่งคำขอ', decscription: '(Customer)', completed: false, },
                { label: 'ดำเนินการ', decscription: '(BCI)', completed: false, },
                { label: 'เสร็จสิ้น', decscription: '(Bank)', completed: false, }],
            stepsCancel: [
                { label: 'สร้างคำขอ', decscription: '(Sales)', completed: false, },
                { label: 'อนุมัติ', decscription: '(Sales Manager)', completed: false, },
                { label: 'บันทึกข้อมูลลูกหนี้', decscription: '(AR Accountant)', completed: false, },
                { label: 'ตรวจสอบ', decscription: '(AR Manager)', completed: false, },
                { label: 'อนุมัติ', decscription: '(Credit Officer)', completed: false, },
                { label: 'อนุมัติ', decscription: '(Credit Manager)', completed: false, },
                { label: 'อนุมัติ', decscription: '(Sales VP)', completed: false, },
                { label: 'ดำเนินการ', decscription: '(BCI)', completed: false, },
                { label: 'เสร็จสิ้น', decscription: '(Bank)', completed: false, }],
            step:{
                "currentEbg": {
                    "requestNo": "",
                    "flowCode": "",
                    "flowName": ""
                },
                "create": "",
                "updateRequest": "",
                "verify": "",
                "approved": "",
                "bciProcess": "",
                "completed": ""
            }
                
        }
    }

    componentDidMount(){
        
        if (this.props.match.params.requestNo){
            const parameter = {
                "requestNo": this.props.match.params.requestNo
            }
            this.props.actionGetStatusSave(parameter)
            Axios.
                post(`credit/stepInfo`, parameter).then(response => {
                    console.log("############ RequesteBGView.js (response) : ", response.data );
                    this.setState({ step: response.data.data })
                    // dispatch({ type: FECT_STEP.SUCCESS, step: response.data.data })
                    // resolve(response)
                }).catch(error => {
                    // dispatch({ type: FECT_STEP.FAILED })
                    // reject(error)
                    console.log("############ RequesteBGView.js (error) : ", error );
                })
            this.getCreditDetail()
            
        }
       
    }

    getCreditDetail() {

        this.setState({ isLoading: true })
        const parameter = { requestNo: this.props.match.params.requestNo }
        this.props.webService.getCreditDetail(parameter).then(response => {
           
            this.setState({ isLoading: false, creditDetail: response.data.data })


        }).catch(error => {
          
            this.setState({ isLoading: false, })
        })

    }

    render() {

        const { classes } = this.props;



        return (
            <div className={classes.root}>
               
               
              
                {this.props.match.params.action === 'decrease' ? this.renderStep(this.state.stepsDescrese, this.checkCompleteDecrease.bind(this), this.getCurrentStepDecrease.bind(this)) : 
                (this.props.match.params.action === 'cancel' ? this.renderStep(this.state.stepsCancel, this.checkCompleteCancel.bind(this), this.getCurrentStepCancel.bind(this)) : 
                (this.checkRoleCUS() ? this.renderStep(this.state.stepsIssueCus, this.checkCompleteCusIssue.bind(this), this.getCurrentCusStep.bind(this)) : 
                this.renderStep(this.state.steps, this.checkCompleteIssue.bind(this), this.getCurrentStep.bind(this))))}
                
                <main>
                    <div>
                        
                        {this.state.isLoading ? <Loading /> : <RequestBG creditDetail={this.state.creditDetail} onSave={() =>{
                            this.getCreditDetail()
                        }}/>}
                    </div>
                </main>
                
            </div>
        )
    }

    renderStep(steps, checkCompleteIssue, getCurrentStep){

        const { classes } = this.props;


        const connector = (
            <StepConnector
                classes={{
                    active: classes.connectorActive,
                    completed: classes.connectorCompleted,
                    disabled: classes.connectorDisabled,
                    line: classes.connectorLine,
                }}
            />
        );

        return(
            <Stepper nonLinear alternativeLabel activeStep={getCurrentStep()} connector={connector} style={{ backgroundColor: 'transparent' }}>

                {steps.map((step, index) => {
                    // const stepStatus = this.props.step
                    const stepStatus = this.state.step
                    let isCompleted = checkCompleteIssue(index,stepStatus)

                    

                    return (
                        <Step key={step.label} completed={isCompleted} >

                            <StepLabel>
                                <div>
                                    <div>
                                        {step.label}
                                    </div>
                                    <div>
                                        {step.decscription}
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        )
        
    }

    checkRoleCUS() {

        const user = JSON.parse(getCookie(keys.USERDETAIL))
        return user.role === roles.CUS
    }

    checkCompleteIssue(index, stepStatus){

   

        var isCompleted = false
        switch (index) {
            case 0:
                isCompleted = stepStatus.create === "Y"
                break
            case 1:
                isCompleted = stepStatus.verify === "Y"
                break
            case 2:
                isCompleted = stepStatus.approve === "Y"
                break
            case 3:
                isCompleted = stepStatus.updateRequest === "Y"
                break
            case 4:
                isCompleted = stepStatus.bciProcess === "Y"
                break
            case 5:
                isCompleted = stepStatus.completed === "Y"
                break
            default:
                isCompleted = false
        }

    

        return isCompleted

    }

    checkCompleteDecrease(index, stepStatus) {


        var isCompleted = false
        switch (index) {
            case 0:
                isCompleted = stepStatus.create === "Y"
                break
            case 1:
                isCompleted = stepStatus.verify === "Y"
                break
            case 2:
                isCompleted = stepStatus.approve === "Y"
                break
            case 3:
                isCompleted = stepStatus.bciProcess === "Y"
                break
            case 4:
                isCompleted = stepStatus.completed === "Y"
                break
            default:
                isCompleted = false
        }

     
        return isCompleted

    }

    checkCompleteCusIssue(index, stepStatus){

     
        var isCompleted = false
        switch (index) {
            case 0:
                isCompleted = stepStatus.create === "Y"
                break
            case 1:
                isCompleted = stepStatus.updateRequest === "Y"
                break
            case 2:
                isCompleted = stepStatus.bciProcess === "Y"
                break
            case 3:
                isCompleted = stepStatus.completed === "Y"
                break
            default:
                isCompleted = false
        }

   

        return isCompleted

    }


    checkCompleteCancel(index, stepStatus) {

        var isCompleted = false
        switch (index) {
            case 0:
                isCompleted = stepStatus.create === "Y"
                break
            case 1:
                isCompleted = stepStatus.verifybySalesManager === "Y"
                break
            case 2:
                isCompleted = stepStatus.verifybyARAccountant === "Y"
                break
            case 3:
                isCompleted = stepStatus.verifybyARManager === "Y"
                break
            case 4:
                isCompleted = stepStatus.verifybyCreditOfficer === "Y"
                break
            case 5:
                isCompleted = stepStatus.verifybyCreditManager === "Y"
                break
            case 6:
                isCompleted = stepStatus.approvebySalesVP === "Y"
                break
            case 7:
                isCompleted = stepStatus.bciProcess === "Y"
                break
            case 8:
                isCompleted = stepStatus.completed === "Y"
                break
            default:
                isCompleted = false
        }

        return isCompleted

    }


    getCurrentStep(){

        // const stepStatus = this.props.step
        const stepStatus = this.state.step
        if (stepStatus.create === "C") {
            return 0
        } else if (stepStatus.verify === "C") {
            return 1
        } else if (stepStatus.approve === "C") {
            return 2
        } else if (stepStatus.updateRequest === "C") {
            return 3
        } else if (stepStatus.bciProcess === "C") {
            return 4
        } else if (stepStatus.completed === "C") {
            return 5
        }


    }

    getCurrentStepDecrease() {

        // const stepStatus = this.props.step
        const stepStatus = this.state.step
        if (stepStatus.create === "C") {
            return 0
        } else if (stepStatus.verify === "C") {
            return 1
        } else if (stepStatus.approve === "C") {
            return 2
        }  else if (stepStatus.bciProcess === "C") {
            return 3
        } else if (stepStatus.completed === "C") {
            return 4
        }
    }

    getCurrentCusStep() {

        // const stepStatus = this.props.step
        const stepStatus = this.state.step
        if (stepStatus.create === "C") {
            return 0
        } else if (stepStatus.updateRequest === "C") {
            return 1
        } else if (stepStatus.bciProcess === "C") {
            return 2
        } else if (stepStatus.completed === "C") {
            return 3
        }


    }

    getCurrentStepCancel() {

       

        // const stepStatus = this.props.step
        const stepStatus = this.state.step
        if (stepStatus.create === "C") {
            return 0
        } else if (stepStatus.verifybySalesManager === "C") {
            return 1
        } else if (stepStatus.verifybyARAccountant === "C") {
            return 2
        } else if (stepStatus.verifybyARManager === "C") {
            return 3
        } else if (stepStatus.verifybyCreditOfficer === "C") {
            return 4
        } else if (stepStatus.verifybyCreditManager === "C") {
            return 5
        } else if (stepStatus.approvebySalesVP === "C") {
            return 6
        } else if (stepStatus.bciProcess === "C") {
            return 7
        } else if (stepStatus.completed === "C") {
            return 8
        }

    }
}


const mapStateToProps = ({ step }) => {

    return (
        {
            // step: step.step
        }
    )
}

export default WithWebService(connect(mapStateToProps, { actionGetStatusSave })(withRouter(withStyles(styles, { withTheme: true })(RequesteBGView))))