import {
    configureStore,
    combineReducers,
    getDefaultMiddleware
  } from "@reduxjs/toolkit"
  import page from "./feature/pageSlice"
  import login from "./feature/loginSlice"
  import account from "./feature/accountSlice"
  import navigation from "./feature/navigationSlice"
  import user from "./feature/userSlice.js"
  const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })
  const rootReducer = combineReducers({
    page: page.reducer,
    login: login.reducer,
    account: account.reducer,
    navigation:navigation.reducer,
    userReducer: user.reducer
  })
  
  const store = configureStore({
    reducer: rootReducer,
    middleware: customizedMiddleware
  })
  
  export default store