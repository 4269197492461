import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
// import { InlineDatePicker } from "material-ui-pickers";
import WithWebService from '../utils/WebServiceInterface'
import { formatNumber, capitalize } from "../utils/Utilities"
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Moment from 'moment'
import { withRouter } from 'react-router-dom'
import {  MButton } from '../component/common'
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import PopupPoDetailChangeFlow from '../component/scene/PopupPoDetailChangeFlow'
import { connect } from 'react-redux'
import { roles, keys } from '../utils/Constants';
import { getCookie } from '../utils/Utilities'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,

    },
    rootTable: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tableRowOrange: {
        backgroundColor: 'rgba(255, 162, 0,0.5)'
    },
    tableRowRed: {
        backgroundColor: 'rgba(255, 43, 0,0.5)'
    },
    tableCellHeader:{
        fontSize:14
    },
    header: {
        color: theme.palette.primary.dark,

    },
    bootstrapRoot: {
        width: 150,

    },
    margin: {
        margin: 0,
    },
    bootstrapInput: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 14,
        width: 'auto',
        height: 10,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    bootstrapFormLabel: {
        fontSize: 14,
    },
    date: {
        width: 150,
        display: 'flex',
        alignItems: 'center'
    },
    select: {
        height: 20
    },
    buttonClose: { margin: 16 },
    filed: {
        marginTop: 16
    },
    cellTable: {
        cursor: 'pointer',
    }
});



let delaySearch = null

class TaskView extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            myTask: [],
            bankList: [],
            bgTypeList: [],
            statusList: [{ value: 'createByMe', label: 'Create By Me' }, { value: 'assignToMe', label: 'Assign To Me' }, { value: 'onGoing', label: 'On Going' }],
            requestNo: '',
            requestDate: null,
            vendor: '',
            bank: '',
            bgNo: '',
            bgType: '',
            amount: '',
            status: '',
            effectiveDate: null,
            expiryDate: null,
            remainDate: '',
            page: 0,
            rowsPerPage: 10,
            isDialogCondition: false,
            conditionCancel: null,
            requestNoSelect:'',
            poNumberSelect:'',
            role: '',
            account: {}
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    componentWillMount() {
        if(getCookie(keys.USERDETAIL)){
            // console.log("############ MainView.js (getCookie(keys.USERDETAIL)) : ", getCookie(keys.USERDETAIL) );
            // this.setState({ user: JSON.parse(getCookie(keys.USERDETAIL)) })
            const checkUser = JSON.parse(getCookie(keys.USERDETAIL))
            this.setState({ account: checkUser })
            console.log("############ TaskView.js (checkUser) : ", checkUser );
        }

        Promise.all([this.getBankList(), 
                    //  this.getDocTypeList(), 
                    //  this.getDataConditionCancel()
                    ]).then(response => {

        }).catch(error => {

        })
    }

    search() {

        if (delaySearch) {
            clearTimeout(delaySearch)
        }

        delaySearch = setTimeout(() => {
            if (this.props.onFilter != null) {
                this.props.onFilter(this.getParameter())
            }

        }, 300);
    }



    getParameter() {

        const parameter = {
            "myTask": this.props.type,
            "status": this.state.status,
            "requestNo": this.state.requestNo,
            "requestDate": (this.state.requestDate != "" && this.state.requestDate != null) ? Moment(this.state.requestDate).format('DD/MM/YYYY') : '',
            "vendor": this.state.vendor,
            "bgNo": this.state.bgNo,
            "bank": this.state.bank,
            "bgType": this.state.bgType,
            "amount": this.state.amount,
            "effectiveDate": (this.state.effectiveDate != "" && this.state.effectiveDate != null) ? Moment(this.state.effectiveDate).format('DD/MM/YYYY') : '',
            "expiryDate": (this.state.expiryDate != "" && this.state.expiryDate != null) ? Moment(this.state.expiryDate).format('DD/MM/YYYY') : ''
        }

        return parameter
    }

    handleChangeTextField(e) {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.search()
        })
    }

    render() {
        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;
        
        return (
            <div>
                <div className={classes.root}>
                    <div className={classes.rootTable}>
                        <Table className={classes.table}>
                            <TableHead className={classes.header}>
                                {this.props.type === 'MY_TASK' ? <TableRow >
                                    <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeader}>เลขที่เอกสาร</TableCell>
                                    <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">รหัสลูกค้า</TableCell>
                                    <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">สัญญา</TableCell>
                                    <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">มูลค่าหลักประกัน</TableCell>
                                    <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">สร้างเมื่อ</TableCell>
                                    <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">รอดำเนินการ</TableCell>
                                    <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">สถานะ</TableCell>

                                </TableRow>: 
                                    <TableRow >
                                        <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeader}>เลขที่เอกสาร</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">รหัสลูกค้า</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">รายละเอียดสัญญา</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">มูลค่าหลักประกัน</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันหมดอายุ</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">สถานะ</TableCell>

                                    </TableRow>}
                               
                            </TableHead>

                            <TableBody>
                                {
                                    this.props.type === 'MY_TASK' ? this.props.task.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                            <TableRow
                                                className={classes.cellTable}
                                                hover
                                                key={row.requestNo}
                                                onClick={() => {
                                                    if (this.props.action === 'cancel') {
                                                        // this.setState({ isDialogCondition: true, 
                                                        //     poNumberSelect: row.poNumber, 
                                                        //     requestNoSelect: row.requestNo })

                                                        this.setState({ poNumberSelect: row.poNumber, })
                                                        this.showDialogFlow(row.requestNo)


                                                    } else if (this.props.action === 'amend') {
                                                        this.setState({ poNumberSelect: row.poNumber, })
                                                        this.showDialogFlow(row.requestNo)
                                                    } else if (this.props.action === 'decrease') {
                                                        this.setState({ poNumberSelect: row.poNumber, })
                                                        this.showDialogFlow(row.requestNo)
                                                    } else {
                                                        if (this.state.account.role === roles.ARF || row.flowName.toLowerCase() === 'paper') {
                                                            this.props.history.push(`/paper/request/${row.requestNo}`)
                                                        }else{
                                                            this.props.history.push(`/request/${row.flowName === 'Return' ? 'cancel' : row.flowName.toLowerCase()}/${row.requestNo}`)
                                                        }
                                                    }
                                                }}>
                                            <TableCell style={{ padding: 8 }} align="center">
                                                <div>
                                                    <div>
                                                        {row.requestNo}
                                                    </div>
                                                    <div>
                                                        {row.flowName}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ padding: 8 }} align="left">
                                               <div>
                                                    <div>
                                                        {`${row.sapCompanyId}`}
                                                    </div>
                                                    <div>
                                                        {`${row.companyNameTh}`}
                                                    </div>
                                               </div>
                                            </TableCell>
                                            <TableCell style={{ padding: 8 }} align="left">
                                                <div>
                                                    <div>
                                                        {`${row.contractDocName}`}
                                                    </div>
                                                    <div>
                                                        {`${row.contractNumber}`}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ padding: 8 }} align="center">{formatNumber(row.amount)}</TableCell>
                                            <TableCell style={{ padding: 8 }} align="center">{row.requestDate}</TableCell>
                                            <TableCell style={{ padding: 8 }} align="center">{row.pending}</TableCell>
                                            <TableCell style={{ padding: 8 }} align="center">
                                                <div style={{ padding: 8, backgroundColor: row.statusColor,borderRadius:10,color:'white'}}>
                                                    {row.status}
                                                </div>
                                            </TableCell>
                                            </TableRow>
                                        )) : this.props.task.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                            <TableRow
                                                className={classes.cellTable}
                                                hover
                                                key={row.requestNo}
                                                onClick={() => {
                                                    if (this.props.action === 'cancel') {
                                                        // this.setState({ isDialogCondition: true, 
                                                        //     poNumberSelect: row.poNumber, 
                                                        //     requestNoSelect: row.requestNo })

                                                        this.setState({ poNumberSelect: row.poNumber, })
                                                        this.showDialogFlow(row.requestNo)


                                                    } else if (this.props.action === 'amend') {
                                                        this.setState({ poNumberSelect: row.poNumber, })
                                                        this.showDialogFlow(row.requestNo)
                                                    } else if (this.props.action === 'decrease') {
                                                        this.setState({ poNumberSelect: row.poNumber, })
                                                        this.showDialogFlow(row.requestNo)
                                                    } else if (this.props.action === 'paper') {
                                                        this.props.history.push(`/paper/request/${row.requestNo}`)
                                                    }  else {
                                                        this.props.history.push(`/request/${row.flowName === 'Return' ? 'cancel' : row.flowName.toLowerCase()}/${row.requestNo}`)
                                                    }

                                                }}>
                                                <TableCell style={{ padding: 8 }} align="center">{row.requestNo}</TableCell>
                                                <TableCell style={{ padding: 8 }} align="left">
                                                    <div>
                                                        <div>
                                                            {row.sapCustomerId}
                                                        </div>
                                                        <div>
                                                            {row.companyName}
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ padding: 8 }} align="center">{row.contractName}</TableCell>
                                                <TableCell style={{ padding: 8 }} align="center">{formatNumber(row.guaranteeAmount)}</TableCell>
                                                <TableCell style={{ padding: 8 }} align="center">{row.expiryDate}</TableCell>
                                                <TableCell style={{ padding: 8 }} align="center">{row.action}</TableCell>
                                                
                                            </TableRow>
                                        ))
                                }

                            </TableBody>


                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.task.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />

                </div>
                {this.state.conditionCancel != null && this.renderDialogCondition()}

                <PopupPoDetailChangeFlow onRef={ref =>{
                    this.dialogPoDetailFlow = ref
                }} 
                    flow={capitalize(this.props.action) }
                onSubmit={(requestNo) =>{
                    this.props.history.push(`/request/${this.props.action}/${requestNo}`)
                }}/>
            </div>
        )

    }

    showDialogFlow(requestNo){
        this.dialogPoDetailFlow.showDialog(requestNo)
    }

    renderDialogCondition() {

        const { classes } = this.props;
        return (
            <Dialog
                open={this.state.isDialogCondition}
                fullWidth={true}
                maxWidth="sm"
                onClose={() => {

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <DialogTitle id="alert-dialog-title">{this.state.conditionCancel.subject}</DialogTitle>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <IconButton className={classes.buttonClose} onClick={this.handelDialogCondition.bind(this)}>
                            <Icon>
                                close
                            </Icon>
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <DialogContent>
                    <br/>
                    {this.state.conditionCancel.condition.map((element,index) => {
                        return (
                            <Grid container key={element.conditionName} alignItems="center">
                                <Grid xs item>
                                    <Typography>{`${index + 1}. ${element.conditionName}`}</Typography>
                                </Grid>
                                <Grid item>
                                    <Checkbox
                                        checked={element.action === 'Y'}
                                        onChange={this.handleChangeCondition(element.conditionName,index)}
                                        value="checkedA"
                                        color="secondary"
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                    <DialogActions>
                        <div className={classes.filed}>
                            <MButton
                                onClick={this.submitCondition.bind(this)}
                                isLoading={this.state.isLoading}
                                variant="contained"
                                color="secondary" >
                                SAVE
                        </MButton>

                        </div>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        )
    }

    submitCondition() {

        this.setState({isDialogCondition:false})
        this.showDialogFlow(this.state.requestNoSelect)
    }

    handelDialogCondition() {
        this.setState({ isDialogCondition: false })
    }

    handleChangeCondition = (name,index) => event => {

        const conditionCancel = this.state.conditionCancel 
        conditionCancel.condition[index].action = event.target.checked ? 'Y':'N'
        this.setState({ conditionCancel});
    }

    getDataConditionCancel() {

        return new Promise((resolve, reject) => {

            this.props.webService.conditionCancel().then(response => {
              
                this.setState({ conditionCancel: response.data.data })

                resolve()

            }).catch(error => {
               
                reject()
            })
        })

    }

    getBankList() {

        return new Promise((resolve, reject) => {

            this.props.webService.getBankList().then(response => {
             
                const bankList = []
                response.data.data.bankList.forEach(element => {
                    bankList.push({
                        value: element.bankCode,
                        label: element.bankName
                    })
                });
                this.setState({ bankList: bankList })

                resolve()

            }).catch(error => {
               
                reject()
            })
        })

    }
    getDocTypeList() {

        return new Promise((resolve, reject) => {

            this.props.webService.getDocTypeList().then(response => {
             
                const docTypes = []
                response.data.data.pageList.forEach(element => {
                    docTypes.push({
                        value: element.docTypeCode,
                        label: element.docTypeName
                    })
                });
                this.setState({ bgTypeList: docTypes })
                resolve()

            }).catch(error => {
            
                reject()
            })
        })

    }
}

const mapStateToProps = ({ account }) => {

    return {
        account: account.user,
    }
}

export default withRouter(WithWebService(connect(mapStateToProps)(withStyles(styles)(TaskView))))