import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const configData = {
  image: "",
  name: {
    first: '',
    last: ''
  },
  jwt : {
    access_token : ''
  }
}

const account = createSlice({
  name: "account",
  initialState: configData,
  reducers: {
    updateAccount: (state, action) => {
      state = { ...state,...action.payload };
      return state;
    },
    setDefault: (state) => {
      state = { ...state,...configData };
      return state;
    },
  },
});
export const { updateAccount, setDefault } = account.actions
export default account;