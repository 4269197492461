import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './redux/store'
// import { createStore, applyMiddleware, compose } from 'redux'
// import thunkMiddleware from 'redux-thunk';
// import reducers from './redux/reducers'
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import { PersistGate } from 'redux-persist/integration/react'



import 'core-js/es/array';
import 'core-js/es/object';

import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';

// const persistConfig = {
//     key: 'root',
//     storage,
// }


// const persistedReducer = persistReducer(persistConfig, reducers)
// const store = createStore(persistedReducer, undefined, compose(applyMiddleware(thunkMiddleware)))
// const persistor = persistStore(store)

const render = Component => {
    return ReactDOM.render(
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
                <App />
            {/* </PersistGate> */}
        </Provider>, document.getElementById('root'));
};

render(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
