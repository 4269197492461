import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { TitleSection } from "../component/common"
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import WithWebService from '../utils/WebServiceInterface'
import { MenuItem, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { formatNumber, } from "../utils/Utilities"

const styles = theme => ({

    tab: { fontSize: 12, backgroundColor: '#D6D6D6', height: 70, color: theme.palette.text.main, },
    tabActive: { fontSize: 12, backgroundColor: '#fff', height: 70, color: theme.palette.secondary.main },
    rootTab: { borderWidth: 1, borderColor: 'red' },
    content: {
        padding: 36
    },
    containerTab: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    title: { fontSize: 24, },
    layoutField: { margin: 80 },
    buttonSubmit: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 24
    },
    buttonSearch: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
    },
    rootTable: {
        overflowX: 'auto'
    },
    table: {
        minWidth: 1200,

    },
    textButton: {
        marginTop: 16
    },
    customButton: {
        backgroundColor: '#07C52A'
    },
    contentCard: {
        padding: 24
    },
    tableRowOrange: {
        backgroundColor: 'rgba(255, 162, 0,0.5)'
    },
    tableRowRed: {
        backgroundColor: 'rgba(255, 43, 0,0.5)'
    },
    tableCellHeader: {
        fontSize: 14
    },
    header: {
        color: theme.palette.primary.dark,

    },
    containerHeaderdata: {
        padding: 24
    },
    titleData: {
        color: theme.palette.common.textTitleRequest,
        fontSize: 14
    },
    valueData: {
        color: theme.palette.common.textTitle,
        fontSize: 14,
        overflowWrap: 'break-word',
        wordBreak: 'break-all'
    },


})


class CreditBgDetailVC extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            myTask: [],
            isLoading: false,
            docType: "",
            page: 0,
            rowsPerPage: 10,
            companyName: '',
            customerTax: '',
            bgNo: ''
        }
    }

    componentWillMount() {

        this.historyDocument()
    }



    render() {

        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <div className={classes.content}>

                <Card>
                    <div className={classes.contentCard}>
                        <TitleSection title="ส่วนที่ 1 ข้อมูลหนังสือสัญญา" />
                        <div className={classes.containerHeaderdata}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Typography className={classes.titleData}>ชื่อลูกค้า</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography className={classes.valueData}>{this.state.companyName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Typography className={classes.titleData}>เลขประจำตัวผู้เสียภาษี</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography className={classes.valueData}>{this.state.customerTax}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                    </div>

                </Card>

                <br />

                {this.state.myTask.length > 0 &&
                    <Card>
                        <div className={classes.contentCard}>
                            <TitleSection title={`เลขที่เอกสาร : ${this.state.bgNo}`} />
                            <div className={classes.containerHeaderdata}>
                                <div className={classes.rootTable}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.header}>
                                            <TableRow >
                                                <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeader}>ลำดับ</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันสร้างเอกสาร</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">สถานะเอกสาร</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">เลขที่สัญญา</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">ชื่อหนังสือ</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วัตถุประสงค์</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันที่สัญญา</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">จำนวนเงินค้ำประกัน</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันที่มีผลบังคับใช้</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันที่สิ้นสุด</TableCell>
                                            </TableRow>

                                        </TableHead>

                                        <TableBody>
                                            {this.state.myTask.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={row.requestNo}>
                                                        <TableCell style={{ padding: 8 }} align="center">
                                                            {row.revision}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">
                                                            {row.createDate}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">
                                                            {row.statusBG}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="left">
                                                            {row.contractNumber}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="left">
                                                            {row.contractDocName}

                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">{row.contractObjectiveName}</TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">{row.contractDate}</TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">
                                                            {formatNumber(row.guaranteeAmount)}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">
                                                            {row.collateralEnforceDate}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">
                                                            {row.collateralEndDate}
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            })}

                                        </TableBody>


                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={this.state.myTask.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </div>

                        </div>
                    </Card>
                }

            </div>
        )
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: this.state.page, rowsPerPage: event.target.value });
    };


    historyDocument() {

        const parameter = { requestNo: this.props.match.params.requestNo, documentId: this.props.match.params.documentId }
        this.setState({ isLoading: true })


        this.props.webService.historyDocument(parameter).then(response => {

            this.setState({
                isLoading: false,
                myTask: response.data.data.historyList,
                customerTax: response.data.data.customerTax,
                companyName: response.data.data.companyName,
                bgNo: response.data.data.bgNo
            })

            console.log(this.state.myTask)


        }).catch(error => {
            this.setState({ isLoading: false })
        })
    }


}





export default WithWebService(withRouter(withStyles(styles)(CreditBgDetailVC)))