import React from 'react'
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const styles = theme => ({

    fabProgress: {
        color: "#2d9714",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -14,
        marginLeft: -14,
        zIndex: 1,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    buttonProgress: {
        color: "#2d9714",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonRoot: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 8,
        color: '#2d9714'
    },
    textButtonSave: {
        marginTop: 8,
        color: '#0288ff'
    },
    textButtonReject: {
        marginTop: 8,
        color: '#f66056'
    },
    fabProgressReject: {
        color: "#f66056",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -14,
        marginLeft: -14,
        zIndex: 1,
    },
    fabProgressSave: {
        color: "#0288ff",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -14,
        marginLeft: -14,
        zIndex: 1,
    },
    buttonSearch: {
        color: 'white',
        backgroundColor: '#0288ff',
        '&:hover': {
            backgroundColor: '#006AC7',
        },
    },
    buttonSubmit:{
        backgroundColor:'#08E222',
        backgroundPosition: 'center',
        color:'white'
    },
    buttonSave: {
        backgroundColor: '#0288ff',
        backgroundPosition: 'center',
        color:'white'
    },
    buttonApprove: {
        backgroundColor: '#08E222',
        backgroundPosition: 'center',
        color:'white'
    },
    buttonReject: {
        backgroundColor: '#f66056',
        backgroundPosition: 'center',
        color:'white'
    },
    buttonPrint: {
        backgroundColor: '#0288ff',
        backgroundPosition: 'center'
    }
})

const SubmitButton = (props) => {

    const { classes, children, isLoading,disabled, ...other} = props;

    return (
        <div className={classes.buttonRoot} >
            <Button classes={{ root: classes.buttonSubmit }} disabled={disabled} {...other} >
                <Icon className={classes.leftIcon}>send</Icon>
                                    Submit
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
        </div>
    )
}

const SaveButton = (props) => {

    const { classes, children, isLoading,disabled, ...other } = props;

    return (
        <div className={classes.buttonRoot} >
            <Button classes={{ root: classes.buttonSave }} disabled={disabled} {...other} >
                <Icon className={classes.leftIcon}>save</Icon>
                Save
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
        </div>
    )
}


const ApproveButton = (props) => {

    const { classes, children, isLoading,disabled, ...other } = props;

    return (
        <div className={classes.buttonRoot}>
            <Button classes={{ root: classes.buttonApprove }} disabled={disabled} {...other} >
                <Icon className={classes.leftIcon}>check</Icon>
                {props.label}
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>

        </div>
    )
}

const RejectButton = (props) => {

  
    const { classes, children, isLoading, disabled, ...other } = props;

    return (
        <div className={classes.buttonRoot}>
            <Button classes={{ root: classes.buttonReject }} disabled={disabled} {...other} >
                <Icon className={classes.leftIcon}>close</Icon>
                {props.label}
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>

        </div>
    )
}

const PrintButton = (props) => {

    const { classes, } = props;

    return (
        <div className={classes.buttonRoot}>
            <Fab
                size="large"
                disabled={props.disabled}
                onClick={props.onClick}
                classes={{ root: classes.buttonPrint }}
                color="secondary">
                <Icon>print</Icon>
                {props.isLoading && <CircularProgress size={68} className={classes.fabProgressSave} />}
            </Fab>
            <Typography align="center" variant="button" className={classes.textButtonSave}>{props.label}</Typography>

        </div>
    )
}

const ButtonPrint = withStyles(styles)(PrintButton)
const ButtonReject = withStyles(styles)(RejectButton)
const ButtonApprove = withStyles(styles)(ApproveButton)
const ButtonSubmit = withStyles(styles)(SubmitButton)
const ButtonSave = withStyles(styles)(SaveButton)

export { ButtonSubmit, ButtonSave, ButtonApprove, ButtonReject, ButtonPrint}