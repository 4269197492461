import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EncryptStorage } from 'encrypt-storage';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import LoginSlice from '../../redux/feature/loginSlice'
import accountSlice from "../../redux/feature/accountSlice"
import userSlice from "../../redux/feature/userSlice"
import { MButton } from "./MButton"
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import "../../css/login.css"
import { setCookie } from '../../utils/Utilities'
import { keys } from '../../utils/Constants'
import axios from 'axios'
import * as AuthApi from '../../apis/AuthApi'
import GlobalUtil from "../../util/GlobalUtil";

//env
import { env } from "../../env"

const useStyles = makeStyles(theme => ({

  root: {
    height: '100vh',
  },
  buttonLogin: {
    marginTop: 24
  },
  textHello: {
    color: 'white',
    fontSize: 28,
    marginLeft: 16,
    marginRight: 16,
    fontWeight: 'bold',

  },
  fabProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    zIndex: 1,
  },
  textWelcome: {
    color: 'white',
    fontSize: 24,
    marginLeft: 16,
    marginRight: 16,

  },
  textWelcomeDescription: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,

  },
  textOr: {
    color: 'white',
    fontSize: 16,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 36,
  },
  cardLeft: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    height: "50vh",
  },
  logo: {
    width: 90,
    height: "auto",
    objectFit: 'contain'
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function AADLoginComp(props) {
  const classes = useStyles;
  const { tenant, btnName, apps } = props
  const dispatchPage = useDispatch();
  let inStoreReduserLogin = useSelector((state) => {
    return state.login;
  });
  const encryptStorage = new EncryptStorage('secret-key', {
    prefix: '@CLAIM'
  })

  const [isLoadings, setIsLoadings] = React.useState(false)

  const { accounts, instance } = useMsal();
  const login = encryptStorage.getItem('login')

  const handleLoginAAD = () => {
    try {
      tenant.selectApps = apps
      encryptStorage.setItem('loginType', "AAD");
      encryptStorage.setItem('tenant', JSON.stringify(tenant));

      instance.loginRedirect(apps.loginRequest)
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (accounts.length > 0 && !login) {
      setIsLoadings(true)
      autnLogin()
      setIsLoadings(false)
    }
  }, [accounts[0]])
  const autnLogin = async () => {
    try {

      // console.log("############ AADLoginComp.js autnLogin (accounts[0]) : ", JSON.stringify(accounts[0]) );
      // window.localStorage.setItem("accountsB2C", JSON.stringify(accounts[0]));

      if(encryptStorage.getItem('loginType') === "AAD"){
        // dispatchPage(userSlice.actions.userDetailSlice(true))
        let tenant = encryptStorage.getItem('tenant')
        let userApp = {}

        await instance
          .acquireTokenSilent({
            ...apps.loginRequest,
            account: accounts[0],
          })
          .then(
            async (response) => {
              console.log("###### AADLoginComp.js (response) : ",response);
              const data = {
                access_token: response.accessToken,
                client_id: tenant.selectApps.client_id,
                tenant_id: tenant.tenant_id,
                type: "CMS"
              }
              userApp = await AuthApi.doserviceAuthAD(data, response.accessToken)
              console.log('userApp AD', userApp)
              userApp = JSON.parse(GlobalUtil.decodeUFT8(userApp.resp_parameters[0].value));
              console.log(" userApp in AADLoginComp.js =>",userApp);

              let parameter = JSON.stringify({
                "username": userApp.email
              });
              console.log("###### AADLoginComp.js (parameter) : ", parameter );
      
              let config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: `${env.REACT_APP_API_ENDPOINT}/credit/getUserProfileV3` ,
                  headers: { 
                    'Content-Type': 'application/json'
                  },
                  data : parameter
              };
      
              await axios.request(config)
                  .then( async (response) => {
                      console.log("###### AADLoginComp.js (response.data) : ", response.data);
                      encryptStorage.setItem(keys.TOKEN, response.data.token)
                      setCookie(keys.TOKEN, response.data.token, 1)
                      // setCookie(keys.USERDETAIL, JSON.stringify(response.data.data) , 1)
                      // dispatchPage(accountSlice.actions.userAccount(response.data.data[0]))
                      // dispatchPage(userSlice.actions.userDetailSlice(JSON.stringify(response.data.data)))
                      if(response.data){
                        let values = JSON.parse(JSON.stringify(response.data.data))

                        const parameterVerify = {
                            "username": values.userName,
                            "password": "x"
                        }

                        let configVerify = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `${env.REACT_APP_API_ENDPOINT}/credit/login/verify`,
                            headers: { 
                              'Content-Type': 'application/json'
                            },
                            data : parameterVerify
                        };

                        await axios.request(configVerify)
                            .then((response) => {
                              // encryptStorage.setItem(keys.TOKEN, response.data.token)
                              setCookie(keys.TOKEN, response.data.token, 1)
                              setCookie(keys.USERDETAIL, JSON.stringify(response.data), 1)
                              console.log("###### AADLoginComp.js Verify (response) : ", response);
                            })
                            .catch((error) => {
                                console.log("###### AADLoginComp.js Verify (error) : ", error);
                            });
                      }
                  })
                  .catch((error) => {
                      console.log("###### AADLoginComp.js (error) : ", error);
                  });


            },
            (error) => {
              console.log("###### AADLoginComp.js (error) : ",error);
            }
          );
        dispatchPage(userSlice.actions.userDetailSlice(false))
      }

      

      // console.log(accounts)
      // console.log(JSON.stringify(accounts))
      // console.log("account change")

      if (accounts[0].localAccountId) {
        let login = inStoreReduserLogin
        if (!login.accountLogin) {
          let payload = {
            ...login,
            accountLogin: accounts[0].username
          }
          dispatchPage(LoginSlice.actions.updateLogin(payload));
          console.log("username => ", accounts[0].username)
          encryptStorage.setItem('userName', accounts[0].username);
          encryptStorage.setItem('login', true);
        }

      }
    } catch (error) {
      console.log(error)
    }
  }
  return <>
    <Grid container spacing={3}>
      <Grid item xs={12} sm>
        <MButton data-testid={`btn-login-${apps.client_id}`} id="login_ad_btn" variant="contained" fullWidth color="secondary" className={classes.buttonLogin} onClick={() => handleLoginAAD()}>LOGIN PTT</MButton>
        {isLoadings && (
          <>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={true}
                // onClose={handleCloseIdCard}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent style={{ overflow: 'hidden' }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={12} style={{ padding: "9rem" }} >
                      <CircularProgress  color="secondary" disableShrink  size={300}  />
                    </Grid>
                  </Grid>
                </DialogContent>
            </Dialog>
          </>
        )}
      </Grid>
    </Grid>

  </>
}