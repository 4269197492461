import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { TitleSection } from "../component/common"
import Grid from '@material-ui/core/Grid';

import WithWebService from '../utils/WebServiceInterface'

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { formatNumber, } from "../utils/Utilities"

const styles = theme => ({

    tab: { fontSize: 12, backgroundColor: '#D6D6D6', height: 70, color: theme.palette.text.main, },
    tabActive: { fontSize: 12, backgroundColor: '#fff', height: 70, color: theme.palette.secondary.main },
    rootTab: { borderWidth: 1, borderColor: 'red' },
    content: {
        padding: 36
    },
    containerTab: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    title: { fontSize: 24, },
    layoutField: { margin: 80 },
    buttonSubmit: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 24
    },
    buttonSearch: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 16
    },
    customButton: {
        backgroundColor: '#07C52A'
    },
    contentCard: {
        padding: 24
    },
    table: {
        minWidth: 700,
    },
    tableRowOrange: {
        backgroundColor: 'rgba(255, 162, 0,0.5)'
    },
    tableRowRed: {
        backgroundColor: 'rgba(255, 43, 0,0.5)'
    },
    tableCellHeader: {
        fontSize: 14
    },
    header: {
        color: theme.palette.primary.dark,

    },
    containerHeaderdata: {
        padding: 24
    },
    titleData: {
        color: theme.palette.common.textTitleRequest,
        fontSize: 14
    },
    valueData: {
        color: theme.palette.common.textTitle,
        fontSize: 14,
        overflowWrap: 'break-word',
        wordBreak: 'break-all'
    },


})


class RequestLogView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            myTask: [],
            isLoading: false,
            docType: "",
            page: 0,
            rowsPerPage: 10,
            companyName: '',
            customerTax: '',
            bgNo: ''
        }
    }

    componentWillMount() {

        this.history()
    }



    render() {

        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <div className={classes.content}>

            
                {this.state.myTask.length > 0 &&
                    <Card>
                        <div className={classes.contentCard}>
                            <TitleSection title={`ประวัติ`} />
                            <div className={classes.containerHeaderdata}>
                                <div className={classes.rootTable}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.header}>
                                            <TableRow >
                                                <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} className={classes.tableCellHeader}>ชื่อ</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} >กิจกรรม</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} >สถานะ</TableCell>
                                                <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันที่</TableCell>

                                            </TableRow>

                                        </TableHead>

                                        <TableBody>
                                            {this.state.myTask.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}>
                                                        <TableCell style={{ padding: 8 }} >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} >
                                                            {row.activities}
                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} >
                                                            {row.status}

                                                        </TableCell>
                                                        <TableCell style={{ padding: 8 }} align="center">{row.actionDate}</TableCell>
                                                      
                                                    </TableRow>
                                                )
                                            })}

                                        </TableBody>


                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={this.state.myTask.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />

                            </div>

                        </div>
                    </Card>
                }

            </div>
        )
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: this.state.page, rowsPerPage: event.target.value });
    };


    history() {

        const parameter = { requestNo: this.props.match.params.requestNo, }
        this.setState({ isLoading: true })


        this.props.webService.requestLog(parameter).then(response => {

            this.setState({
                isLoading: false,
                myTask: response.data.data.actionLog,
            })


        }).catch(error => {
            this.setState({ isLoading: false })
        })
    }


}





export default WithWebService(withRouter(withStyles(styles)(RequestLogView)))