import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import {  MTextField, ButtonSearch, MSelect } from "../component/common"
import Grid from '@material-ui/core/Grid';

import Icon from '@material-ui/core/Icon';
import WithWebService from '../utils/WebServiceInterface'
import Container from '@material-ui/core/Container';
import { MenuItem, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { formatNumber,  } from "../utils/Utilities"
import ExportExcelSearchDocument from '../component/scene/ExportExcelSearchDocument'
import ReactToPrint from "react-to-print";

const isIE = /*@cc_on!@*/false || !!document.documentMode;

const styles = theme => ({

    tab: { fontSize: 12, backgroundColor: '#D6D6D6', height: 70, color: theme.palette.text.main, },
    tabActive: { fontSize: 12, backgroundColor: '#fff', height: 70, color: theme.palette.secondary.main },
    rootTab: { borderWidth: 1, borderColor: 'red' },
    content: {
        padding: 36,
        
    },
    containerTab: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8
    },
    title: { fontSize: 24, },
    layoutField: { margin: 80 },
    buttonSubmit: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 24
    },
    buttonSearch: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 16
    },
    customButton: {
        backgroundColor: '#07C52A'
    },
    contentCard: {
        padding: 24
    },
    table: {
        minWidth: 700,
    },
    tableRowOrange: {
        backgroundColor: 'rgba(255, 162, 0,0.5)'
    },
    tableRowRed: {
        backgroundColor: 'rgba(255, 43, 0,0.5)'
    },
    tableCellHeader: {
        fontSize: 14
    },
    header: {
        color: theme.palette.primary.dark,

    },
    containerPdf: {
        padding: isIE ? 10 : 90,
        paddingBottom: isIE ? 10 : 30,
    },



})


class SearchDocumentView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            myTask: [],
            isLoading: false,
            collateralModelList: [],
            docType: "",
            page: 0,
            rowsPerPage: 10,
            data:{}
        }
    }

    componentWillMount() {
        this.getCollateralModel()
    }

    getCollateralModel() {

        return new Promise((resolve, reject) => {

            this.props.webService.getCollateralModel().then(response => {
              
                this.setState({ collateralModelList: response.data.data.collateralModelList })
                resolve()

            }).catch(error => {
              
                reject()
            })
        })

    }

    render() {

        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <div className={classes.content}>

                <Card>
                    <br />
                    <br />
                    <div>
                        <Container maxWidth="md">
                            <Grid item xs>

                                <Formik
                                    initialValues={{
                                        bgNo: '',
                                        contractName: '',
                                        contractNumber: '',
                                        customerTax: '',
                                        companyName: '',
                                        collateralTypeCode: '',
                                        collateralModelCode:''
                                    }}
                                    validate={values => {
                                        const requires = []
                                        let errors = {};
                                        requires.forEach(field => {
                                            if (!values[field]) {
                                                errors[field] = 'Required';
                                            }
                                        });


                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        this.findDocument(values)
                                    }}
                                    render={({ submitForm, isSubmitting, values, setFieldValue, handleChange }) => (
                                        <Form>
                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="companyName"
                                                        fullWidth
                                                        placeholder="กรอกชื่อบริษัทลูกค้า"
                                                        label="กรอกชื่อบริษัทลูกค้า"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="bgNo"
                                                        fullWidth
                                                        placeholder="เลขที่หนังสือค้ำประกัน"
                                                        label="กรอกเลขที่หนังสือค้ำประกัน"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        type="text"
                                                        name="customerId"
                                                        fullWidth
                                                        label="รหัสลูกค้า"
                                                        placeholder="รหัสลูกค้า"
                                                        margin="normal"
                                                        component={MTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        fullWidth
                                                        type="text"
                                                        name="collateralModelCode"
                                                        label="ประเภทหนังสือค้ำประกัน"
                                                        options={this.state.collateralModelList}
                                                        component={MSelect}
                                                    >
                                                        {this.state.collateralModelList.map(option => (
                                                            <MenuItem key={option.collateralModelCode} value={option.collateralModelCode}>
                                                                {option.collateralModelName}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                            </Grid>



                                            <div className={classes.buttonSearch}>
                                                <ButtonSearch
                                                    label="Search"
                                                    disabled={this.state.isLoading}
                                                    isLoading={this.state.isLoading}
                                                    onClick={submitForm} />
                                            </div>

                                            <br />
                                            <br />
                                        </Form>
                                    )}
                                />
                            </Grid>
                        </Container>
                    </div>

                </Card>
                <br />

                {this.state.myTask.length > 0 &&
                    <Card>
                        <div className={classes.contentCard}>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography paragraph variant="subheading" className={classes.title}>ผลการค้นหา</Typography>
                            </Grid>
                            <Grid item>
                                <ExportExcelSearchDocument dataSet={this.state.myTask} />
                            </Grid>
                        </Grid>
                            
                            <Divider />
                            <div className={classes.rootTable}>
                                <Table className={classes.table}>
                                    <TableHead className={classes.header}>
                                        <TableRow >
                                        <TableCell style={{ padding: 8, paddingBottom: 16, paddingTop: 16 }} align="center" className={classes.tableCellHeader}>เลขที่หนังสือค้ำประกัน</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">ประเภทหนังสือค้ำประกัน</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">ลูกค้า</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">รายละเอียดสัญญา</TableCell>
                                        <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันที่เอกสาร</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">มูลค่าหลักประกัน</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center">วันหมดอายุ</TableCell>
                                            <TableCell style={{ padding: 8 }} className={classes.tableCellHeader} align="center"></TableCell>

                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {this.state.myTask.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}>
                                                    <TableCell style={{ padding: 8 }} align="center">{row.bgNo} </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">{`${row.requestType}`}</TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                        <div>
                                                            <div>
                                                                {`${row.sapCustomerId}`}
                                                            </div>
                                                            <div>
                                                                {`${row.companyNameTh}`}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{ padding: 8 }} align="left">{`${row.contractDocName}`}</TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">{row.requestDate}</TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">{formatNumber(row.amount)}</TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">{row.expiryDate}</TableCell>
                                                    <TableCell style={{ padding: 8 }} align="center">
                                                       <Grid container>
                                                            <Grid item>
                                                                <Grid container direction="column">
                                                                    <Grid item >
                                                                        <IconButton disabled>
                                                                            <Icon style={{ color: 'red' }}>alarm</Icon>
                                                                        </IconButton>
                                                                        
                                                                   </Grid>
                                                                    <Grid item>
                                                                        
                                                                        <Typography variant="caption" style={{ color: 'red' }}>{`${row.remainDate} days`}</Typography>
                                                                    </Grid>
                                                               </Grid>
                                                            </Grid>
                                                            {row.requestType.toLowerCase() != 'paper' && <Grid item>
                                                                <div>
                                                                    <ReactToPrint
                                                                        ref={(ref) => {
                                                                            this.print = ref
                                                                        }}
                                                                        trigger={() => <a href="#">

                                                                        </a>}
                                                                        content={() => this.printEbgPTTTH}
                                                                    />

                                                                    <Grid container direction="column">
                                                                        <Grid item >
                                                                            <IconButton onClick={() => {
                                                                                this.setState({ data: row })
                                                                                setTimeout(() => {
                                                                                    this.print.handleClick()
                                                                                }, 500);

                                                                            }}>
                                                                                <Icon>find_in_page</Icon>
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="caption">{`View`}</Typography>
                                                                        </Grid>
                                                                    </Grid>


                                                                </div>
                                                            </Grid>}
                                                           
                                                            {row.requestType.toLowerCase() != 'paper' && <Grid item>
                                                                <Grid container direction="column">
                                                                    <Grid item>
                                                                        <div>
                                                                            <IconButton onClick={() => {
                                                                                this.props.history.push(`/paper/detail/${row.requestNo}/${row.documentId}`)
                                                                            }}>
                                                                                <Icon>restore</Icon>
                                                                            </IconButton>

                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="caption">History</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>}
                                                            
                                                       </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>


                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={this.state.myTask.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </div>
                    </Card>
                }

                {this.state.data.collateralModelCode === 'PTTPLC_MER_001_TH' && this.renderPrintEbgPTTTH()}
                {this.state.data.collateralModelCode === 'PTTPLC_MER_001_EN' && this.renderPrintEbgPTTEN()}


            </div>
        )
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: this.state.page, rowsPerPage: event.target.value });
    };

    renderPrintEbgPTTTH() {

        const { classes } = this.props;
        const data = this.state.data

        return (
            <div style={{ display: 'none' }}>
                <div className={classes.containerPdf} ref={ref => {
                    this.printEbgPTTTH = ref
                }}>
                    

                    <Typography align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>
                        หนังสือค้ำประกันอิเล็กทรอนิกส์ของธนาคาร
                    </Typography>
                    <Typography paragraph align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>
                        (หลักประกันสัญญา)
                    </Typography>
                    <Typography align="right" style={{ fontSize: 14 }}>
                        {`${data.bankName} ${data.issuerTaxID}`}
                    </Typography>
                    <br/>
                    <Typography align="center" style={{ fontSize: 14 }}>
                        {`${data.issueDate}`}
                    </Typography>
                    <br />
                    <Typography align="left" style={{ fontSize: 14 }}>
                        {`เลขที่ ${data.lgNumber}`}
                    </Typography>
                    <br />
                    <Typography style={{ fontSize: 14 }} align="justify">
                        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้าพเจ้าธนาคาร ${data.bankName} ${data.issuerTaxID} ขอทำหนังสือค้ำประกันให้ไว้ต่อ ${data.beneficiaryName} ${data.beneficiaryTaxID} (ซึ่งต่อไปในหนังสือค้ำประกันฉบับนี้เรียกว่า “ผู้รับหนังสือค้ำประกัน”) ดังมีข้อความต่อไปนี้`}
                    </Typography>
                    <Typography style={{ fontSize: 14 }} align="justify">
                        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้อ1. ตามที่ ${data.requesterNameTH} ${data.requesterTaxID} (ซึ่งต่อไปในหนังสือค้ำประกันฉบับนี้จะเรียกว่า  “คู่สัญญา”) ได้ตกลงทำ ${data.referenceName} เลขที่ ${data.referenceNo} ลงวันที่ ${data.referenceDate} โดยมีวัตถุประสงค์ ${data.referenceDescription} ซึ่งจะต้องวางหลักประกันการปฏิบัติตามเงื่อนไขในสัญญาต่อผู้รับหนังสือค้ำประกัน เป็นเงินไม่เกิน ${formatNumber(data.lgAmount)} บาทนั้น`}
                    </Typography>
                    <Typography style={{ fontSize: 14 }} align="justify">
                        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้าพเจ้ายอมผูกพันตนเป็นผู้ค้ำประกัน คู่สัญญา ต่อ ผู้รับหนังสือค้ำประกัน เพื่อรับผิดอย่างลูกหนี้ร่วมในการชำระเงินตามสิทธิเรียกร้องของผู้รับหนังสือค้ำประกัน เป็นเงินไม่เกิน ${formatNumber(data.lgAmount)} บาท กล่าวคือหากคู่สัญญาไม่ปฏิบัติตามเงื่อนไขในสัญญาที่ทำไว้กับผู้รับหนังสือค้ำประกัน หรือปฏิบัติผิดเงื่อนไขข้อหนึ่งข้อใดของสัญญาดังกล่าว ซึ่งผู้รับหนังสือค้ำประกันมีสิทธิริบหลักประกันและหรือเรียกค่าปรับและหรือค่าเสียหายใดๆจากคู่สัญญาได้แล้ว  ข้าพเจ้าตกลงชำระเงินแทนให้ผู้รับหนังสือค้ำประกัน โดยมิต้องเรียกร้องให้คู่สัญญาชำระก่อน`}
                    </Typography>
                    <Typography style={{ fontSize: 14 }} align="justify">
                        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้อ 2. หนังสือค้ำประกันนี้มีผลใช้บังคับนับตั้งแต่วันที่ ${data.effectiveDate} จนถึงวันที่ ${data.maturityDate}  และไม่ว่ากรณีใดๆ ข้าพเจ้าจะไม่เพิกถอนการค้ำประกันภายในระยะเวลาที่กำหนดไว้`}
                    </Typography>
                    <Typography style={{ fontSize: 14 }} align="justify">
                        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`ข้อ 3.  ข้าพเจ้ายอมรับรู้และยินยอมด้วยในกรณีที่ ผู้รับหนังสือค้ำประกัน ได้ยินยอมให้ผัดหรือผ่อนเวลาการปฏิบัติตามเงื่อนไขในสัญญา ให้แก่คู่สัญญาโดยให้ขยายระยะเวลาการค้ำประกันนี้ออกไปตลอดระยะเวลาที่ได้ขยายไปดังกล่าวข้างต้นด้วย`}
                    </Typography>
                    <br />
                    <Typography style={{ fontSize: 14 }} align="justify">
                        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;{`เพื่อเป็นหลักฐาน ข้าพเจ้าธนาคาร ${data.bankName} ${data.issuerTaxID} โดยผู้มีลงนามข้างท้ายนี้  เป็นผู้มีอำนาจลงนามทำนิติกรรม ซึ่งมีผลผูกพันธนาคารได้ลงลายมือชื่อและได้ประทับตราของธนาคาร (ถ้ามี) ให้ไว้ต่อหน้าพยานเป็นสำคัญ`}
                    </Typography>
                    <br /><br />
                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                        (ลงชื่อ) ………………………………….. ผู้ค้ำประกัน
                    </Typography>
                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                        (………………………………….)
                    </Typography>
                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                        ตำแหน่ง……………………………….
                    </Typography>
                    <br />
                    <Grid container>
                        <Grid item>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (ลงชื่อ) ………………………………….. พยาน
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (………………………………….)
                    </Typography>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (ลงชื่อ) ………………………………….. พยาน
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (………………………………….)
                    </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography style={{ fontSize: 14, }}>
                        *** รับผิดอย่างลูกหนี้ร่วม & ผ่อนเวลาล่วงหน้าได้
                    </Typography>
                    <br/>
                    <Typography style={{ fontSize: 12, }}>
                        {`${data.lgFormNumber}|${data.approveDate}|${data.documentId}`}
                    </Typography>

                </div>
            </div >
        )
    }

    renderPrintEbgPTTEN() {

        const { classes } = this.props;
        const data = this.state.data

        return (
            <div style={{ display: 'none' }}>
                <div className={classes.containerPdf} ref={ref => {
                    this.printEbgPTTTH = ref
                }}>

                    <Typography align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>
                        PERFORMANCE  SECURITY  / MERCHANDISE SECURITY BOND
                    </Typography>
                    <Typography paragraph align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>
                        (Bank as Guarantor)
                    </Typography>
                    <Typography align="right" style={{ fontSize: 14 }}>
                        {`${data.issuerNameEN} ${data.issuerTaxID} Bank`}
                    </Typography>
                    <Grid container>
                        <Grid item>
                            <Typography align="right" style={{ fontSize: 14 }}>
                                {`Document No. ${data.lgNumber}`}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            <Typography align="right" style={{ fontSize: 14 }}>
                                {`Dated ${data.issueDate}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Typography style={{ fontSize: 14 }} align="justify">
                        {`In  accordance  with  the  provision  of  the  Contract  for ${data.referenceName} ${data.referenceNo} ${data.referenceDate} ${data.referenceDescription} (hereinafter  referred  to  as  the  Contract)  the  contents  of  which  have  been  noted  by  us  that  Messrs., ${data.requesterNameEN} ${data.requesterTaxID} (hereinafter  referred  to  as  the Contractor)  has  to  deposit  with ${data.beneNameEN} ${data.beneficiaryTaxID}  (hereinafter  referred  to  as  Beneficiary)  a  Performance  Security  for  the  proper  and  faithful  performance  of  the Contractor under the Contract  in  the  amount  not  exceeding THB ${formatNumber(data.lgAmount)},  we  the  ${data.issuerNameEN} ${data.issuerTaxID} Bank,  as  instructed  by  Contractor,  irrevocably  and  unconditionally  agree  to  guarantee,  as  joint  obligor,  due  and  punctual  performance  of  the  Contractor under  the  Contract  and  hereby  undertake  to  promptly  make  the  payment  to  Beneficiary,  without  whatsoever  right  of  objection  on  our  part  and  without  its  first  claim  to  the  Contractor,  in  the  amount  not exceeding  THB ${formatNumber(data.lgAmount)},  in  the  event  of  the  obligations  of  the Contractor expressed  in  the  above-mentioned  Contract  have not  been  fulfilled  or  become  the default  of  Contract  by  the Contractor,  which  giving Beneficiary  the  right  to  claim  for  penalties,  costs,  losses,  damages  or  any  expenses  for  which  the Contractor becomes  liable  to  Beneficiary.`}
                    </Typography>
                    <br />
                    <Typography style={{ fontSize: 14 }} align="justify">
                        {`This  Performance  Security  shall  be  valid  and  in  full  force  and  effect  from ${data.effectiveDate} to ${data.maturityDate} and,  in  any  circumstances,  we  shall  not  revoke  this  Performance  Security  during  such  period.`}
                    </Typography>
                    <br />
                    <Typography style={{ fontSize: 14 }} align="justify">
                        {`We further  agree  that  no  extension  of  time,  which   may  be  made  between  Beneficiary  and  the Contractor,  shall  in  any  way  release  us  from  any  liability  under  the   Performance  Security,  and  the  guaranteed  period  shall  be  extended  throughout  such  extension  of  time.`}
                    </Typography>
                    <br />
                    <Typography style={{ fontSize: 14 }} align="justify">
                        {`In  witness  whereof,  we  the ${data.issuerNameEN} ${data.issuerTaxID} by  the  authorized  representative  has  signed  and  affixed  the seal  (if any)  in  the  presence  of  witnesses.`}
                    </Typography>
                    <br /><br />
                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                        (Signed) ………………………………….. Bank
                    </Typography>
                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                        (………………………………….)
                    </Typography>
                    <Typography paragraph align="center" style={{ fontSize: 14, }}>
                        (Position)……………………………….
                    </Typography>
                    <br />
                    <Grid container>
                        <Grid item>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (Signed) ………………………………….. Witness
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (………………………………….)
                    </Typography>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (Signed) ………………………………….. Witness
                    </Typography>
                            <Typography paragraph align="center" style={{ fontSize: 14, }}>
                                (………………………………….)
                    </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Typography style={{ fontSize: 12, }}>
                        {`${data.lgFormNumber}|${data.approveDate}|${data.documentId}`}
                    </Typography>
                </div>
            </div >
        )
    }

    findDocument(values) {

      
        this.setState({ isLoading: true })


        this.props.webService.findDocument(values).then(response => {
         
            this.setState({
                isLoading: false,
                myTask: response.data.data.tasksList
            })


        }).catch(error => {
         
            this.setState({ isLoading: false })
        })
    }


}





export default WithWebService(withRouter(withStyles(styles)(SearchDocumentView)))