import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import "../css/login.css"
import { MButton, MTextField, } from "../component/common"
import WithWebService from '../utils/WebServiceInterface'
import { withRouter } from 'react-router-dom'
import { setCookie,  deleteCookie} from '../utils/Utilities'
import { keys } from '../utils/Constants'
import { actionLogin } from '../redux/actions'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box/Box';

const styles = theme => ({

    root: {
        height: '100vh',
    },
    buttonLogin: {
        width: 150,
        marginTop: 24
    },
    textHello: {
        color: 'white',
        fontSize: 28,
        marginLeft: 16,
        marginRight: 16,
        fontWeight: 'bold',

    },
    textWelcome: {
        color: 'white',
        fontSize: 24,
        marginLeft: 16,
        marginRight: 16,

    },
    textWelcomeDescription: {
        color: 'white',
        fontSize: 16,
        textAlign: 'center',
        marginLeft: 16,
        marginRight: 16,
        marginTop: 16,

    },
    textOr: {
        color: 'white',
        fontSize: 16,
        marginLeft: 16,
        marginRight: 16,
        marginTop: 36,
    },
    cardLeft: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        height: "100%",
    },
    logo: {
        width: 50,
        objectFit: 'contain'
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})



class ChangeForgotPasswordView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            showError: true,
            isLoadingButtonRegister: false,
        }

    }



    render() {

        const { classes,  } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="absolute" style={{
                    background: 'transparent',
                }} elevation={0}>
                    <Toolbar>
                        <Grid container spacing={8} alignItems="center">
                            <Grid item>
                               <a href='/'>
                                    <img alt="logo" src={require('../resource/image/logo.png')} className={classes.logo} />
                               </a>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Grid container alignContent="center" alignItems="center" justify="center" className={classes.root}>
                    <Grid item xs={11} md={4} sm={7} lg={3} xl={2}>
                        <Card elevation={5}>
                            <Box pt={5} pb={5}>
                                <Grid container justify="center">
                                    <Grid item xs={9}>
                                        <div className="containerForm">
                                            <Box mb={4}> <Typography variant="h5">Change password</Typography></Box>
                                            <Formik
                                                validate={values => {
                                                    const requires = ['password', 'confirmPassword']
                                                    let errors = {};
                                                    requires.forEach(field => {
                                                        if (!values[field]) {
                                                            errors[field] = 'Required';
                                                        }
                                                    });


                                                    if (values.password != values.confirmPassword) {
                                                        errors.confirmPassword = 'Password not match'
                                                    }

                                                    return errors;
                                                }}
                                                initialValues={{
                                                    password: '',
                                                    confirmPassword: ''

                                                }}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setSubmitting(false);
                                                    setCookie(keys.TOKEN, this.props.match.params.token, 1)
                                                    setTimeout(() => {
                                                        this.changePassword(values)
                                                    }, 500);
                                                   
                                                }}
                                                render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                                                    <Form>

                                                        <Field
                                                            name="password"
                                                            type="password"
                                                            fullWidth
                                                            label="Password"
                                                            margin="normal"
                                                            required
                                                            component={MTextField}
                                                        />

                                                        <Field
                                                            name="confirmPassword"
                                                            type="password"
                                                            fullWidth
                                                            label="Confirm password"
                                                            margin="normal"
                                                            required
                                                            component={MTextField}
                                                        />


                                                        <Box mt={4}>
                                                            <MButton fullWidth isLoading={this.state.isLoadingButtonRegister} disabled={this.state.isLoading} onClick={submitForm} variant="contained" color="secondary" >
                                                                SUBMIT
                                                            </MButton>
                                                        </Box>

                                                    </Form>
                                                )}
                                            />

                                        </div>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }



   

    changePassword(values) {


        this.setState({ isLoading: true, isLoadingButtonRegister: true })
        this.props.webService.changePassword(values).then(response => {
            this.setState({ isLoading: false, isLoadingButtonRegister: false })
            deleteCookie(keys.TOKEN)
            setTimeout(() => {
                this.goLogin()
            }, 300);

        }).catch(error => {
            this.setState({ isLoading: false, isLoadingButtonRegister: false })

        })


    }

    goLogin() {
        this.props.history.replace('/login')
    }

}


export default connect(null, { actionLogin })(withRouter(withStyles(styles, { withTheme: true })(WithWebService(ChangeForgotPasswordView))))