import axios from "axios"
import { CallFunctionCommon } from "./GlobalApi"
import { genrateTranscriptID } from "../util/GlobalUtil"
import * as GlobalConfigApi from "../apis/GlobalConfigApi"
import GlobalUtil from "../util/GlobalUtil"
import axiosApiInstance from "./AuthHeader"
//env
import { env } from "../env"

const checkjsondata = text => {
  try {
    // JSON.parse(text);
    return JSON.parse(text)
  } catch (error) {
    return text
  }
}

// TODO: set Default accessToken: string = ""
export const doserviceGetAllGlobalConfigs = body => {
  body.status = body.status == null ? "" : body.status
  const url = new URL(
    `${env.REACT_APP_ENGINE_URL}globalConfig/getQueryGlobalConfig`
  )
  return new Promise((resolve, reject) => {
    axiosApiInstance
      .post(url, body, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        // if(res.data.Data != null){
        //     res.data.Data = checkjsondata(GlobalUtil.decodeUFT8(res.data.Data));
        // }else {
        //     res.data.Data = [];
        // }
        // res.data.RespMessage  = GlobalUtil.decodeUFT8(res.data.RespMessage);

        resolve(GlobalUtil.convertResponseData(res))
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const doserviceCreateGlobalConfigs = async body => {
  const resp = await GlobalConfigApi.doserviceFindGlobalConfigByKey({
    key: "FUNCTION_F100028"
  })
  const userName = GlobalUtil.getUsername()
  let func = resp.Data.Value
  let req_para = func.req_parameters
  let indexdata = req_para.findIndex(({ k }) => k === "data")
  let indexby = req_para.findIndex(({ k }) => k === "by")
  let by = req_para[indexby].v
  let model = req_para[indexdata].v

  model = model.replace("##key##", body.Key)
  // model = model.replace('##value##', body.Value);
  model = model.replace("##value##", JSON.stringify(body.Value))
  model = model.replace("##status##", body.Status)
  model = model.replace("##created_by##", userName)

  by = by.replace("##by##", userName)

  func.req_parameters[indexdata].v = GlobalUtil.encodeBase64(model)
  func.req_parameters[indexby].v = GlobalUtil.encodeBase64(by)
  func.req_transaction_id = genrateTranscriptID()
  return await CallFunctionCommon("azt/doservice", func)
}

export const doserviceUpdateGlobalConfigs = async body => {
  const resp = await GlobalConfigApi.doserviceFindGlobalConfigByKey({
    key: "FUNCTION_F100029"
  })
  const userName = GlobalUtil.getUsername()
  let func = resp.Data.Value
  let req_para = func.req_parameters

  let indexdata = req_para.findIndex(({ k }) => k === "data")
  let indexby = req_para.findIndex(({ k }) => k === "by")
  let by = req_para[indexby].v
  let model = req_para[indexdata].v

  model = model.replace("##id##", body.Id)
  model = model.replace("##key##", body.Key)
  model = model.replace("##status##", body.Status)
  model = model.replace("##updated_by##", userName)
  model = model.replace("##value##", JSON.stringify(body.Value))

  by = by.replace("##by##", userName)

  func.req_parameters[indexdata].v = GlobalUtil.encodeBase64(model)
  func.req_parameters[indexby].v = GlobalUtil.encodeBase64(by)
  func.req_transaction_id = genrateTranscriptID()
  return await CallFunctionCommon("azt/doservice", func)
}

export const doserviceFindGlobalConfigByKey = body => {
  // body.key= btoa(body.key)
  const url = new URL(
    `${env.REACT_APP_ENGINE_URL}globalConfig/findGlobalConfigByKey`
  )
  return new Promise((resolve, reject) => {
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "application/json"
          // 'Authorization': `Bearer ${accessToken}`
        }
      })
      .then(res => {
        res.data.Data = JSON.parse(GlobalUtil.decodeUFT8(res.data.Data))
        // res.data.Data.Value = JSON.parse(res.data.Data.Value);
        // FIXME: Data.Value ??
        res.data.Data.Value = checkjsondata(
          res.data.Data.Value.replace("##app_user##", env.REACT_APP_USER)
        )
        res.data.RespMessage = GlobalUtil.decodeUFT8(res.data.RespMessage)
        resolve(res.data)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const doserviceGetGlobalConfigById = id => {
  const url = new URL(
    `${env.REACT_APP_ENGINE_URL}globalConfig/getQueryGlobalConfigById/${id}`
  )
  return new Promise((resolve, reject) => {
    axiosApiInstance
      .get(url, {
        headers: {
          "Content-Type": "application/json"
          // 'Authorization': `Bearer ${accessToken}`
        }
      })
      .then(res => {
        // res.data.Data = JSON.parse(GlobalUtil.decodeUFT8(res.data.Data));
        // res.data.RespMessage  = GlobalUtil.decodeUFT8(res.data.RespMessage);
        // resolve(res.data);
        resolve(GlobalUtil.convertResponseData(res))
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

export const doserviceDeleteGlobalConfigs = body => {
  const url = new URL(
    `${env.REACT_APP_ENGINE_URL}globalConfig/deleteGlobalConfig`
  )
  return new Promise((resolve, reject) => {
    axiosApiInstance
      .post(url, body, {
        headers: {
          "Content-Type": "application/json"
          // 'Authorization': `Bearer ${accessToken}`
        }
      })
      .then(res => {
        res.data.data = JSON.parse(GlobalUtil.decodeUFT8(res.data.data))
        res.data.respMessage = GlobalUtil.decodeUFT8(res.data.respMessage)
        resolve(res.data)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}
