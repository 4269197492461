import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;






class ExportExcelReport extends React.Component {


    render() {
        return (
            <ExcelFile element={<button style={{ background: '#06A719', color: 'white', height: 30, borderRadius: 10, margin: 8, cursor: 'pointer', }}>Export Excel</button>}>
                <ExcelSheet data={this.props.dataSet} name="Document">
                    <ExcelColumn label="รหัสลูกค้า" value={(col) => col.customerId} />
                    <ExcelColumn label="รหัสส่งสินค้า" value={(col) => `${col.sapShipToCode}`} />
                    <ExcelColumn label="ชื่อลูกค้า" value={(col) => `${col.customerName}`} />
                    <ExcelColumn label="ประเภทธุรกิจ" value={(col) => col.sapBusinessType} />
                    <ExcelColumn label="Product Delivery" value={(col) => col.sapProductDelivery} />
                    <ExcelColumn label="Business Area" value={(col) => col.sapBusinessArea} />
                    <ExcelColumn label="Sale Area Manager" value={(col) => col.saleAreaManager} />
                    <ExcelColumn label="แบบหนังสือค้ำประกัน" value={(col) => col.requestType} />
                    <ExcelColumn label="ประเภทหนังสือค้ำประกัน" value={(col) => col.reportType} />
                    <ExcelColumn label="จำนวนเงินค้ำประกัน" value={(col) => col.guaranteeAmount} />
                    <ExcelColumn label="วันที่ต้องคืนหลักประกัน" value={(col) => col.collateralCancelDate} />
                    <ExcelColumn label="ธนาคาร" value={(col) => col.bankName} />
                    <ExcelColumn label="สาขา" value={(col) => col.bankBranch} />
                    <ExcelColumn label="เลขที่หนังสือค้ำประกัน" value={(col) => col.bgNo} />
                    <ExcelColumn label="วันที่ขอออกหนังสือค้ำประกัน" value={(col) => col.issueDate} />
                    <ExcelColumn label="วันที่มีผลบังคับใช้หนังสือค้ำประกัน" value={(col) => col.collateralEnforceDate} />
                    <ExcelColumn label="วันที่ครบกำหนด" value={(col) => col.collateralEndDate} />
                    <ExcelColumn label="เลขที่สัญญา" value={(col) => col.contractNumber} />
                    <ExcelColumn label="วันที่สัญญามีผลบังคับใช้" value={(col) => col.contractEnforceDate} />
                    <ExcelColumn label="วันที่สัญญาสิ้นสุด" value={(col) => col.contractExpireDate} />
                    <ExcelColumn label="วันที่เรื่มจ่ายก๊าซ" value={(col) => col.gasStartDate} />
                    <ExcelColumn label="ระยะเวลาที่ต้องวาง BG" value={(col) => col.periodeBGDate} />
                    <ExcelColumn label="เลขที่การทำรายการจากระบบ" value={(col) => col.requestNo} />
                    <ExcelColumn label="สาเหตุ" value={(col) => col.note} />
                    
                </ExcelSheet>
                

            </ExcelFile>
        );
    }
}

export default ExportExcelReport