import React from 'react'
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({

    fabProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -14,
        marginLeft: -14,
        zIndex: 1,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSubmit: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 0,
        color: 'white'
    },
    buttonSearch: {
        color: 'white',
        backgroundColor: '#0288ff',
        '&:hover': {
            backgroundColor: '#006AC7',
        },
    }
})

const Button = (props) => {

    const { classes, } = props;

    return (
        <div className={classes.buttonSubmit}>
            <Fab
                variant="extended"
                disabled={props.disabled}
                onClick={props.onClick}
                size="large"
                classes={{ root: classes.buttonSearch }}>
                <Icon>search</Icon>
                {props.isLoading && <CircularProgress size={30} className={classes.fabProgress} />}
                <Typography align="center" variant="button" className={classes.textButton}>{props.label}</Typography>
            </Fab>

        </div>
    )
}

const ButtonBase = (props) => {

    const { classes,icon } = props;

    return (
        <div className={classes.buttonSubmit}>
            <Fab
                variant="extended"
                disabled={props.disabled}
                onClick={props.onClick}
                size="large"
                classes={{ root: classes.buttonSearch }}>
                {icon && <Icon>{icon}</Icon>}
                {props.isLoading && <CircularProgress size={30} className={classes.fabProgress} />}
                <Typography align="center" variant="button" className={classes.textButton}>{props.label}</Typography>
            </Fab>

        </div>
    )
}

const ButtonCircle = (props) => {

    const { classes,...other } = props;

    return (
        <Fab
            size="large"
            {...other}
            disabled={props.disabled}
            onClick={props.onClick}
            classes={{ root: classes.buttonSearch }}>
            <Icon >{props.icon}</Icon>
            {props.isLoading && <CircularProgress size={30} className={classes.fabProgress} />}
            {props.children}
        </Fab>
    )
}


const ButtonSearch = withStyles(styles)(Button)
const ButtonApp = withStyles(styles)(ButtonBase)
const ButtonSearchCircle = withStyles(styles)(ButtonCircle)
export { ButtonSearch, ButtonSearchCircle, ButtonApp }