import React from 'react'
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({

    fabProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -14,
        marginLeft: -14,
        zIndex: 1,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSubmit: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: 8
    },
})


const Button  = (props) =>{

    const { classes, } = props;

    return (
        <div className={classes.buttonSubmit}>
            <Fab
                size="large"
                disabled={props.disabled}
                color={props.color}
                onClick={props.onClick}>
                <Icon>{props.icon}</Icon>
                {props.isLoading && <CircularProgress  size={68} className={classes.fabProgress} />}
            </Fab>
            <Typography align="center" variant="button" className={classes.textButton}>{props.label}</Typography>
        </div>
    )
}


const ButtonStep = withStyles(styles)(Button)
export { ButtonStep}