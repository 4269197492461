import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const configData = {
    template : {
      open : false,
      mdTheme : '',
      background : ''
    },
    spinner : false,
    menu : [],
}

const page = createSlice({
    name: "page",
    initialState: configData,
    reducers: {
      updatePage: (state, action) => {
        state = { ...state,...action.payload};
        return state;
      },
      setDefault: (state) => {
        state = { ...state,...configData};
        return state;
      },
    },
  });
  export const {updatePage, setDefault} = page.actions
  export default page;